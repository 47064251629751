import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from "components/ui/button";
import { FileText, Trash2, Upload } from 'lucide-react';
import { DocChatContext } from "pages/PromptingAndRagTraining/context/DocChatContext";
import * as ShadeDD from "components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogClose, DialogHeader, DialogTitle, DialogTrigger} from "components/ui/dialog";
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Input } from 'components/ui/input';
import { processMultipleFiles , deleteDocument } from 'pages/PromptingAndRagTraining/context/functions/documentChatOps';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "components/ui/tabs";

    

export const DocumentList = () => {
    const fileInputRef = useRef(null);
    const fileInputRefOrg = useRef(null);
    const [files, setFiles] = useState([]);
    const [filesOrg, setFilesOrg] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const {
        selectedDocuments, setSelectedDocuments,
        uploadedDocs,
        uploadedDocsOrg,
        loadDocuments,
        loadDocumentsOrg,
        addNewNotifcation,
        processLoading, setProcessLoading,
        processLoadingOrg, setProcessLoadingOrg,
        user,
        selectedSubOrganization,
        setSelectedSample
    } = useContext(DocChatContext);

    const processDocuments = async() => {
        setProcessLoading(true);

        if (files.length === 0){
            addNewNotifcation("Please choose at least 1 file.", "danger");
            setProcessLoading(false);
            return;
        }
        let processed = 0;

        try{
            const result = await processMultipleFiles(files, {
                userId: user._id,
                organizationId: user.organization,
                subOrganizationId: selectedSubOrganization._id,
                assistant: 'ai-lab',
                feature: "ai-teacher",
                document_collection: "user"
            }, user);

            processed = result.length;
            console.log("Processed:", processed);
            console.log("result:", result);
            if (processed === 0)
                addNewNotifcation("File not processed. Something went wrong.", "danger");
            else
                addNewNotifcation(`${processed} file(s) successfully processed.`, 'success');
        }
        catch (error){
            console.error("Error processing documents:", error);
            addNewNotifcation(`Something went wrong. ${files.length - processed} file(s) not processed.`, 'danger');
        }

        loadDocuments();
        setProcessLoading(false);
    }

    const processDocumentsOrg = async() => {
        setProcessLoadingOrg(true);

        if (filesOrg.length === 0){
            addNewNotifcation("Please choose at least 1 file.", "danger");
            setProcessLoadingOrg(false);
            return;
        }
        let processed = 0;

        try{
            const result = await processMultipleFiles(filesOrg, {
                userId: user._id,
                organizationId: user.organization,
                subOrganizationId: selectedSubOrganization._id,
                assistant: 'ai-lab',
                feature: "ai-teacher",
                document_collection: "organization"
            }, user);

            processed = result.length;
            console.log("Processed:", processed);
            console.log("result:", result);
            if (processed === 0)
                addNewNotifcation("File not processed. Something went wrong.", "danger");
            else
                addNewNotifcation(`${processed} file(s) successfully processed.`, 'success');
        }
        catch (error){
            console.error("Error processing documents:", error);
            addNewNotifcation(`Something went wrong. ${files.length - processed} file(s) not processed.`, 'danger');
        }

        loadDocumentsOrg();
        setProcessLoadingOrg(false);
    }

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles(selectedFiles);
    }
    const handleFileChangeOrg = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFilesOrg(selectedFiles);
    }

    const handleIfSelected = (doc) => {
        if (
          selectedDocuments.find(
            (document) => document._id === doc._id
          )
        )
          return true;
        else return false;
      };

    const GetDocs = async () => {
        await loadDocuments();
        setIsLoading(false);
    }
    const GetDocsOrg = async () => {
        await loadDocumentsOrg();
        setIsLoading(false);
    }

    useEffect(() => {
        GetDocs();
        GetDocsOrg();
    }, []);

    useEffect(() => {
        console.log("Selected documents:", selectedDocuments);
    }, [selectedDocuments]);

    return(
        <ShadeDD.DropdownMenu>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" className="px-2">
                    <FileText className="w-5 h-5" />
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="center" side="bottom" className="w-[350px]">
                <Tabs defaultValue="user" className="w-full h-fit">
                    <TabsList className="flex w-full grid-cols-4 bg-gray-100">
                        <TabsTrigger className="" value="user">User Documents</TabsTrigger>
                        <TabsTrigger value="org">Org Documents</TabsTrigger>
                    </TabsList>
                    <ShadeDD.DropdownMenuSeparator />
                    <TabsContent className="w-full h-full m-0" value="user">
                        {/* <PromptPanelDemo/> */}
                        <ShadeDD.DropdownMenuGroup className='max-h-72 overflow-y-auto'>
                            {uploadedDocs.map((doc) => (
                                <div
                                    key={doc._id}
                                    className="w-full flex flex-row gap-2 justify-between">
                                    <ShadeDD.DropdownMenuCheckboxItem
                                        className="w-full overflow-hidden"
                                        
                                        checked={handleIfSelected(doc)}
                                        onSelect={(event) => {
                                        const result = selectedDocuments.find(
                                            (document) => document._id === doc._id
                                        );
                                        // console.log(doc._id)
                                        event.preventDefault();
                                        setSelectedSample([])
                                        if (result) {
                                            setSelectedDocuments((prevDocuments) =>
                                                prevDocuments.filter((document) => document._id !== doc._id)
                                            );
                                        } else {
                                            if (selectedDocuments.length < 5){
                                                setSelectedDocuments((prevDocuments) => [
                                                    ...prevDocuments,
                                                    { _id: doc._id, WD_id:doc.WD_id, name: doc.file_name, file_url: doc.file_url },
                                                ]);
                                            }
                                            
                                        }
                                        
                                        }}
                                    >
                                        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                            {doc.file_name}
                                        </span>
                                        
                                    </ShadeDD.DropdownMenuCheckboxItem>
                                    <DeleteDialog doc={doc} setSelectedDocuments={setSelectedDocuments}/>
                                </div>
                            
                            ))}
                        </ShadeDD.DropdownMenuGroup>
                        <ShadeDD.DropdownMenuSeparator />
                        <ShadeDD.DropdownMenuLabel className="flex flex-row flex-nowrap items-center gap-2">
                            <Input
                                ref={fileInputRef}
                                type="file"
                                onChange={handleFileChange}
                                multiple
                            />
                            <Button variant="outline"  className="mr-2" onClick={processDocuments} disabled={!fileInputRef.current || fileInputRef.current.files.length === 0}>
                                {processLoading ? <LoaderSpinner/> : <Upload className="w-5 h-5"/>}
                            </Button>
                        </ShadeDD.DropdownMenuLabel>
                    </TabsContent>
                    <TabsContent className="w-full h-full m-0" value="org">
                        <ShadeDD.DropdownMenuGroup className='max-h-72 overflow-y-auto'>
                            {uploadedDocsOrg.map((doc) => (
                                <div
                                    key={doc._id}
                                    className="w-full flex flex-row gap-2 justify-between">
                                    <ShadeDD.DropdownMenuCheckboxItem
                                        className="w-full overflow-hidden"
                                        
                                        checked={handleIfSelected(doc)}
                                        onSelect={(event) => {
                                        const result = selectedDocuments.find(
                                            (document) => document._id === doc._id
                                        );
                                        // console.log(doc._id)
                                        event.preventDefault();
                                        setSelectedSample([])
                                        if (result) {
                                            setSelectedDocuments((prevDocuments) =>
                                                prevDocuments.filter((document) => document._id !== doc._id)
                                            );
                                        } else {
                                            if (selectedDocuments.length < 5){
                                                setSelectedDocuments((prevDocuments) => [
                                                    ...prevDocuments,
                                                    { _id: doc._id, WD_id:doc.WD_id, name: doc.file_name, file_url: doc.file_url },
                                                ]);
                                            }
                                            
                                        }
                                        
                                        }}
                                    >
                                        <span className="block overflow-hidden text-ellipsis whitespace-nowrap">
                                            {doc.file_name}
                                        </span>
                                        
                                    </ShadeDD.DropdownMenuCheckboxItem>
                                    <DeleteDialog doc={doc} setSelectedDocuments={setSelectedDocuments}/>
                                </div>
                            
                            ))}
                        </ShadeDD.DropdownMenuGroup>
                        <ShadeDD.DropdownMenuSeparator />
                        <ShadeDD.DropdownMenuLabel className="flex flex-row flex-nowrap items-center gap-2">
                            <Input
                                ref={fileInputRefOrg}
                                type="file"
                                onChange={handleFileChangeOrg}
                                multiple
                            />
                            <Button variant="outline"  className="mr-2" onClick={processDocumentsOrg} disabled={!fileInputRefOrg.current || fileInputRefOrg.current.files.length === 0}>
                                {processLoadingOrg ? <LoaderSpinner/> : <Upload className="w-5 h-5"/>}
                            </Button>
                        </ShadeDD.DropdownMenuLabel>
                    </TabsContent>
                </Tabs>
                {/* <ShadeDD.DropdownMenuLabel>
                    {isLoading ? "Loading documents..." : uploadedDocs.length === 0 ? "No documents" : "Documents"}
                </ShadeDD.DropdownMenuLabel> */}
                {/* <ScrollArea className="h-72 w-full "> */}

                {/* </ScrollArea> */}
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


const DeleteDialog = ({doc, setSelectedDocuments}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        addNewNotifcation, loadDocuments,loadDocumentsOrg,
        user
    } = useContext(DocChatContext);

    const handleDelete = async () => {
        setIsOpen(false);
        setIsLoading(true);
        await deleteDocument(doc._id, user, addNewNotifcation);
        setSelectedDocuments([]);
        await loadDocuments();
        await loadDocumentsOrg();
        setIsLoading(false);
    };

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
                <Button variant="ghost" size="icon" className="px-2" onClick={() => setIsOpen(true)}>
                    {isLoading ? <LoaderSpinner/> : <Trash2 className="w-5 h-5" />}
                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Delete Document?</DialogTitle>
                    <DialogDescription>
                        Are you sure you want to delete this document?
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-2">
                    <span className=''>{doc.name}</span>
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="button" variant="secondary" onClick={() => setIsOpen(false)}>
                            Close
                        </Button>
                    </DialogClose>
                    <Button
                        type="button"
                        className="bg-red-500 hover:bg-red-600"
                        onClick={handleDelete}
                        disabled={isLoading}
                    >
                        Delete Permanently
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
};