import React, { useContext } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "../../../../components/ui/card";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import StyledTextArea from "../StyledTextArea";
import { PartContext } from "../../context/PartContext";


export function PromptAnatomyLive(){
    const {
        promptLive, setPromptLive,
    } = useContext(PartContext);

    return (
        <Card className="w-full flex flex-col h-full justify-between h-fit">
            {/* HEADER */}
            <CardHeader className="border-b-[1px] border-gray-200 px-6 py-4">
                {/* <div className="flex items-center">

                </div> */}
                <CardTitle className="text-sm">AI Playground</CardTitle>
                <CardDescription className="text-xs">Experiment with different prompts, models and parameters to get your desired result.</CardDescription>
            </CardHeader>


            {/* CONTENT */}
            <CardContent className="p-0 flex flex-col max-h-[360px]">
                <ScrollArea className="max-h-[360px]">
                    <div className='flex flex-col gap-4 px-6 py-4'>
                    <StyledTextArea
                        value={promptLive.instruction}
                        onChange={(e)=>{setPromptLive({...promptLive, instruction: e.target.value})}}
                        labelText="Instruction"
                        tooltipText="Provide specific tasks or questions for the language model to address. This sets the purpose and direction for the response."
                        required={true}
                        placeholder={"Describe the process of making a cup of coffee."}
                    />
                    <StyledTextArea
                        value={promptLive.context}
                        onChange={(e)=>{setPromptLive({...promptLive, context: e.target.value})}}
                        labelText="Context"
                        tooltipText="Supply background information or details that help the language model understand the scenario or topic, ensuring relevant and accurate responses."
                        required={false}
                        placeholder={"For someone who is new to making coffee at home, you need to provide a step-by-step guide that includes selecting coffee beans, grinding them, and brewing the coffee."}
                    />
                    <StyledTextArea
                        value={promptLive.target_format}
                        onChange={(e)=>{setPromptLive({...promptLive, target_format: e.target.value})}}
                        labelText="Target Format"
                        tooltipText="Specify the structure and style of the response. This ensures that the output is consistent with your needs, whether it's a specific layout, tone, or type of information."
                        required={false}
                        placeholder={"Provide the response in a numbered list format with each step clearly outlined."}
                    />
                    <div className="grid grid-cols-2 gap-4">
                    <StyledTextArea
                        value={promptLive.sample_input}
                        onChange={(e)=>{setPromptLive({...promptLive, sample_input: e.target.value})}}
                        labelText="Sample Input"
                        tooltipText="Offer an example of what the input data might look like. This helps clarify the format and type of data expected."
                        required={false}
                        placeholder={""}
                    />
                    <StyledTextArea
                        value={promptLive.sample_output}
                        onChange={(e)=>{setPromptLive({...promptLive, sample_output: e.target.value})}}
                        labelText="Sample Output"
                        tooltipText="Show a representative example of the desired response. This demonstrates the format and quality of output you are looking for from the language model."
                        required={false}
                        placeholder={""}
                    />
                    </div>
                    <StyledTextArea
                        value={promptLive.input_data}
                        onChange={(e)=>{setPromptLive({...promptLive, input_data: e.target.value})}}
                        labelText="Input Data"
                        tooltipText="Include any data or details that the language model needs to process in order to generate a meaningful response. This can include text, numbers, or structured data."
                        required={false}
                        placeholder={"Coffee beans: Arabica, ground size: medium-fine, water temperature: 195-205°F."}
                    />
                    </div>
                </ScrollArea>
            </CardContent>
        </Card>
    );
}
