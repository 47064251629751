import { createContext, useState, useEffect } from 'react'
import { SubscriptionService, WorkflowService } from 'api'
import { useParams } from 'react-router-dom'
import subOrgSectionMenu from "data/subOrgSectionMenu";
import { projectService } from 'api/services/BEX/project.service';

export const SubOrgContext = createContext({})


export default function SubOrganizationContext() {
    const [loading, setLoading] = useState(true)
    const [workflow, setWorkflow] = useState(null)
    const [subscription, setSubscription] = useState(null)
    const [project, setProject] = useState(null)

    const [subOrgMenu, setSubOrgMenu] =  useState(subOrgSectionMenu)
    const { oragID, subOragID  } = useParams()

    const subscriptionService = new SubscriptionService(oragID, subOragID)
    const workflowService = new WorkflowService()

    // Hooks
    useEffect(() => {
        fetchSubOrgData()
    }, [])

    // Actions
    const actions = (action) => {
        const { type, payload } = action

        switch (type) {
            case "SET_LOADING":
                return setLoading(payload)
            case "SET_WORKFLOW":
                return setWorkflow(payload)
            case "SET_SUBSCRIPTION":
                return setSubscription(payload)
            case "SET_PROJECT":
                return setProject(payload)
            default:
                return loading
        }
    }

    const fetchSubOrgData = async () => {
        try {
            const workflowResponse = await workflowService.getSubOrgWorkflow(oragID, subOragID)
            setWorkflow(workflowResponse.data)
            setSubOrgMenu(prev => ({...prev, name: workflowResponse.data.name}))

            const subscriptionResponse = await subscriptionService.getSubOrgSubscription()
            setSubscription(subscriptionResponse.data)

            const projectsResponse = await projectService.getSubOrgProjects(oragID, subOragID)
            setProject(projectsResponse.data[0])

        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoading(false)
        }
    }

    return {
        actions,
        loading,
        workflow,
        subscription,
        subOrgMenu,
        project
    }
}