import { httpRequestO, httpRequestX } from "../../../../utils/httpsRequest";

export const consolidate = (prompt) => {
    // console.log("Passed", prompt);
    
    let cp = 
    "Input:\n" + 
    ((prompt.instruction) ? `${prompt.instruction}\n\n` : '') +
    ((prompt.context) ? `${prompt.context}\n\n` : '') +
    ((prompt.target_format) ? `${prompt.target_format}\n\n` : '') +
    ((prompt.sample_input) ? `Sample Input:\n${prompt.sample_input}\n\n` : '') +
    ((prompt.sample_output) ? `Sample Output:\n${prompt.sample_output}\n\n` : '') +
    ((prompt.input_data) ? `Actual Input:\n${prompt.input_data}\n\n` : '') 

    + "Output:\n";
    console.log("Merged\n", cp);
    return cp;
};

export const generatePrompt = async (prompt, parameters, selectedModel) => {
    let params = {};
    if (parameters.mode === "greedy") {
        params = { 
            mode: parameters.mode,
            max_tokens: 0,
            max_new_tokens: Number(parameters.max_new_tokens),
            stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
            repetition_penalty: 1.2
        };
    } else {
        params = {
            mode: parameters.mode,
            max_tokens: 0,
            max_new_tokens: Number(parameters.max_new_tokens),
            stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
            temperature: Number(parameters.temperature),
            top_k: Number(parameters.top_k),
            top_p: Number(parameters.top_p),
            repetition_penalty: 1.2
        };
    }

    let consolidatedPrompt = consolidate(prompt);
    console.log( "CONSOLIDATED PROMPT\n", consolidatedPrompt);
    console.log( "PARAMS\n", params);
    console.log( "SELECTED MODEL\n", selectedModel);

    try {
        const res = await httpRequestO.post('/projecto/api/v1/model/foundationModel/experiment?mode=freeMode', {
            modelId: selectedModel.value,
            parameters: params,
            prompt: consolidatedPrompt,
            query: ""
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return res;

        // console.log("prompt response", res);
        // setPromptOutputDemo(res.data.prediction.trim());
        // addNewNotifcation('Generation successful. Please see the Output for results.', 'success');
    } catch (error) {
        // console.log(error);
        // addNewNotifcation('Generation unsuccessful. Please try again.', 'error');
    } finally {
        // setGenerateBtnLoading(false);
    }
};

function filterDocuments(documents) {
    var result = '';
    var contents = [];
    for (const doc of documents){
        const filename = doc.metadata.filename;
        const page_content = doc.page_content;
        if (!contents.includes(page_content)) {
            contents.push(page_content);
        }
    }
    result = contents.join("\n\n")

    // if (!result){
    //     return "Information not found in the document.";
    // }

    return result;
};

export const getDocList = async (user, subOrg, customFilter = "") =>{
    try {
        // console.log("GETTING DOCS")
        // console.log("ORG: " + user.organization, "SUBORG: " + selectedSubOrg._id)
        // const res = await httpRequestO.get(`/projecto/api/v1/embedding_api_service/list-document/?organization_id=${user.organization}&sub_organization_id=${selectedSubOrg._id}`)
        // CHECK IN ONE COLLECTION
        let filter = ""
        if (customFilter){
            filter = customFilter;
        }
        else {
            filter = JSON.stringify({
                uploader_user: user._id,
                assistant: '667539a5a13370ac974a2e9c',
                uploader_organization: user.organization,
                uploader_subOrganization: subOrg,
                feature: "live_prompt"
            })
        }
        const res = await httpRequestO.get(`/projecto/api/v1/document_api_service/list-document/?organization_id=663c6e356a007f9827881e53&sub_organization_id=663c6f136a007f9827881e5c&filter=${filter}`);
        return res.data;
    } catch (error) {
        throw new Error(error);
    }
};

export const queryWithID = async (doc_id = '', ragQuery, k = null) => {
        
    // Check if document is in the processed documetns first
    let result = null;
    const formData = new FormData();
    formData.append('query', ragQuery);
    formData.append('document_id', doc_id);
    if (k === null) {
        formData.append('k', 10);
    }else {
        formData.append('k', k);
    }
    console.log(doc_id, k);

    // ONLY 1 COLLECTION
    // CoolRIOTS Academy / Training / documents
    formData.append('organization_id', '663c6e356a007f9827881e53');
    formData.append('sub_organization_id', '663c6f136a007f9827881e5c');
    // if (!live){
    //     formData.append('organization_id', '663c6e356a007f9827881e53');
    //     formData.append('sub_organization_id', '663c6f136a007f9827881e5c');
    // }
    // else{
    //     formData.append('organization_id', user.organization);
    //     formData.append('sub_organization_id', selectedSubOrg._id);
    // }


    try {
        const res = await httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData);
        // result = await chatWithLLM(filterDocuments(res.data.data.documents), ragQuery, messagesLive);
        // result = filterDocuments(res.data.data.documents);
        // addNewNotifcation("Querying successful. Results are shown under your query.", "success");
        console.log(res.data.data);
        return res.data.data;
    } catch (error) {
        console.log('QUERYING ERROR', error);
        // addNewNotifcation("Querying unsuccessful. Please try again.", "error");
    }
    return result;
};

function getRelevantMessages(messages) {
    // console.log(messages)
    if (messages.length < 1) return [];

    messages = messages.filter(message => message.role === 'rag' || message.role === 'user');
    // Get the last 5 messages
    const lastFiveMessages = messages.slice(-5);
  

    const relevantMessages = [];
    for (let i = lastFiveMessages.length - 1; i >= 0; i--) {
        const message = lastFiveMessages[i];
        if (message.role === 'rag' || message.role === 'user') {
        relevantMessages.unshift(message);
            // relevantMessages.push(message)
          if (relevantMessages.length === 5) {
            break;
          }
        }
        // else break;
    }
    return relevantMessages;
  }

export const chatWithLLM = async (ragContent, ragQuery, messagesLive) => {

    const relevantMessages = getRelevantMessages(messagesLive);
    console.log(relevantMessages);
    
    let pastConversation = "";
    for (const message of relevantMessages){
        if (message.role === "user"){
            pastConversation += `<|user|>\n`;
        }else{
            pastConversation += `<|assistant|>\n`;
        }
        pastConversation += `${message.msg}\n\n`;
    }


    let prompt = 
    "<|system|>\n" +
    "You are an AI language model that specializes in Retrieval-Augmented Generation. You look at the document and give response based on the document. You reply with \"Sorry, that information is not in the document.\" when the document does not contain the answer. You are a cautious assistant. You carefully follow instructions. You are helpful and harmless and you follow ethical guidelines and promote positive behavior. Please only return the answer and do not return any part of the prompt. Please do not say anything else and do not start a conversation.\n\n" +
    "[Document]\n" + 
    `${ragContent}\n` +
    "[End]\n\n" + 
    `${pastConversation ? pastConversation : ""}` +
    "<|user|>\n" +
    `${ragQuery}\n\n` +

    "<|assistant|>\n";

    // console.log("Prompt", prompt);
    let result = '';
    try {
        const response = await httpRequestO.post('/projecto/api/v1/model/foundationModel/experiment?mode=freeMode', {
            modelId: 'ibm/granite-13b-chat-v2',
            parameters: { mode: "greedy", max_tokens: 0, max_new_tokens: 400 },
            prompt: prompt,
            query: ""
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        result = response.data.prediction;
        console.log("prompt response", response);
    } catch (error) {
        console.log(error);
    }
    return result;
};

function getFileWithLowercaseExtension(file) {
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf('.');
    const nameWithoutExtension = fileName.slice(0, lastDotIndex);
    const extension = fileName.slice(lastDotIndex + 1);
    const lowercasedExtension = extension.toLowerCase();
    const newFileName = `${nameWithoutExtension}.${lowercasedExtension}`;
    console.log("New File Name:", newFileName, new File([file], newFileName, { type: file.type }));
    return new File([file], newFileName, { type: file.type });

}

export const processFile = async (file,files, user, subOrg) => {
    // console.log("UPLOADING")
    const form = new FormData();
    form.append('organization_id', '663c6e356a007f9827881e53');
    form.append('sub_organization_id', '663c6f136a007f9827881e5c');
    form.append('chunk_size', 500);
    form.append('chunk_overlap', 50);
    let newFile = getFileWithLowercaseExtension(file);
    form.append('files', newFile);
    form.append('metadata', JSON.stringify({
        name: newFile.name,
        email: user.email,
        uploader_user: user._id,
        assistant: '667539a5a13370ac974a2e9c',
        uploader_organization: user.organization,
        uploader_subOrganization: subOrg,
        feature: "live_prompt"
    }));
    // form.append('metadata', JSON.stringify({
    //     demo_doc: true,
    //     name: "Question Answering",
    // }));

    try {
        const res = await httpRequestO.post('/projecto/api/v1/embedding_api_service/process_file/', form, {
            headers: {
              'Content-Type': 'multipart/form-data' // Set the content type here
            }
          });
        console.log("FILE PROCESSSING SUCCESS", res.data.document_ids);
        
        // const regex = /\[([^\]]+)\]/;
        // const match = res.data.message.match(regex);
        // if (match) {
        //     const extractedString = match[1];
        //     console.log(extractedString);
        //     return extractedString;
        // }
        return res.data.document_ids[0];
    } catch (error) {
        console.log("FILE PROCESSING ERROR", error);
        return error;
    } 
};


export const deleteDocument = async (id, addNewNotifcation, loadDocuments) => {
    
    try{
        const res = await httpRequestO.delete(`/projecto/api/v1/document_api_service/delete-document/?organization_id=663c6e356a007f9827881e53&sub_organization_id=663c6f136a007f9827881e5c&document_id=${id}`);
        // console.log(res)
        addNewNotifcation('Successfully deleted the document.', 'success');
        // return res;
    }
    catch (error){
        console.log(error);
        addNewNotifcation('Something went wrong. Could not delete the document.', 'danger');
        // throw new Error(error);
    }
    finally{
    //   console.log("s")
        loadDocuments();
    }
};




export const processFileWD = async (files) => {
    try {
        const form = new FormData()
        form.append('projectId', '6abff2e1-69f5-4012-94e2-053ba430dac7')
        form.append('collectionId', '104f3a09-5c1e-3998-0000-0190a6d7eb74')
        form.append('file', files)
        const result = await httpRequestX.post("/X/api/discovery/uploadDocument", form);

    } catch (error) {
        console.log(error);
    }
}

export const queryWithIDWD = async () => {
    try {
        const body = {
            "projectId": "6abff2e1-69f5-4012-94e2-053ba430dac7",
            "collectionIds":["104f3a09-5c1e-3998-0000-0190a6d7eb74"],
            "nlq": "What is an involuntary recall? how is it issued?",
            "maxPassages" : 3,
            "count":3
        }

        const result = await httpRequestX.post("/X/api/discovery/query", body);
    } catch (error) {
        console.log(error);
    }
}

export const getDocListWD = async () => {
    try {
        const body = {
            "projectId": "6abff2e1-69f5-4012-94e2-053ba430dac7",
            "collectionId":"104f3a09-5c1e-3998-0000-0190a6d7eb74",
        }

        const result = await httpRequestX.post("/X/api/discovery/getDocumentsDetailed", body);
    } catch (error) {
        console.log(error);
    }
}

export const deleteDocumentWD = async (doc_ids) => {
    try {
        const body = {
            "projectId": "6abff2e1-69f5-4012-94e2-053ba430dac7",
            "collectionId":"104f3a09-5c1e-3998-0000-0190a6d7eb74",
            "documentIds": doc_ids
        }
        const result = await httpRequestX.post("/X/api/discovery/deleteDocument", body);

        
    } catch (error) {
        console.log(error);
    }
}