import React from 'react';
import { Avatar, AvatarFallback } from './ui/avatar';
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuItem } from './ui/dropdown-menu';

const AvatarDropdownMenu = ({ user, menuItems = [] }) => {
    const userFirsCharacter = user.name ? user.name[0].toUpperCase() : 'A';
    return (
        <DropdownMenu>
            {/* User Avatar */}
            <DropdownMenuTrigger asChild>
                <Avatar className="h-9 w-9">
                    <AvatarFallback>
                        {userFirsCharacter}
                    </AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>

            {/* User Menu */}
            {menuItems.length > 0 && (
                <DropdownMenuContent>
                    {menuItems.map((item, index) => (
                        <DropdownMenuItem
                            key={index}
                            linkPath={item.link}
                            action={item.action}>
                            {item.name}
                        </DropdownMenuItem>
                    ))}
                    <DropdownMenuSeparator />
                </DropdownMenuContent>
            )}
        </DropdownMenu>
    );
};

export default AvatarDropdownMenu;
