import { Input } from '../../../components/ui/input'
import { Button } from '../../../components/ui/button'
import { Card, CardContent, CardFooter, CardTitle } from '../../../components/ui/card'
import React, { useContext, useEffect, useState } from 'react'
import { Label } from '../../../components/ui/label'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog"
import { AppLoader } from '../../../components/LoaderSpinner'
import { httpRequestOSetup } from '../../../utils/httpsRequest'
import { useParams } from 'react-router-dom'
import { Textarea } from '../../../components/ui/textarea'
import { Context } from '../../../context/GlobalState'

export default function HotelSettings() {

    const [isOpen, setIsOpen] = useState(false)
    const [hotel, setHotel] = useState({})
    const [loading, setLoading] = useState(true)

    const { oragID, subOragID } = useParams()


    useEffect(() => {
        const getHotelObj = {
            subOrganizationId: subOragID,
            organizationId: oragID
        }

        httpRequestOSetup.post(`hotels/sub-organization`, getHotelObj)
        .then((res) => {
            console.log('res: ', res);
            setHotel(res.data)
        }).catch((error) => {
            console.log('hotel error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div className='flex w-full mt-4'>
            <Card className="w-full space-y-8 p-4">
                <CardTitle> Hotel Information </CardTitle>
                {loading ? <AppLoader size={50} /> : (
                    <CardContent>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Name </Label>
                                <span className='font-[500]'>{hotel.hotel_name}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Email </Label>
                                <span className='font-[500]'>{hotel.hotel_email}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Phone </Label>
                                <span className='font-[500]'>{hotel.hotel_phone}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Address </Label>
                                <span className='font-[500]'>{hotel.hotel_address}</span>
                            </div>
                        </div>
                    </CardContent>
                )}
                <CardFooter>
                    <div className='flex w-full justify-end'>
                        <Button onClick={() => setIsOpen(true)}> Update </Button>
                    </div>
                </CardFooter>
            </Card>
            
            {/* update hotel modal */}
            <UpdateHotelModal isOpen={isOpen} setIsOpen={setIsOpen} setHotel={setHotel} hotel={hotel} />
        </div>
    )
}


export const UpdateHotelModal = ({ isOpen, setIsOpen, setHotel, hotel }) => {

    const [btnLoading, setBtnLoading] = useState(false)

    const { addNewNotifcation } = useContext(Context)


    const handleHotelChange = (value, type) => {
        setHotel(prev => ({...prev, [type]: value}))
    }


    const handleUpdateHotel  = (e) => {
        e.preventDefault()

        setBtnLoading(true)
        httpRequestOSetup.put(`hotels/${hotel._id}`, hotel)
        .then((res) => {
            console.log('res: ', res);
            setHotel(res.data)
            addNewNotifcation('Hotel information updated successfully', 'success')
            setIsOpen(false)
        }).catch((error) => {
            console.log('hotel error: ', error);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })

    }

    return (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                <DialogTitle>Update Hotel</DialogTitle>
                <DialogDescription>Update your hotel information.</DialogDescription>
                </DialogHeader>
                <form onSubmit={handleUpdateHotel} className="grid gap-4">
                    <div className='flex flex-col gap-3'>
                        <div>
                            <Label> Name </Label>
                            <Input onChange={(e) => handleHotelChange(e.target.value, 'hotel_name')} value={hotel.hotel_name} />
                        </div>
                        <div>
                            <Label> Email </Label>
                            <Input onChange={(e) => handleHotelChange(e.target.value, 'hotel_email')} value={hotel.hotel_email} />
                        </div>
                        <div>
                            <Label> Phone </Label>
                            <Input onChange={(e) => handleHotelChange(e.target.value, 'hotel_phone')} value={hotel.hotel_phone} />
                        </div>
                        <div>
                            <Label> Address </Label>
                            <Textarea onChange={(e) => handleHotelChange(e.target.value, 'hotel_address')} className='max-h-[200px]' value={hotel.hotel_address} />
                        </div>

                    </div>
                    <DialogFooter>
                        <Button disabled={btnLoading} onClick={handleUpdateHotel} type="submit">Update</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}