import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";
import { AppLoader, LoaderContainerDiv } from "../components/LoaderSpinner";
import SubOrganizationContext, { SubOrgContext } from "context/SubOrganizationContext";
import { WorkflowRequired } from "pages/Dashboard/AssistantsDashboard";
import SideMenuWrapper from "./SideMenuWrapper";

export const ProtectedSubOrgRoute = () => {
    const { isAuth, user, selectedSubOrganization, setSelectedSubOrganization, addNewNotifcation} = useContext(Context);
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const { subOragID, oragID } = useParams()
    const [protectLoading, setProtectLoading] = useState(true)
    const store = SubOrganizationContext()


    
    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem('redirect-url', pathname)
            window.location = '/auth/login'
        } else {
            if (selectedSubOrganization._id !== subOragID) {
                // check if user member of the subOragID
                const subOrganizationObj = user.subOrganizations?.find(item => item._id === subOragID)
                if (subOrganizationObj) {
                    setSelectedSubOrganization(subOrganizationObj)
                } else {
                    navigate(`/organizations/${oragID}/sub`)
                    addNewNotifcation('You are not a member of this organization', 'warning')
                }
            }
        }

        setProtectLoading(false)
    }, [])


    if (protectLoading) {
        return <LoaderContainerDiv> <AppLoader size={50} /> </LoaderContainerDiv>;
    }
    
    // check if workflow exist in sub organization or display WorkflowRequired component
    return (
        <SubOrgContext.Provider value={store}>
            {store.loading ? <AppLoader size={30} /> : store.workflow ? (
                <SideMenuWrapper section={store.subOrgMenu}>
                    <Outlet />
                </SideMenuWrapper>
            ): <WorkflowRequired />}
        </SubOrgContext.Provider>
    )
};