import { useContext, useState } from "react"
import { Button } from "../../../components/ui/button"
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../../components/ui/table"
import { Card, CardHeader,CardContent } from "../../../components/ui/card"
import AlertModal from "../../../components/Alertmodal"
import { useParams } from "react-router-dom"
import { Context } from "../../../context/GlobalState"
import QueryDocs from "./QueryDoc"
import { ragService } from "api/services/PROJECT-O"

export default function ListDocuments({ docs, setDocs, handleQueryAll, handleUploadFiles }) {

    return (
        <div className="flex w-full p-4 flex-grow flex-col">
            <Card className="shadow-md flex-grow">
                <CardHeader>
                    <div className="flex items-center justify-between mb-6">
                        <h1 className="text-2xl font-bold"> Files</h1>
                        <div className="flex items-center gap-4">
                            <Button size="sm" onClick={handleQueryAll}>
                                <SearchIcon className="w-4 h-4 mr-2" />
                                Query All
                            </Button>
                            <Button size="sm" onClick={handleUploadFiles}>
                                <UploadIcon className="w-4 h-4 mr-2" />
                                Upload File
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="border rounded-lg overflow-hidden">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>File Name</TableHead>
                                    <TableHead>File Type</TableHead>
                                    <TableHead>Upload Date</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {docs.map((file, index) => (
                                    <DocumentCard  {...file} key={index} setDocs={setDocs} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>
        </div>

    )
}



const DocumentCard = ({ _id, file_name, name, url, file_url, created_at, type, setDocs }) => {

    const [deleteBtnLoading, setDeleteBtnLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    
    const [showQueryModal, setShowQueryModal] = useState(false)
    
    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const handleDelete = () => {
        
        setDeleteBtnLoading(true)
        ragService.deleteDocument(oragID, subOragID, _id, `bex-org-${oragID}`)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Document deleted successfully', 'success')
            setDocs(prev => prev.filter((obj) => obj._id !== _id));
        }).catch((err) => {
            console.log('error: ', err);
            addNewNotifcation('Something went wrong. Please try again later', 'danger')
        }).finally(() => {
            setDeleteBtnLoading(false)
            setShowModal(false)
        })
    }

    const handleQuery = () => {
        setShowQueryModal(true)
    }

    return (
        <>
            <AlertModal loading={deleteBtnLoading} setopenModal={setShowModal} openModal={showModal} onDelete={handleDelete} />
            {/* <QueryDocs showQueryModal={showQueryModal} setShowQueryModal={setShowQueryModal} isDocument={true} documentId={_id} /> */}
            <QueryDocs showQueryModal={showQueryModal} setShowQueryModal={setShowQueryModal} isDocument={true} documentId={_id} />

            <TableRow>
                <TableCell className='p-4'>
                    <div className="flex items-center gap-2">
                        <a className='flex items-center gap-2 max-w-[400px] truncate' target="_blank" href={file_url || url}>
                            <FileIcon className="w-5 h-5 text-gray-500" />
                            <span className="max-w-[400px] truncate">{file_name || name}</span>
                        </a>
                    </div>
                </TableCell>
                <TableCell className='p-4'>{type || file_name?.split('.')[1] || '-'}</TableCell>
                <TableCell className='min-w-[150px] p-4'>{created_at ? created_at.split('T')[0] : "2024"}</TableCell>
                <TableCell className='p-4'>
                    <div className="flex items-center gap-2">
                        <Button variant="outline" size="sm" onClick={handleQuery}>
                            <SearchIcon className="w-4 h-4" />
                            Query
                        </Button>
                        <Button variant="destructive" size="sm" onClick={() => setShowModal(true)}>
                            <TrashIcon className="w-4 h-4" />
                            Delete
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}


function FileIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
            <path d="M14 2v4a2 2 0 0 0 2 2h4" />
        </svg>
    )
}


function SearchIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <circle cx="11" cy="11" r="8" />
            <path d="m21 21-4.3-4.3" />
        </svg>
    )
}


function TrashIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <path d="M3 6h18" />
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        </svg>
    )
}


function UploadIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
            <polyline points="17 8 12 3 7 8" />
            <line x1="12" x2="12" y1="3" y2="15" />
        </svg>
    )
}