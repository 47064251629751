import React, { useContext, useState, useEffect } from "react";
import { Input } from "components/ui/input";

import { Badge } from "components/ui/badge";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "components/ui/table";

import { Context } from "context/GlobalState";
import { TextToSpeechService } from "api";

export default function TTSOperationHistory() {
    // State
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [operations, setOperations] = useState([]);

  // Context
  const { addNewNotifcation, user } = useContext(Context);

  // Services
  const textToSpeechService = new TextToSpeechService(user);

  useEffect(() => {
    fetchUserHistory();
  }, [user]);


  // Getters
  // Ensure operations is an array
  const operationsArray = Array.isArray(operations) ? operations : [];

  // Filter operations based on the search term
  const filteredOperations = operationsArray.filter((operation) =>
    operation.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOperations.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredOperations.length / itemsPerPage);


  // Actions
  const fetchUserHistory = async () => {
    try {
      const history = await textToSpeechService.retrieveTTSOperationsHistory();
      setOperations(history);
    } catch (error) {
        addNewNotifcation("Error fetching user history", "error");
    }
  };

  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const extractVoiceName = (voiceName) => {
    const namePart = voiceName.substring(6);
    const [name, type] = namePart.split(/(?=[A-Z])/);
    return type === "Voice" ? `${name} (V1)` : `${name} (${type})`;
  };

  return (
    <div className="p-6 sm:p-10 bg-card">
      <div className="flex items-center justify-between mb-6">
        <div className="text-xl sm:text-2xl font-bold mb-4">
            TTS Operations History
        </div>

        <div className="flex items-center">
          <Input
            type="search"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mr-4 bg-white dark:bg-gray-950" />
        </div>
      </div>

      <div className="overflow-auto border rounded-lg">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Type</TableHead>
              <TableHead>Text</TableHead>
              <TableHead>Voice</TableHead>
              <TableHead>Audio File ID</TableHead>
              <TableHead>PDF File ID</TableHead>
              {/* <TableHead>Key Terms</TableHead> */}
              <TableHead>Sub-Org ID</TableHead>
              <TableHead>Org ID</TableHead>
              <TableHead>User ID</TableHead>
            </TableRow>
          </TableHeader>
          
          <TableBody>
            {currentItems.map((operation) => (
              <TableRow key={operation._id}>
                <TableCell>
                    {operation.type}
                </TableCell>

                <TableCell>
                    {operation.text}
                </TableCell>

                <TableCell>
                    {extractVoiceName(operation.voice)}
                </TableCell>

                <TableCell>
                    {operation.ibmObjectStorageId_tts}
                </TableCell>

                <TableCell>
                    {operation.ibmObjectStorageId_instrDoc}
                </TableCell>

                {/* <TableCell>
                  {operation.extracted_keyterms.map((keyTerm) => (
                    <Badge key={keyTerm._id} className="mr-2">
                      {keyTerm.keyterm}
                    </Badge>
                  ))}
                </TableCell> */}

                <TableCell>
                    {operation.subOrg_Id}
                </TableCell>

                <TableCell>
                    {operation.org_Id}
                </TableCell>
                
                <TableCell>
                    {operation.user_id}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
