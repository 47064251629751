import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'

const getIndexStyle = (idxStep) => {
    
}

const Container = styled.div`
    disfley: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 10px;
`

const StepPointContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
    margin-bottom: 20px;

    & > div:last-child {
        flex-grow: 0;
    } 

    & > div:last-child::after {
        left: 0px;
        background-color: red;
        display: none;
    }

`;

const StepContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 20px;
        left: 40px;
        width: 100%;
        height: 2px; /* Adjust the height as needed */
        background-color: ${(props) => props.$stepIndex};
        z-index: -10;
    }
`

const StepIconContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`

const StepIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: ${(props) => props.$stepIndex};
    // border: 0.3px solid black;
`

const StepText = styled.span`
    color: ${(props) => props.$stepIndex};
`

export const StyledStepPoint = ({ steps, stepIndex }) => {

    const getIndexType = (stepI, index, currentColor, beforeColor) => {
        if (stepI === index) return currentColor
        else if (stepI < index) return beforeColor
        else if (stepI > index) return currentColor
    }


    return (
        <StepPointContainer>
            {steps.map((step, i) => (
                <StepContainer $stepIndex={getIndexType(stepIndex, i, 'var(--primary-color)', '#e5e5e5')} key={step.label}>
                    <StepIconContainer>
                        <StepIcon $stepIndex={getIndexType(stepIndex, i, 'var(--primary-color)', '#e6e6e6')} key={step.label}>
                            <StepText $stepIndex={getIndexType(stepIndex, i, '#fff', 'gray')} key={step.label}> {i+1} </StepText>
                        </StepIcon>
                        <span>  {step.label} </span>
                    </StepIconContainer>
                </StepContainer>
            ))}
        </StepPointContainer>
    )
}