import { bexHttpClient } from '../../clients';


/**
 * Service class for performing subscription operations.
 */
class SubscriptionService {

    constructor(organizationId, subOrganizationId) {
        this.organizationId = organizationId;
        this.subOrganizationId = subOrganizationId;
    }

    /**
     * Fetches the sub organization subscription.
     * @returns {Promise<Object>} The response from the server returns sub organization subscription.
     */
    async getSubOrgSubscription() {
        const response = await bexHttpClient.get(`/db/subscriptions/get-subscriptions-by-sub-organization/${this.subOrganizationId}`);
        return response;
    }

    /**
     * Fetches the organization subscriptions.
     * @returns {Promise<any[]>} The response from the server returns organization subscriptions.
     */
    async getOrgSubscriptions() {
        const response = await bexHttpClient.get(`/db/subscriptions/get-subscriptions-by-organization/${this.organizationId}`);
        return response;
    }

}


export { SubscriptionService }
