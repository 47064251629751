import React, { useEffect, useState } from 'react';
import { Container } from '../../../../components/FormInput';
import { httpRequest } from '../../../../utils/httpsRequest';


const UpdateAdminForm = ({ updateAdminInput, setUpdateAdminInput, isModalOpen, selectedItem}) => {

    const [roles, setRoles] = useState([]);
    const handleInputChange = (key, value) => {
        setUpdateAdminInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    useEffect(() => {
        if (isModalOpen && selectedItem) {
            setUpdateAdminInput(prev => ({
                ...prev,
                role: selectedItem.role
            }));
        }
    }, [isModalOpen, selectedItem]);

    useEffect(() => {
        httpRequest.get('/role/get-roles')
        .then((res) =>{
            console.log('roles: ', res.data);
            setRoles(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [isModalOpen]);

    return (
        <Container labelName={'Select type'} style={{ fontWeight: '600', marginUp: '5px' }}>
            <select onChange={(e) => handleInputChange('role', e.target.value)} name="role" value={updateAdminInput?.role} style={{ padding: '10px' }} >
                {roles.map(role => (
                    <option key={role.name} value={role.name}>{role.name}</option>
                ))}
            </select>
        </Container>
    );
};

export default UpdateAdminForm;