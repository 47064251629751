import React, { useContext, useState } from 'react'
import { LoaderSpinner } from '../../../../../components/LoaderSpinner'
import { Button } from '../../../../../components/ui/button'
import { Card, CardContent, CardHeader } from '../../../../../components/ui/card'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../../../../../components/ui/dialog'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import { Link, useParams } from 'react-router-dom'
import { Context } from '../../../../../context/GlobalState'
import { Trash } from 'lucide-react'
import { Label } from '../../../../../components/ui/label'
import { Input } from '../../../../../components/ui/input'
import { projectService } from 'api/services/BEX/project.service'
import { SubOrgContext } from 'context/SubOrganizationContext'
import AlertModal from 'components/Alertmodal'

export default function SubOrganizationCollections() {

    const [openModal, setOpenModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)
    const [collectionName, setCollectionName] = useState('')


    const { oragID, subOragID  } = useParams()
    const { addNewNotifcation } = useContext(Context)
    const { project, actions } = useContext(SubOrgContext)

    const handleCreateCollection = () => {
        if (!project) {
            return addNewNotifcation('To create collections, please subscribe to a plan.')
        }

        setBtnLoading(true)
        const collectionObj = {
            name: collectionName,
            organizationId: oragID,
            subOrganizationId: subOragID,
            projectId: project._id
        }

        projectService.addCollection(collectionObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Collection created successfully', 'success')
            actions({type: 'SET_PROJECT', payload: (project => ({...project, collections: res.data.collections}))})
            setOpenModal(false)
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status === 403) {
                addNewNotifcation('Resource limit reached. You cannot create more collections under your current plan', 'danger')
            } else if (error.response?.status === 409) { 
                addNewNotifcation(`Collection with ${collectionName} already created`, 'danger')
            } else {
                addNewNotifcation(`Something went wrong. Please try again`, 'danger')
            }
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    return (
        <div className='flex flex-grow'>
            <Card className="shadow-md flex-grow">
                <CardHeader>
                    <div className="flex items-center justify-between mb-6">
                        <h1 className="text-2xl font-bold"> Collections </h1>
                        <div className="flex items-center gap-4">
                            <Button onClick={() => setOpenModal(true)} size="sm">
                                Create New Collection
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="border rounded-lg overflow-hidden">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Creted</TableHead>
                                    {/* <TableHead>Members</TableHead> */}
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {project && project.collections.map((item, index) => (
                                    <CollectionItem project={project} {...item} index={index} key={index} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>

            {/* create collection modal */}
            <Dialog open={openModal} onOpenChange={setOpenModal}>
                <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create New Collection</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new collection.</DialogDescription>
                    </DialogHeader>
                    <div className='flex w-full my-4'>
                        <div className='flex flex-col w-full gap-2'>
                            <Label htmlFor='collection-name'> Collection Name </Label>
                            <Input id='collection-name' value={collectionName} onChange={(e) => setCollectionName(e.target.value)} />
                        </div>
                    </div>

                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                        <Button onClick={handleCreateCollection} className="px-5 py-3 text-md">
                            {btnLoading ? <LoaderSpinner /> : 'Create Collection'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>
    )
}



const CollectionItem = ({ name, created, _id, project }) => {

    const [delModel, setDelModel] = useState(false)
    const [delLoading, setDelLoading] = useState(false)

    const { oragID, subOragID } = useParams()

    const handleDeleteCollection = () => {
        setDelLoading(true)

        const delObj = {
            collectionId: _id,
            projectId: project._id,
            organizationId: oragID,
            subOrganizationId: subOragID,
        }

        projectService.deleteCollection(delObj)
        .then((res) => {
            console.log('res: ', res);
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setDelLoading(false)
        })

    }

    return (
        <TableRow>
            <TableCell className='p-4'>
                <div className="flex items-center gap-2">
                    <Link className='flex items-center gap-2 text-[blue] hover:underline' to={`${_id}/documents`}>
                        <span>{name}</span>
                    </Link>
                </div>
            </TableCell>
            <TableCell className='p-4'>{created.split('T')[0] || "2024/2/2"}</TableCell>
            <TableCell className='p-4'>
                <div className="flex items-center gap-2">
                    <Button onClick={() => setDelModel(true)} variant="destructive" className='flex items-center gap-2 text-[13px]' size="sm">
                        <Trash size={13} /> 
                        Delete Collection
                    </Button>
                </div>
                {/* delete collection alert model */}
                <AlertModal openModal={delModel} setopenModal={setDelModel} onDelete={handleDeleteCollection} loading={delLoading} 
                subTitle='Once deleted all documents in this collection will be deleted'
                    />

            </TableCell>
        </TableRow>
    )
}
