import React, { useState, useEffect, useContext } from 'react';
import './organization-styles.css';
import { useOrganization } from '../../../hooks/useOrganization';
import { AppLoader } from '../../../components/LoaderSpinner';
import { httpRequest } from '../../../utils/httpsRequest';
import { Outlet, useParams } from 'react-router-dom';
import { Context } from '../../../context/GlobalState';
import AlertModal from '../../../components/Alertmodal';
import OrganizationRequests from './OrganizationRequests';
import { Card, CardContent, CardFooter, CardTitle } from '../../../components/ui/card';
import { Label } from '../../../components/ui/label';
import { Button } from '../../../components/ui/button';
import { handleLeaveOrganization } from '../../../utils/organizationUtils';

export default function OrganizationSetting() {
    return (
        <div className='flex flex-grow gap-2'>

            <div className='flex flex-col flex-grow'>
                <Outlet />
            </div>
        </div>
    );
}

export const OrganizationInfoCard = () => {

    const { organizations, loading } = useOrganization();
    const [userInput, setUserInput] = useState({ name: '' });
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    
    const { oragID } = useParams();
    const { user, addNewNotifcation } = useContext(Context)

    useEffect(() => {
        if (organizations.length > 0) {
            setUserInput({ name: organizations[0].name });
        }
    }, [organizations]);


    const handleLeaveOrg = async () => {

        setDeleteLoading(true)
        try {
            await handleLeaveOrganization(oragID, user._id)
        } catch (error) {
            if (error.response?.status === 409) {
                addNewNotifcation('Super admin can not leave the organization', 'warning')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'warning')
            }
        } finally {
            setDeleteLoading(false)
        }

    }

    return (
        <div className='organization-settings-container'>
            <Card className="w-full space-y-8 p-4">
                <CardTitle> Organization </CardTitle>
                {loading ? <AppLoader size={50} /> : (
                    <CardContent>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Name </Label>
                                <span className='font-[500]'>{organizations[0].name}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Created </Label>
                                <span className='font-[500]'>{new Date(organizations[0].created).toDateString()}</span>
                            </div>
                        </div>
                    </CardContent>
                )}
                <CardFooter>
                    {user.role !== "superAdmin" && (
                        <div className='flex w-full justify-end'>
                            <Button variant='destructive' onClick={() => setDeleteModal(true)}> Leave Organization </Button>
                            <AlertModal 
                            openModal={deleteModal}
                            setopenModal={setDeleteModal}
                            onDelete={handleLeaveOrg}
                            loading={deleteLoading} 
                            title='Are you sure you want to leave this organization'
                            subTitle=''
                            />
                        </div>
                    )}
                </CardFooter>
            </Card>
            {/* {user.role !== 'editor' && <OrganizationRequests organizationId={oragID} />} */}
        </div>
    )
}