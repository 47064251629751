import React, { useContext, useEffect, useRef, useState } from 'react'
import './email-extraction-styles.css'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { LoaderModal } from '../../../components/LoaderModal';
import { httpRequest, httpRequestOSetup, serverOSetup } from '../../../utils/httpsRequest';
import { Context } from '../../../context/GlobalState';

import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog"
import { Button } from "../../../components/ui/button"
import { Label } from "../../../components/ui/label"
import { Input } from "../../../components/ui/input"


export const EmailSetupModal = ({ isOpen, onClose, setOpenEmailModal }) => {
    const [steps] = useState([{label: 'Outlook Setup'}, {label: 'Hotel Details'}])
    const [stepIndex, setStepIndex] = useState(0)
    const [getEmailLoading, setGetEmailLoading] = useState(false)
    const [emailAuth, setEmailAuth] = useState({});

    const { addNewNotifcation } = useContext(Context)

    // url getter
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const success = searchParams.get("email-auth-success");
    const email = searchParams.get("email");
    const statusCode = searchParams.get("statusCode");

    useEffect(() => {
        console.log('success: ', success);

        if (success === 'true' && email) {
            setGetEmailLoading(true)
            setOpenEmailModal(true)

            httpRequestOSetup.get(`/hotel-auth/get-email-auth/${email}`)
            .then((res) => {
                console.log('res: ', res);
                setEmailAuth(res.data)
                setStepIndex(1)
            }).catch((error) => {
                console.log('error: ', error);
                addNewNotifcation('Something went wrong. Please try again',  'danger')
            }).finally(() => {
                setGetEmailLoading(false)
            })

        } else if (success === 'false') {
            if (statusCode === '403' || statusCode === 403) {
                addNewNotifcation('Error. Please check your email configuration', 'danger')
            } else if (statusCode === '409' || statusCode === 409) {
                addNewNotifcation('Error. Email already subscribed', 'danger')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            }
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        }
    }, [])


    return (
        <HotelSetup getEmailLoading={getEmailLoading} emailAuth={emailAuth} isOpen={isOpen} setOpenEmailModal={setOpenEmailModal} stepIndex={stepIndex} />
    );
};


export default function HotelSetup({ isOpen, setOpenEmailModal, emailAuth, stepIndex, getEmailLoading }) {

    const { oragID, subOragID } = useParams()
    const [loading, setLoading] = useState(false);

    const nameRef = useRef()
    const phoneRef = useRef()
    const emailRef = useRef()
    const addressRef = useRef()
    const { addNewNotifcation } = useContext(Context)
    
    const handleOnSubmit = async (e) => {
        setLoading(true)

        const hotelObj = {
            hotel_name: nameRef.current.value,
            hotel_address: addressRef.current.value,
            hotel_phone: phoneRef.current.value,
            hotel_email: emailRef.current.value,
            organization_id: oragID,
            sub_organization_id: subOragID,
            email_auth: emailAuth
        }

        console.log('hotelObj: ', hotelObj);


       try {
            const hotelRes = await httpRequestOSetup.post(`/hotels`, hotelObj)
            console.log(hotelRes);
            addNewNotifcation('Hotel was created successfully.','success', 6)

            // update assistant in organization to be active
            const updateOrg = {
                assistant: '667538b01444181354ac98eb',
                status: 'active',
                organizationId: oragID,
                subOrganizationId: subOragID,
            }
            const updateRes = await httpRequest.put(`/db/workflows/update-assistant`, updateOrg)
            console.log('updateRes: ', updateRes);
            window.location = `/organizations/${oragID}/sub/${subOragID}/bex-assistants/667538b01444181354ac98eb`
            // navigate(`/organizations/organization/${oragID}/sub-organization/${subOragID}/email-handling`)
                    
        } catch(error)  {
            console.log('error: ', error);
            addNewNotifcation('Hotel creation failed. Please check all your entries.','danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog open={isOpen} onOpenChange={setOpenEmailModal}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Email Handling Setup</DialogTitle>
                    <DialogDescription>Let's get started by authenticating your Outlook email.</DialogDescription>
                </DialogHeader>
                <div className="p-6 space-y-4">
                <Button disabled={stepIndex === 1} onClick={() => window.location = `${serverOSetup}/outlook/auth/microsoft?organizationId=${oragID}&subOrganizationId=${subOragID}`} variant="outline" className="w-full">
                    <MailIcon className="mr-2 h-5 w-5" />
                    Authenticate with Outlook
                </Button>

                {getEmailLoading ? <LoaderModal /> : stepIndex === 1 && (
                    <>
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                            <span className="w-full border-t" />
                            </div>
                            <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-white px-2 text-gray-500 dark:bg-gray-950 dark:text-gray-400">Hotel Details</span>
                            </div>
                        </div>
                        <form className="grid gap-4">
                            <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="name">Hotel Name</Label>
                                <Input ref={nameRef} id="name" placeholder="Enter hotel name" />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="email">Email</Label>
                                <Input ref={emailRef} id="email" type="email" placeholder="Enter email" />
                            </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                            <div className="space-y-2">
                                <Label htmlFor="phone">Phone</Label>
                                <Input ref={phoneRef} id="phone" type="tel" placeholder="Enter phone number" />
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="address">Address</Label>
                                <Input ref={addressRef} id="address" placeholder="Enter hotel address" />
                            </div>
                            </div>
                        </form>
                    </>
                )}

                </div>
                <DialogFooter>
                    <Button onClick={() => setOpenEmailModal(false)} variant="outline">Cancel</Button>
                    <Button onClick={handleOnSubmit} disabled={stepIndex === 0 || loading}>Create Hotel</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}

function MailIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="20" height="16" x="2" y="4" rx="2" />
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
    </svg>
  )
}