import React, { useEffect, useState } from 'react';
import assistantsMapper from './assistantsMapper';
import { useParams } from 'react-router-dom';
import { httpRequest } from '../../utils/httpsRequest';
import { AppLoader } from '../../components/LoaderSpinner';
import SetupCard from '../../components/AppSetup/SetupCard';
import Breadcrumb from 'components/Breadcrumb'

export default function AssistantDashboard() {

    const { assistantId } = useParams()
    const AssistantComponent = assistantsMapper[assistantId]

    return (
        <div className='flex flex-grow'>
            {AssistantComponent ? <AssistantComponent /> : <DynamicAssistantDashboard assistantId={assistantId} />}
        </div>
    )
}

const DynamicAssistantDashboard = ({ assistantId }) => {

    // Constants
    const SETUP_DEMO_LIST = [''];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistants are designed to cater to various business needs, enhancing productivity and efficiency through intelligent automation.',
        'BeX AI Assistants are capable of integrating with your existing systems, automating repetitive tasks, and providing insightful data analysis, making them essential tools for modern businesses.'
    ];

    const [assistantInfo, setAssistantInfo] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        httpRequest.get(`db/bex-assistants/get-assistant/${assistantId}`)
        .then((res) => {
            console.log('res: ', res);
            setAssistantInfo(res.data)
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    if (loading) {
        return <div className='flex flex-grow'> <AppLoader size={50} /> </div>;
    }

    const handleDemoClick = () => {
        
    }
    const handleSetupClick = () => {

    }

    return (
        <div className='flex flex-grow justify-center items-center p-2'>
            {assistantInfo ? (
                <div className='flex h-full w-full justify-center p-4'>
                    <div className='dashboard-app-setup-container'>
                        <Breadcrumb maxPaths={4} />

                        <SetupCard
                            overviewTitle={assistantInfo.title}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant' 
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />
                    </div>
                </div>
            ) : <h1> Assistant not found 404 </h1>}
        </div>
    )
}