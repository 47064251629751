import React, { useState, useEffect, useRef, useContext } from 'react';
import { Context } from '../../../context/GlobalState';
import { httpRequest } from '../../../utils/httpsRequest';
import { DialogTitle, DialogDescription, DialogHeader, DialogFooter, DialogContent, Dialog } from '../../../components/ui/dialog';
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Badge } from "../../../components/ui/badge";
import { ScrollArea } from "../../../components/ui/scroll-area";
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner, AppLoader } from '../../../components/LoaderSpinner';
import { Button } from '../../../components/ui/button';
import { cn } from "../../../lib/utils";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { WorkflowService } from 'api';

const CreateWorkflowTypeDialog = ({ show, setShow, user, addNewNotifcation }) => {
    const workflowTypeNameRef = useRef();
    const [btnLoading, setBtnLoading] = useState(false);
    const [assistants, setAssistants] = useState([]);
    const [loadingAssistants, setLoadingAssistants] = useState(true);
    const [selectedAssistants, setSelectedAssistants] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [workflowTypes, setWorkflowTypes] = useState([])

    const { actions } = useContext(Context);

    const getData = async () => {
        if (!show) return
        try {
            const assistants = await httpRequest.get(`/db/bex-assistants/get-assistants`)
            // console.log(assistants.data)
            setAssistants(assistants.data);
        } catch (error) {
            console.log('error: ', error);
        } finally {
            setLoadingAssistants(false);
        }
    }

    useEffect(() => {
        getData()
    }, [show]);
    
    const handleCreateWorkflowType = () => {
        console.log("assistants: ", selectedAssistants);
        console.log("workflow type name: ", workflowTypeNameRef.current.value);
        const workflowTypeName = workflowTypeNameRef.current.value.trim();
        if (workflowTypeName.length < 3){
            return addNewNotifcation('Workflow Type name must be at least 3 characters', 'warning');
        } else if (!selectedAssistants) {
            return addNewNotifcation('Please select assistants for this workflow', 'warning');
        }

        setBtnLoading(true);
        httpRequest.post('/db/workflows/add-workflow-type', {
            name: workflowTypeName,
            assistants: selectedAssistants.map(assistant => assistant._id),
        })
        .then((res) => {

                console.log("RES:", res);
                setShow(false);
                setSelectedAssistants([]);
                workflowTypeNameRef.current.value = '';
                addNewNotifcation('Workflow Type created successfully', 'success');
        })
        .catch((error)=>{
            if (error.response.status === 409){
                addNewNotifcation("Workflow type with the same name already exist.", "danger")
            }
            else {
                addNewNotifcation("Something went wrong. Could not create a new workflow type.", "danger")
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });
    }

    const handleSelectAssistant = (assistant) => {
        if (!selectedAssistants.some(m => m._id === assistant._id)) {
            setSelectedAssistants([...selectedAssistants, assistant]);
        }
    };

    const handleRemoveAssistant = (assistant) => {
        setSelectedAssistants(selectedAssistants.filter((m) => m._id !== assistant._id));
    };

    const filteredAssistants = assistants.filter((assistant) =>
        assistant.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        assistant.description.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Dialog open={show} onOpenChange={setShow}>
            <DialogContent className="max-h-[650px] overflow-y-scroll w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                <DialogHeader>
                    <DialogTitle className="text-2xl font-semibold">Create Workflow Type</DialogTitle>
                    <DialogDescription className="text-md text-muted-foreground">Set up a new workflow type and add assistants.</DialogDescription>
                </DialogHeader>
                <div className="space-y-6">
                    <div className="space-y-3">
                        <Label htmlFor="workflow-type-name" className="text-md">Name</Label>
                        <Input
                            ref={workflowTypeNameRef}
                            id="workflow-type-name"
                            placeholder="Enter workflow type name"
                            className="w-full p-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                    </div>

                    <div className="flex">
                        <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                            <div className="flex flex-wrap gap-2">
                                {selectedAssistants.map((assistant) => (
                                    <Badge key={assistant._id} variant="secondary" className="p-2 text-sm">
                                        {assistant.name}
                                        <Cross1Icon className="ml-2 cursor-pointer" onClick={() => handleRemoveAssistant(assistant)} />
                                    </Badge>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                    <div className="relative space-y-3">
                        <Label htmlFor="member-search" className="text-md">Assistant Selection</Label>
                        <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                        <Input
                            id="member-search"
                            placeholder="Search assistants..."
                            type="search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                        />
                        <ScrollArea className="border-solid rounded-md h-56">
                            <div className="flex flex-col p-3 space-y-3">
                                {loadingAssistants ? <AppLoader size={60} /> : filteredAssistants.length === 0 ? <div className="text-center text-muted-foreground">No assistants found</div> : filteredAssistants.map((assistant) => (
                                    <div key={assistant._id} className="flex items-center space-x-3 cursor-pointer" onClick={() => handleSelectAssistant(assistant)}>
                                        <div>
                                            <div className="font-medium text-md">{assistant.name}</div>
                                            <div className="text-sm text-muted-foreground">{assistant.description}</div>
                                        </div>
                                        <CheckIcon className={cn("ml-auto h-6 w-6", selectedAssistants.some(m => m._id === assistant._id) ? "opacity-100" : "opacity-0")} />
                                    </div>
                                ))}
                            </div>
                        </ScrollArea>
                    </div>
                </div>
                <DialogFooter className="flex justify-end space-x-5 mt-6">
                    <Button variant="outline" onClick={() => setShow(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                    <Button onClick={handleCreateWorkflowType} className="px-5 py-3 text-md">
                        {btnLoading ? <LoaderSpinner /> : 'Create Workflow Type'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default CreateWorkflowTypeDialog;