/* eslint-disable max-len */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import styled from "styled-components";
import "./WebDemo.css";
import * as ShadeDD from "../../../components/ui/dropdown-menu";
import * as pdfjs from "pdfjs-dist";
import { Context } from "../../../context/GlobalState";
import { IoMdEye } from "react-icons/io";
import useComponentVisible from "../../../hooks/useClickOutside";
// import { BsX } from "react-icons/bs";
import { RotateCcw } from 'lucide-react';
import WebChat from "./WebChat";
import { Combobox } from "../../../components/ui/combobox";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";
import { Card, CardDescription, CardFooter, CardContent, CardHeader, CardTitle } from "../../../components/ui/card";
import { httpRequestO } from "../../../utils/httpsRequest";
import { LoaderSpinner } from "../../../components/LoaderSpinner";
import { TrashIcon } from "@radix-ui/react-icons";
import { Label } from "@radix-ui/react-dropdown-menu";
import { useParams } from "react-router-dom";
import { ragService } from "api/services/PROJECT-O";
import { LoaderModal } from "components/LoaderModal";
import { Trash2 } from 'lucide-react';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();


const PanelViewDiv = styled.div`
display: flex;
flex-direction: column;
  border-style: solid;
  border-radius: 0.5rem;
  border-width: ${({ $isSelected }) => ($isSelected ? "2px" : "1px")};
  border-color: ${({ $isSelected }) =>
    $isSelected ? "var(--primary-color)" : "lightgrey"};
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0 5px 5px
    ${({ $withShadow }) => ($withShadow ? "rgba(0,0,0,0.1)" : "rgba(0,0,0,0)")};
  font-size: 0.9rem;
  cursor: ${({ $isSelectable }) => ($isSelectable ? "pointer" : "default")};
`;
const FloatingDiv = styled.div`
  position: absolute;
  padding: 0.75rem;
  background-color: white;
  min-width: 100px;
  min-height: 100px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);

  left: 10px;
  transform: translate(-50%, 0%);
  top: 20%;
  left: 50%;
`;


function PanelView({
  panelId,
  children,
  selectable,
  setSelected,
  selected,
  withShadow,
  value,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const PanelRef = useRef(null);

  useEffect(() => {
    console.log(selected);
    if (panelId === selected?.content && selectable) {
      PanelRef.current.scrollIntoView({ behavior: "smooth" });
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selected?.content]);
  const handleClick = () => {
    if (selectable) {
      setSelected(value);
    }
  };
  return (
      <PanelViewDiv
      ref={PanelRef}
      $withShadow={withShadow}
      $isSelected={isSelected}
      $isSelectable={selectable}
      onClick={handleClick}
    >
          {children}
      </PanelViewDiv>
  );
}
function PanelView2({
  panelId,
  children,
  selectable,
  setSelected,
  selected,
  withShadow,
  value,
}) {
  const [isSelected, setIsSelected] = useState(false);
  const PanelRef = useRef(null);

  useEffect(() => {
    console.log(selected);
    if (panelId === selected?.documentRecord?._id && selected) {
      PanelRef.current.scrollIntoView({ behavior: "smooth" });
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [selected?.content, selected?.documentRecord?._id]);
  const handleClick = () => {
    if (selectable) {
      setSelected(value);
    }
  };
  return (
      <PanelViewDiv
      ref={PanelRef}
      $withShadow={withShadow}
      $isSelected={isSelected}
      $isSelectable={selectable}
      onClick={handleClick}
    >
          {children}
      </PanelViewDiv>
  );
}
function FloatingPDFView({ children }) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  return (
      <>
          <IoMdEye
        size={20}
        style={{ cursor: "pointer" }}
        onClick={() => {
          document.getElementById("compareDocument").style.display = "block";
        }}
        color={"gray"}
      />
          <div style={{ display: "none" }} id={"compareDocument"}>
              <FloatingDiv ref={ref}>
                  {/* <div className="v3-legal-floating-view-header">
            <BsX
              size={20}
              style={{ cursor: "pointer" }}
              onClick={() =>
                (document.getElementById("compareDocument").style.display =
                  "none")
              }
            />
          </div> */}
                  {children}
              </FloatingDiv>
          </div>
      </>
  );
}

export default function DemoModeWeb() {
  // const subOragID = "665468884b7c867c744381a9";
  // const oragID = "665467d84b7c867c744381a0";
  const [activeStep, setActiveStep] = useState("use-case");
  const [activeTab, setActiveTab] = useState("selectUseCase")
  const [processed, setProcessed] = useState(false);
  const [docList, setDocList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [viewedPdf, setViewedPdf] = useState(null);
  const [viewKeyTerm, setViewKeyTerm] = useState(null);
  const [mode, setMode] = useState(null);
  const [showCompareResult, setShowCompareResult] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [selectedRag, setSelectedRag] = useState("methodA");
  const [fileArrStatus, setFileArrStatus] = useState([]);
  const [fileArr, setFileArr] = useState([]);
  const { user, addNewNotifcation } = useContext(Context);
  const [selectedTab, setSelectedTab] = useState(null);
  const [testStream, setTestStream] = useState([]);
  const [checked, setChecked] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState([])
  const FileInputRef = useRef(null);
  const [modelInfo, setModelInfo] = useState(null);
  const [modelName, setModelName] = useState('');
  const [modelItems, setModelItems] = useState([]);
  const [modelLoading, setModelLoading] = useState(false);
  
  const [prompt, setPrompt] = useState('');
  const [decodingMethod, setDecodingMethod] = useState('');
  const [modelIdName, setModelIdName] = useState('');
  const [maxTokens, setMaxTokens] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedModel, setSelectedModel] = useState();



  // State to track step enablement
  const [stepEnabled, setStepEnabled] = useState({
    useCase: true,
    selectDocument: false,
    chat: false,
  });

  const {subOragID, oragID} = useParams()
  
  const handleGetDocuments = async () => {
      try {
          setIsLoading(true);
          const preDefinedPromise = ragService.listDocuments(process.env.REACT_APP_COMMON_ORGANIZATION_ID, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, {'assistant':`web-engagement`})
          const userDefinedPromise = ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {uploader: user.email})
          
          const [preDefinedDocs, userDefinedDocs] = await Promise.all([preDefinedPromise, userDefinedPromise]);
        //   join user uploader with predefined
          const allDocs = [...preDefinedDocs.data, ...userDefinedDocs.data]
          
          setDocList(allDocs.map((file) => ({
            documentRecord: { ...file },
          })));

      } catch (err) {
          console.log('err: ', err);
      } finally {
          setIsLoading(false);
      }
  };

  useEffect(() => {
    console.log(viewKeyTerm);
  }, [viewKeyTerm]);

  useEffect(() => {
    console.log(testStream);
  }, [testStream]);


  useEffect(() => {
    console.log(fileArr)
    handleGetDocuments();
    showListOfModels()
    handleListUploadedFiles()
    console.log(user);
  }, []);
  
  useEffect(() => {
    console.log(fileArrStatus);
  }, [fileArrStatus]);

  const handleProcessFiles = async () => {
    setIsLoading(true);
    try {
        for await (const fileObj of fileArr) {
            const formData = new FormData();
            const file = fileObj.file;

            formData.append('organization_id', oragID);
            formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
            formData.append('files', file);
            formData.append('metadata', JSON.stringify({ project: "webdemo_upload", uploader: user.email, filename: file.name }));
            formData.append('chunk_size', 700);
            formData.append('chunk_overlap', 70);
            formData.append('bucket_name', `${oragID}-bex-demo-mode`);

            const res = await ragService.processFile(formData)

            console.log('API Response:', res.data);

            const { document_ids } = res.data;
            const _id = document_ids[0];

            // Update file status immediately
            setFileArr(prevFiles =>
                prevFiles.map(file => {
                    if (file.file.name === fileObj.file.name) {
                        return { ...file, _id, status: "Processed" };
                    }
                    return file;
                })
            );

            setUploadedDocs(prevDocs => [
                ...prevDocs,
                { documentRecord: { ...fileObj, _id, status: "Processed", file_name: fileObj.file.name } }
            ]);

            addNewNotifcation('Document added successfully', 'success');
            console.log("THIS IS ID", _id);
        }
        
        //await handleGetDocuments();
    } catch (error) {
        console.error('Error uploading document:', error);
        addNewNotifcation('Document upload failed. Please try again.', 'danger');
    } finally {
        setIsLoading(false);
    }
};


const showListOfModels = async () => {  
  try {
    setModelLoading(true)
    const res = await httpRequestO.get(`/projecto/api/v1/model/foundationModel/get_all_models?org_id=${oragID}&sub_org_id=${subOragID}&metadata={"project":"use_case_models"}`);

    const filteredModels = res.data.filter(model => {
      //const metadata = JSON.parse(model.metadata || '{}');
      return model.org_id === `${oragID}`;
    });

    setModelItems(filteredModels.map(model => ({
      id: model._id,
      name: model.unique_name,
      modelId: model.modelId,
      parameters: {
        mode: model.parameters?.mode || '',
        maxTokens: model.parameters?.max_tokens || 0,
        maxNewTokens: model.parameters?.max_new_tokens || 200,
        stopSequences: model.parameters?.stop_sequences || [],
        repetitionPenalty: model.parameters?.repetition_penalty || 1.2
      },
      prompt: model.prompt,
      userId: model.user_id,
      description: model.modelDescription,
      placeholders: model.placeholders
    }))); 
  } catch (error) {
    console.error('Error showing list of models:', error);
  } finally {
    setModelLoading(false);
  }
};

  const handleClear = () => {
    setSelectedModel(null); // Clear selected model
    setModelInfo(null); // Clear model information
    setPrompt(''); // Clear prompt
    setDecodingMethod(''); // Clear decoding method
    setModelIdName(''); // Clear model ID name
    setMaxTokens(''); // Clear max tokens
  };
  
  const handleListUploadedFiles = async () => {
    try {
        const res = await ragService.listDocuments(oragID, process.env.REACT_APP_DEMO_MODE, {"project":"webdemo_upload", uploader: user.email})
        setUploadedDocs(res.data.map(doc=>({documentRecord:doc,name:doc.file_name,value:{documentRecord:doc}})))
     
    } catch (error) {
      console.error('Error showing document:', error);
      // addNewNotifcation('Document upload failed. Please try again.', 'danger');
    } finally {
      setIsLoading(false);
    }
  }

  const handleSetUploadFiles = (e) => {
    if (Array.from(e.target.files).length <= 2) {
        setMode("upload");
        const filesWithMetadata = Array.from(e.target.files).map(file => ({
            file,
            status: "Ready to upload",
            progress: 0,
            label: file.name,
        }));

        setFileArr(filesWithMetadata);
        setFileArrStatus(filesWithMetadata.map(file => ({
            progress: 0,
            status: "Ready to upload",
            label: file.label,
            resps: [],
        })));
    } else {
        e.preventDefault();
        addNewNotifcation("Cannot upload more than 2 files", "danger");
        e.target.value = null;
    }
};

  useEffect(() => {
    setViewKeyTerm(null);
    setViewedPdf(null);
    // setProcessed(false)
  }, [selectedDocument, fileArrStatus]);

  useEffect(() => {
    console.log("line 541", selectedDocument);
    if (selectedDocument.length > 0) {
      setMode("dropdown");
    } else {
      setMode("upload")
    }
    
  }, [selectedDocument]);
  useEffect(()=>{console.log("MODE",mode)},[mode])

  useEffect(() => {
    console.log('Selected Model:', selectedModel);
  }, [selectedModel]);

  const listSelectedDocument = () => {
    return selectedDocument.map((value) => (
        <Card key={value.documentRecord._id} className="mt-2">
            <CardHeader>
                <CardTitle>{value.documentRecord.file_name}</CardTitle>
            </CardHeader>
        </Card>
    ));
};
  const handleIfSelected = (doc) => {
    if (
      selectedDocument.find(
        (document) => document.documentRecord._id === doc.documentRecord._id
      )
    )
      return true;
    else return false;
  };
  
  const handleDelete = (_id) => {
    ragService.deleteDocument(oragID, process.env.REACT_APP_DEMO_MODE, _id, `${oragID}-bex-demo-mode`)
      .then((res) => {
        setUploadedDocs(prev => prev.filter((obj) => obj.documentRecord._id !== _id));
        addNewNotifcation('Document deleted successfully', 'success');
        console.log('Document deleted successfully:', res);
      }).catch((err) => {
        console.error('Error deleting document:', err);
        console.log('ID num', _id)
      }).finally(() => {
        handleGetDocuments();
      });
  }
  

useEffect(() => {
  setStepEnabled((prevState) => ({
    ...prevState,
    selectDocument: !!selectedModel,
  }));
}, [selectedModel]);

useEffect(() => {
  setStepEnabled((prevState) => ({
    ...prevState,
    chat: !!selectedModel && (selectedDocument.length > 0 || uploadedDocs.length > 0),
  }));
}, [selectedDocument, uploadedDocs, selectedModel]);

useEffect(() => {
  console.log('Step Enabled:', stepEnabled);
}, [stepEnabled]);

const handleStepChange = (step) => {


  if (stepEnabled[step]) {
    setActiveStep(step);
    setUploadedDocs([]); 
  } else {
    console.log(`Step '${step}' is not enabled.`);

  if (step === "chat" && (!uploadedDocs.length || !selectedDocument.length)) {
    addNewNotifcation('Please upload a document or select a document to proceed.', 'warning');
    return;
  }
  
    
  }
};

const handleTabChange = (tab) => {
  setActiveTab(tab);
  if (tab === "selectDocument") {
    handleGetDocuments(); 
  }
  setUploadedDocs([]);  
};

useEffect(() => {
  if (activeTab !== "selectDocument" && activeStep === "selectDocument") {
    setUploadedDocs([]);
  }
}, [activeTab, activeStep]);

useEffect(() => {
  if (activeTab === 'select-document') {
    handleGetDocuments(); 
  }
}, [activeTab]);


return (
    <div className="flex gap-2 flex-wrap flex-grow">
        <Card className={`min-w-[70%] relative flex flex-grow flex-col p-1 ${activeStep === 'use-case' ? (selectedModel ? 'h-[590px]' : 'h-[320px]') : ''} ${activeStep === 'selectDocument' ? 'h-[500px]' : ''} ${activeStep === 'chat' ? 'h-[680px]' : ''}`}>
            <CardHeader>
                <CardTitle>Web Assistant Demo</CardTitle>
                <CardDescription>
                    The BeX Assistant for Web Engagement is an advanced conversational AI platform designed to address the limitations and simplifies support and enhances user experiences. See how easily we can create and showcase a chatbot tailored to your enterprise's needs.
                </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-grow h-1 flex-col box-border">
                <div className="flex items-center justify-center space-x-4">
                    <div
                      className={`flex items-center space-x-2 ${activeStep === "use-case" ? "text-primary" : "text-muted-foreground"} ${!stepEnabled["useCase"] ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                      onClick={() => setActiveStep("use-case")}
                    >
                        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${activeStep === "use-case" ? "bg-primary text-primary-foreground" : "border border-muted-foreground text-muted-foreground"}`}>
                            1
                        </div>
                        <span>Select Use Case</span>
                    </div>
                    <div
                      className={`flex items-center space-x-2 ${activeStep === "selectDocument" ? "text-primary" : "text-muted-foreground"} ${!stepEnabled["selectDocument"] ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                      onClick={() => handleStepChange("selectDocument")}
                    >
                        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${activeStep === "selectDocument" ? "bg-primary text-primary-foreground" : "border border-muted-foreground text-muted-foreground"}`}>
                            2
                        </div>
                        <span>Select Document</span>
                    </div>
                    <div
                      className={`flex items-center space-x-2 ${activeStep === "chat" ? "text-primary" : "text-muted-foreground"} ${!stepEnabled["chat"] ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}
                      onClick={() => handleStepChange("chat")}
                    >
                        <div className={`flex h-8 w-8 items-center justify-center rounded-full ${activeStep === "chat" ? "bg-primary text-primary-foreground" : "border border-muted-foreground text-muted-foreground"}`}>
                            3
                        </div>
                        <span>Chat with your Generated Bot</span>
                    </div>
                </div>

                {activeStep === "use-case" && stepEnabled["useCase"] && (
                <div className="mt-3 min-h-[80%] overflow-auto">
                    <Label className="mb-3">Please select a use case  you're interested in from the options provided to begin the chatbot generation process. Your selection will determine the focus and functionality of the chatbot.</Label>
                    <div className="flex">
                        <div className="relative flex max-w-[200px]">

                            {modelLoading && <LoaderModal />}
                            <Combobox
                                items={modelItems}
                                defaultDisplayed="Select Model"
                                style={{ width: '500px' }}
                                selectedValue={selectedModel}
                                setter={setSelectedModel}
                              />
                        </div>

                        <Button variant="secondary" size="icon" className="ml-2" onClick={handleClear}>
                            <RotateCcw className="w-5 h-5" />
                        </Button>
                    </div>
                    {selectedModel && (
                    <div className="mt-3 h-[90%]">
                        <Card className="mb-3">
                            <CardHeader>
                                <CardTitle>Prompt Used</CardTitle>
                                <CardDescription>
                                    {selectedModel.prompt || 'No prompt available.'}
                                </CardDescription>
                                <br />
                            </CardHeader>
                        </Card>
                        <Card>
                            <CardHeader>
                                <CardTitle>Model Details</CardTitle>
                                <CardDescription>
                                    {selectedModel.modelId || 'No model available.'}
                                </CardDescription>
                                <CardTitle>Model Parameters</CardTitle>
                                <CardDescription>
                                    Decoding Method: {selectedModel.parameters.mode} <br />
                                    Max Tokens: {selectedModel.parameters.maxNewTokens}
                                </CardDescription>
                            </CardHeader>
                        </Card>
                    </div>
                  )}
                </div>
                )}

                {activeStep === "selectDocument" && stepEnabled["selectDocument"] && (
                <Tabs defaultValue="select-document" className="mt-6 overflow-auto" onValueChange={handleTabChange}>
                    <TabsList>
                        <TabsTrigger value="select-document" >Select Document</TabsTrigger>
                        <TabsTrigger value="upload-document" disabled={selectedDocument.length >= 1}>Upload Document</TabsTrigger>
                    </TabsList>
                    <TabsContent value="select-document">
                        <div className="space-y-2">
                            <Label htmlFor="document">Select up to two documents to build your chatbot’s knowledge base. These will be the foundation for crafting a chatbot that truly understands and addresses your specific needs.</Label>
                            <Card>
                                <CardHeader>
                                    <CardTitle>Select Sample Documents</CardTitle>
                                    <CardDescription>These are all preloaded documents you can try to generate your chatbot. Select up to two files from the selection.</CardDescription>
                                </CardHeader>
                                <CardContent>
                                    <label>
                                        <ShadeDD.DropdownMenu>
                                            <ShadeDD.DropdownMenuTrigger asChild>
                                                <Button className="w-fit block mt-1" >Select files</Button>
                                            </ShadeDD.DropdownMenuTrigger>
                                            <ShadeDD.DropdownMenuContent side="bottom">
                                                <ShadeDD.DropdownMenuLabel>Documents</ShadeDD.DropdownMenuLabel>
                                                <ShadeDD.DropdownMenuSeparator />
                                                {docList.length === 0 ? (
                                                    <ShadeDD.DropdownMenuItem>No available documents found.</ShadeDD.DropdownMenuItem>
                                                  ) : (
                                                    docList.map((doc) => (
                                                        <div className="flex gap-1 justify-between">
                                                            <ShadeDD.DropdownMenuCheckboxItem
                                                            className="w-full"
                                                            key={doc.documentRecord._id}
                                                            checked={handleIfSelected(doc)}
                                                            onSelect={(event) => {
                                                              const result = selectedDocument.find(
                                                                (document) =>
                                                                  document.documentRecord._id === doc.documentRecord._id
                                                              );
                                                              event.preventDefault();
                                                              if (result) {
                                                                setSelectedDocument((prev) =>
                                                                  prev.filter(
                                                                    (docToFilter) =>
                                                                      docToFilter.documentRecord._id !== result.documentRecord._id
                                                                  )
                                                                );
                                                              } else {
                                                                if (selectedDocument.length < 2) {
                                                                  setSelectedDocument((prev) => [
                                                                    ...prev,
                                                                    {
                                                                      name: doc.documentRecord?.filename,
                                                                      value: { doc },
                                                                      documentRecord: doc.documentRecord,
                                                                    },
                                                                  ]);
                                                                }
                                                              }
                                                            }}
                                                          >
                                                                {doc.documentRecord?.file_name}

                                                                
                                                            </ShadeDD.DropdownMenuCheckboxItem>
                                                            {doc.documentRecord.organization_id !== "demo" &&
                                                                <Button variant="ghost" size="icon" onClick={() => handleDelete(doc.documentRecord._id)}>
                                                                    
                                                                    <Trash2 className="w-5 h-5"/>
                                                                </Button>}
                                                        </div>
                                                        
                                          ))
                                        )}
                                            </ShadeDD.DropdownMenuContent>

                                        </ShadeDD.DropdownMenu>
                                    </label>
                                    {listSelectedDocument()}
                                </CardContent>
                            </Card>
                        </div>
                    </TabsContent>
                    <TabsContent value="upload-document">
                        <div className="space-y-2 w-[100%]">
                            <Label htmlFor="upload">Try uploading your own documents to create a customized knowledge base for your chatbot. </Label>
                            <div className="flex items-center space-x-2">
                                {/* Insert here !!! */}
                                <Card className="w-[100%]">
                                    <CardHeader>
                                        <CardTitle>Upload Documents</CardTitle>
                                        <CardDescription>Upload a maximum of 2 files by clicking the "Choose files". Supported files are .txt, .docx, .xlsx.</CardDescription>
                                    </CardHeader>
                                    <CardContent>
                                        <label>
                                            <Input
                                          ref={FileInputRef}
                                          multiple
                                          type="file"
                                          className="bg-background block mt-1"
                                          onChange={handleSetUploadFiles}
                                        />
                                        </label>
                                        <div className="flex-grow overflow-auto">
                                            {uploadedDocs.map((doc) => (
                                                <Card key={doc.documentRecord._id} className="flex-grow flex justify-between items-center mt-4">
                                                    <CardHeader>
                                                        <CardTitle>{doc.documentRecord.file_name}</CardTitle>
                                                    </CardHeader>
                                                    <Button className="mr-4"onClick={() => handleDelete(doc.documentRecord._id)}>
                                                        <TrashIcon />
                                                    </Button>
                                                </Card>
                                          ))}
                                        </div>
                                    </CardContent>
                                    <CardFooter className="pt-1 flex gap-2 flex-row-reverse">
                                        <Button
                                        //disabled={uploadedDocs.length < 1 || isLoading}
                                        onClick={handleProcessFiles}
                                        className="w-fit hover:cursor-pointer right-0"
                                      >
                                            {isLoading ? <LoaderSpinner /> : "Process"}
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </div>
                        </div>
                    </TabsContent>
                </Tabs>
                )}

            
                {activeStep === "chat" && (
                <div className="flex flex-col h-full">
                    {selectedModel ? (
                        <WebChat
                                selectedDocument={selectedDocument}
                                uploadFilter={{ project: "webdemo_upload", uploader: user.email }}
                                modelPrompt={selectedModel.prompt}
                                decodingMethod={selectedModel.parameters.mode}
                                modelIdName={selectedModel.modelId}
                                maxTokens={selectedModel.parameters.maxNewTokens}
                              />
                        ) : (
                            <div>Select a model to start using the chat assistant.</div>
                        )}
                </div>
                )}

            </CardContent>
            {/* <CardFooter className="mt-6 pt-2">
                <Button variant="outline" onClick={() => setActiveStep("use-case")}>Back</Button>
            </CardFooter> */}
        </Card>
    </div>
);
}

function RefreshCwIcon(props) {
  return (
      <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
          <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8" />
          <path d="M21 3v5h-5" />
          <path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16" />
          <path d="M8 16H3v5" />
      </svg>
  )
}

function UploadIcon(props) {
  return (
      <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
          <polyline points="17 8 12 3 7 8" />
          <line x1="12" x2="12" y1="3" y2="15" />
      </svg>
  )
}
