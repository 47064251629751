import React, { useContext, useState, useEffect, useImperativeHandle, useRef, createContext } from 'react'
import './smart-contract-styles.css'
import { Context } from '../../../context/GlobalState'
import Breadcrumb from 'components/Breadcrumb'
import SetupCard from '../../../components/AppSetup/SetupCard'
import SetupModal from '../../../components/AppSetup/SetupModal'
import { useNavigate } from 'react-router-dom'
import { httpRequestY } from '../../../utils/httpsRequest'
import QueryPlagroundDemo from '../../../pages/Knowledge/Collection/components/queryPlayground/queryPlaygroundDemo'
import styled from 'styled-components'
import { RedButton, WhiteButton } from '../../../components/Buttons'
import { LoaderSpinner } from '../../../components/LoaderSpinner'

const DemoContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 0px;
    padding: 10px;
`
const ButtonPrimary = styled.button`
padding: 10px 15px;
border: 1px solid var(--primary-color);
cursor: pointer;
background-color: var(--primary-color);
color: #fff;
`
const AddQueryButton = styled(WhiteButton)`
  cursor: ${(props) => props.resLoading ? 'not-allowed' :  'pointer'};
  opacity: ${(props) => props.resLoading ? '0.5' :  '1'};
  white-space: nowrap;
`
const SendButton = styled(RedButton)`
  cursor: ${(props) => props.resLoading ? 'not-allowed' :  'pointer'};
  opacity: ${(props) => props.resLoading ? '0.5' :  '1'};
  white-space: nowrap;
`
export const LoadingContext = createContext()
export default function SmartContractDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your contract repository where the BeX AI Assistant will be connecting to and reading',
        'A set of legal clauses that you want the Assistant to extract by default'
    ];

    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for legal contract management is designed for corporate lawyers who need to manage their documents.',
        'This BeX AI Assistant is capable of reading your repository of contract documents, extract out the key clauses and handles the contract workflow '
    ];

    const projectIdDemo = "b36df648-68c4-4cb7-9150-8d6fed0a4fca"
    const navigate = useNavigate()
    const { user } = useContext(Context)
    const [openModal, setOpenModal] = useState(false)
    const [collections,setCollections] = useState([])
    const [loadingParams,setLoadingParams] = useState([false])
    const addQueryRef = useRef(null)
    const [resLoading, setResLoading] = useState(false);
    
    const handleDemoClick = () => {
        setLoadingParams(true)
        httpRequestY.post('/Y/api/discovery/getcollections', {projectId: projectIdDemo })
        .then((res) => {
            console.log('res: ', res);
            setCollections(res.data.collections)
        }).catch((err) => {
            console.log('error: ', err);
            setLoadingParams(false)
        })
        setOpenModal(!openModal)
    }

    useEffect(() => {


    }, [])

    const handleSetupClick = () => {
        if (user.organization) {
            navigate(`/organizations/organization/${user.organization}/contract-management/projects`)
        } else {
            navigate(`/organizations`)
        }
    }
   
    return (
        <div className='smart-contract-dashboard-container'>
            {/* <div>
                <AsideMenu asidewidth='200px'>
                    <SettingsSection title='Smart Contract Dashboard' list={settingsOrgList(user.organization)} />
                </AsideMenu>
            </div> */}

            <div className='smart-contract-dashboard-content'>
                <div className='dashboard-app-setup-container'>
                    <Breadcrumb/>
                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for answering ESG questions'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant' 
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                        <SetupModal title={'Demo Mode for BeX AI Assistant (Document Information Extraction)'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='smart-contract-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO:  Select a collection, and select documents from the collections to extract information!
                                    </div>
                                    <div className='demo-insturctions__text'>
                                        <span>
                                            This BeX AI Assistant is capable of extracting information from contracts and generate a reply from it. It is important to select collections first then select documents from the selected collections that you want to query.
                                        </span>
                                        <span>
                                            You can add a context such as making the AI be someone like an HR, or others! You can add also additional information with specific definitions to help the AI to know what to look for.
                                        </span>
                                        <span>
                                            Add your query in the query field. Put whatever you want to be extracted from the document.
                                        </span>
                                        
                                    </div>
                                </div>
                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                                <DemoContainer>
                                    <LoadingContext.Provider value={{resLoading, setResLoading}}>
                                        <QueryPlagroundDemo ref={addQueryRef} projectDemo={projectIdDemo} collectionList={collections.map((item) => ({name: item.name, id: item.collection_id,}))}/>
                                        <div className='sc-action-button-bottom'>
                                            <AddQueryButton resLoading={resLoading} onClick={()=>{addQueryRef.current?.add("","",true)}}>Add query</AddQueryButton>
                                            <SendButton resLoading={resLoading} onClick={()=>{addQueryRef.current?.processDoc()}}>{!resLoading ? "Process": <LoaderSpinner/>}</SendButton>
                                        </div>
                                    </LoadingContext.Provider>
                                </DemoContainer>
            

                            </SetupModal.StyledSetup>
                        </SetupModal>
                    </div>
                </div>
            </div>
        </div>
    )
}
