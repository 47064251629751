import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { httpRequestO } from "../../../utils/httpsRequest";
import { Context } from "../../../context/GlobalState";
import { useParams } from "react-router-dom";
import { Button } from "../../../components/ui/button";
import { Card, CardContent } from "../../../components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "../../../components/ui/table"
import { Progress } from "../../../components/ui/progress"
import { Avatar, AvatarImage, AvatarFallback } from "../../../components/ui/avatar"
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';

const MatchResult = ({ selectedCV, selectedJD, selectedFolder , orgID, subOrgID, uploadFilter,uploadedJD,uploadedCV }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [matchResults, setMatchResults] = useState([]);
  const [messages, setMessages] = useState([]);
  const [buttonText, setButtonText] = useState("Match");
  const inputRef = useRef(null);
  const messageListRef = useRef(null);
  const { addNewNotifcation, user } = useContext(Context);
  const {subOragID, oragID} = useParams();

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [isLoading]);

  useEffect(() => {
    let timer1, timer2, timer3;

    if (isLoading && !matchResults.length) {
      timer1 = setTimeout(() => setButtonText("Loading..."), 0);
      timer2 = setTimeout(() => setButtonText("Scanning CVs..."), 10000);
      timer3 = setTimeout(() => setButtonText("Checking qualifications..."), 20000);
      setTimeout(() => setButtonText("Matching candidates..."), 30000);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [isLoading, matchResults]);

  const handleQueryJD = async () => {
    const jdPromiseArr = [];
    let fromRag = "";
    console.log("SELECTED JD:",selectedJD)
    try {
      if (selectedJD.length === 0){
        console.log("NO SELECTED")

          const formData = new FormData();
          formData.append('organization_id', oragID);
          formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
          formData.append("pre_filter", JSON.stringify({folder:"JD_upload",uploader:user.email}));
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          const queryResponse = await httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData);
          console.log(queryResponse);
          for (let pageContent of queryResponse.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        
        return fromRag;
      } 
      // BELOW IS NEEDED
      else if (selectedJD.length > 0){
        // console.log("SELECTED JD: ", selectedJD);
        for (const [index, document] of selectedJD.entries()) {
          // console.log("DOC ID", document.documentRecord._id)
          console.log(document);
          const formData = new FormData();
          formData.append('organization_id', document.documentRecord.organization_id ? document.documentRecord.organization_id : orgID);
          formData.append('sub_organization_id', document.documentRecord.sub_organization_id ? document.documentRecord.sub_organization_id : process.env.REACT_APP_DEMO_MODE);
          formData.append('document_id', document.documentRecord._id);
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          jdPromiseArr.push(httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, formData));
        }

        const jdResponses = await Promise.all(jdPromiseArr);
        for (let documentResult of jdResponses) {
          for (let pageContent of documentResult.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        }
        return fromRag;
      }
      
    } catch (error) {
      console.log('Error in handleQueryJD:', error);
    }
  };

  const handleQueryCV = async () => {
    const cvPromiseArr = [];
    let fromRag = "";
    try {
      //BELOW IS NEEDED
      if (selectedCV.length > 0) {
        console.log("SELECTED CVV: ", selectedCV);
        // console.log("")
        for (const document of selectedCV) {
          const formData = new FormData();
          formData.append('organization_id', document.documentRecord.organization_id ? document.documentRecord.organization_id : orgID);
          formData.append('sub_organization_id', document.documentRecord.sub_organization_id ? document.documentRecord.sub_organization_id : process.env.REACT_APP_DEMO_MODE);
          formData.append('document_id', document.documentRecord._id);
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          cvPromiseArr.push(httpRequestO.post('/projecto/api/v1/embedding_api_service/query_data/', formData));
        }
        const cvResponses = await Promise.all(cvPromiseArr);
        for (let documentResult of cvResponses) {
          for (let pageContent of documentResult.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${pageContent.metadata.filename}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        }

        
        return fromRag;

      }
      
      
      else if (selectedCV.length< 1){
        console.log("SELECTED FOLDER", selectedFolder);
        for (const [index, document] of selectedCV.entries()) {
          const formData = new FormData();
          formData.append('organization_id', oragID);
          formData.append('sub_organization_id', process.env.REACT_APP_DEMO_MODE);
          formData.append('pre_filter', JSON.stringify({folder:selectedFolder}));
          formData.append('k', 15);
          formData.append('query', "Experience and Skills");

          cvPromiseArr.push(httpRequestO.post(`/projecto/api/v1/embedding_api_service/query_data/`, formData));
        }

        const jdResponses = await Promise.all(cvPromiseArr);
        for (let documentResult of jdResponses) {
          for (let pageContent of documentResult.data.data.documents) {
            fromRag += `[Document]\n`;
            fromRag += `Document Name: ${selectedCV.find(doc => doc._id === pageContent.documentRecord.metadata.document_id).name}\n`;
            fromRag += `${pageContent.page_content}\n`;
            fromRag += `[End]\n`;
          }
        }
        return fromRag;
      }
    
    } catch (error) {
      console.log('Error in handleQueryCV:', error);
    }
  };

  const handleSendMessage = async () => {
    setIsLoading(true);
    console.log("SENDING")
    const relatedPassagesJD = await handleQueryJD();
    // console.log("RESULT of JD Retrieval", relatedPassagesJD);
    const relatedPassagesCV = await handleQueryCV();
    // console.log("RESULT of CV Retrieval", relatedPassagesCV);


    let fullPrompt = `Input:
You are provided with this job description: ${relatedPassagesJD ? relatedPassagesJD : ""}, and a list of all the applicants' CV: ${relatedPassagesCV ? relatedPassagesCV : ""}. 
Your task is to evaluate closely who among the candidates' qualifications match the job description. Each criterion has a specific weight in determining the overall similarity score. 
Calculate the match percentage between the job description and the CV based on the following criteria and their respective weights:

Experience (30%): Compare the candidate's relevant experience with the job requirements.
Skills (25%): Match the top 5 skills found in the CV that are listed or related in the job description requirements, if none, make it null.
Education (20%): Assess how the candidate’s educational background aligns with the job qualifications.
Job Title (15%): Compare past job titles and responsibilities with those in the job description.
Location (5%): Check if the candidate’s location and availability fit the job requirements.


Provide the result as a JSON object with the following fields:

"candidate_name": Name of the candidate.
"hiring_position": Title of the job they are applying.
"remarks": Provide if applicant is qualified when most of the job requirements are met or not qualified if applicant has not met the requirements or when overqualified.
"criteria": A JSON object detailing each criterion:
"skills": Top 5 Relevant Skills of applicant that ONLY matched with Job Description's requirements.
"experience": Years of Related Experience in the field and Relevant Experiences of applicant that ONLY matched with Job Description
"education": Highest attainment of applicant 
"job_title": Previous job of applicant
"location":Location of applicant

Output:
`;

    const requestData = {
      prompt: fullPrompt,
      modelId: "mistralai/mistral-large", //or codellama
      parameters: {
        decoding_method: "greedy",
        max_new_tokens: 2000,
        min_new_tokens: 0,
        repetition_penalty: 1,
        stop_sequences: ["\n\n\n"]
      },
      query: ""
    };

    console.log("Request Data:", requestData);

    try {
      const llmResp = await httpRequestO.post(
        "/projecto/api/v1/model/foundationModel/experiment?mode=freeMode",
        requestData
      );

      const responseText = llmResp.data.prediction;
      console.log("LLM Response:", responseText);

       // Clean up the response text
       const cleanedResponseText = responseText
       .replace(/```json/g, '') 
       .replace(/```/g, '')    
       .trim();
       
     let jsonResponse = [];
     try {

       const responseArray = cleanedResponseText.split('\n\n').filter(Boolean).map(item => {
         try {
           return JSON.parse(item);
         } catch (parseError) {
           console.error("Error parsing individual JSON:", parseError);
           return null;
         }
       }).filter(item => item !== null);

     
       jsonResponse = responseArray;
       console.log("Cleaned response:", jsonResponse)
     } catch (parseError) {
       console.error("Error parsing LLM response:", parseError);
     }

     setMatchResults(jsonResponse);
     const newMessage = {
       content: JSON.stringify(jsonResponse, null, 2)
     };

     setMessages((prevMessages) => [...prevMessages, newMessage]);

   } catch (error) {
     console.error("Error fetching response:", error);
   } finally {
     setIsLoading(false);
   }
 };

 const formatExperiences = (experiences) => {
  if (Array.isArray(experiences)) {
    // Handle each element in the array
    return experiences.length > 0
      ? experiences.map(exp =>
          typeof exp === 'object' && exp !== null && exp.relevant_experiences
            ? exp.relevant_experiences.join(', ')
            : exp
        ).join(', ')
      : 'No relevant experience for this position.';
  } else if (typeof experiences === 'string') {
    return experiences || 'No relevant experience for this position.';
  } else if (typeof experiences === 'object' && experiences !== null && experiences.relevant_experiences) {
    return experiences.relevant_experiences.length > 0
      ? experiences.relevant_experiences.join(', ')
      : 'No relevant experience for this position.';
  } else if (experiences == null) {
    return 'No relevant experience for this position.';
  } else {
    return 'Unknown format for experiences.';
  }
};



 return (
     <>
         <div className="flex items-center justify-between">
             <div className="space-y-1">
                 <h2 className="text-2xl font-bold mt-6">Match Candidates to Jobs</h2>
                 <p className="text-muted-foreground">Click the button to find the best matches.</p>
             </div>
             <Button
              className="bg-primary text-primary-foreground hover:bg-primary/90"
              onClick={() => {
                  setIsLoading(true);
                  handleSendMessage(); 
              }}
              disabled={isLoading || selectedJD.length === 0 || selectedCV.length === 0}
          >
                 {isLoading ? (
                     <div className="flex items-center justify-center">
                         <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary-foreground" />
                         <span className="ml-2">{buttonText}</span>
                     </div>
              ) : (
                  "Match"
              )}
             </Button>
         </div>
         <Card className="mt-4 bg-card text-card-foreground shadow-sm">
             <CardContent>
                 <Table className="min-w[200px] overflow-y-auto">
                     <TableHeader>
                         <TableRow>
                             <TableHead className="w-20 text-md font-semibold py-3 px-4">Candidate</TableHead>
                             <TableHead className="w-30 text-md font-semibold py-3 px-4">Role</TableHead>
                             <TableHead className="w-[300px] text-md font-semibold py-3 px-4">Experiences</TableHead>
                             <TableHead className="w-[150px] text-md font-semibold py-3 px-4">Skills</TableHead>
                             <TableHead className="text-md font-semibold py-3 px-4">Education</TableHead>
                             <TableHead className="text-md font-semibold py-3 px-4">Location</TableHead>
                             <TableHead className="w-[130px] text-md font-semibold py-3 px-4">Remarks</TableHead>
                         </TableRow>
                     </TableHeader>
                     <TableBody>
                         {matchResults.length > 0 ? (
                          matchResults
                              .sort((a, b) => (b.percentage_match || 0) - (a.percentage_match || 0))
                              .map((result, index) => {
                                  const remarks = result.remarks || "Not known";
                                  const isQualified = remarks.toLowerCase().includes("not qualified");
                                  const rowColor = isQualified ? 'bg-red-100' : 'bg-green-100';
                                  const icon = isQualified ? <CrossCircledIcon className="w-5 h-5 text-red-500" /> : <CheckCircledIcon className="w-5 h-5 text-green-500" /> ;
                                  
                                  return (
                                      <TableRow key={index} className={rowColor}>
                                          <TableCell className="font-medium">
                                              <div className="flex items-center gap-2">
                                                  <div>{result.candidate_name || "N/A"}</div>
                                              </div>
                                          </TableCell>
                                          <TableCell>{result.criteria.job_title || "No previous job"}</TableCell>
                                          <TableCell>
                                              <div>{formatExperiences(result.criteria.experience)}</div>
                                          </TableCell>
                                          <TableCell>
                                              <div className="flex items-center gap-2 ">
                                                  <div>{Array.isArray(result.criteria.skills) && result.criteria.skills.length > 0
                                                      ? result.criteria.skills.join(', ')
                                                      : `No related skills to the mandatory requirements.`}</div>
                                              </div>
                                          </TableCell>
                                          <TableCell>
                                              <div className="flex items-center gap-2">
                                                  <div>{result.criteria.education || "Not known"}</div>
                                              </div>
                                          </TableCell>
                                          <TableCell>
                                              <div className="flex items-center gap-2">
                                                  <div>{result.criteria.location || "Not indicated"}</div>
                                              </div>
                                          </TableCell>
                                          <TableCell>
                                              <div className="flex items-center gap-2">
                                                  <div>{remarks}</div>
                                                  <div>{icon}</div>
                                              </div>
                                          </TableCell>
                                      </TableRow>
                                  );
                              })
                      ) : (
                          <TableRow>
                              <TableCell colSpan={7} className="text-center py-8 text-muted-foreground mt-6">
                                  No results available. Please select job descriptions and candidate CVs to find matches.
                              </TableCell>
                          </TableRow>
                      )}
                     </TableBody>
                 </Table>
             </CardContent>
         </Card>
     </>
);

};

export default MatchResult;