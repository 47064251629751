import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai'
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

const LoaderContainer = styled.div`
    animation: ${rotate} 1s infinite linear;
`;

const AppLoaderContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    background-color: rgba(238, 238, 238, 0.5);
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const LoaderContainerDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    min-height: 100vh;
`


export const LoaderModal = ({...loadingProps}) => {

    return (
        <AppLoaderContainer>
            <LoaderContainer>
                <AiOutlineLoading size={40} {...loadingProps} />
            </LoaderContainer>
        </AppLoaderContainer>
    )
}
