import React from 'react';
import { Container } from '../../../../components/FormInput';


const UpdateUserForm = ({ updateUserInput, setUpdateUserInput }) => {

    const handleInputChange = (key, value) => {
        setUpdateUserInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <Container labelName={'Select type'} style={{ fontWeight: '600', marginUp: '5px' }}>
            <select onChange={(e) => handleInputChange('role', e.target.value)} name="role" value={updateUserInput?.role} style={{ padding: '10px' }} >
                <option value="admin">Admin</option>
                <option value="editor">Editor</option>
            </select>
        </Container>
    );
};

export default UpdateUserForm;