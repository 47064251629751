import { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useOrganization } from "hooks/useOrganization";
import { SubOrgContext } from "context/SubOrganizationContext";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "components/ui/breadcrumb";
// import { Context } from "context/GlobalState";

const StyledLink = styled(Link)`
    text-decoration: none;
    color: ${(props) => (props.$active ? "black" : "#0d638f")};
    text-transform: capitalize;
    pointer-events: ${(props) => (props.$active ? "none" : "")};
`;

export default function BreadcrumbsCustom({ maxPaths }) {
  // const { user } = useContext(Context);
  const { organizations } = useOrganization();
  const { workflow } = useContext(SubOrgContext);

  const workflowAssistants = workflow?.workflowType?.assistants;

  const getAppName = (appId) => {
    return workflowAssistants.find((app) => app._id === appId)?.name;
  };

  const getOrgName = (orgId) => {
    return organizations.find((org) => org._id === orgId)?.name;
  };

  // const getSubOrgName = (subOrgId) => {
  //   return user.subOrganizations.find((subOrg) => subOrg._id === subOrgId)?.name;
  // };

  const DynamicOrgBreadcrumb = ({ match }) => (
      <span>{getOrgName(match.params.organizations)}</span>
  );

  // const DynamicSubOrgBreadcrumb = ({ match }) => (
  //     <span>{getSubOrgName(match.params.sub)}</span>
  // );

  const DynamicAppBreadcrumb = ({ match }) => (
      <span>{getAppName(match.params["bex-assistants"])}</span>
  );

  const routes = [
    {
      path: "/organizations/:organizations",
      breadcrumb: DynamicOrgBreadcrumb,
    },
    {
      path: "/organizations/:organizations/sub/",
      breadcrumb: null
    },
    {
      path: "/organizations/:organizations/sub/:sub",
      breadcrumb: null,
    },
    {
      path: "/organizations/:organizations/sub/:sub/bex-assistants/:bex-assistants",
      breadcrumb: DynamicAppBreadcrumb,
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  const renderedBreadcrumbs = maxPaths ? breadcrumbs.slice(-maxPaths) : breadcrumbs;

  return (
      <Breadcrumb>
          <BreadcrumbList>
              {renderedBreadcrumbs.map(({ match, breadcrumb }, index) => (
                  <BreadcrumbItem key={index}>
                      {index < renderedBreadcrumbs.length - 1 ? (
                          <StyledLink to={match.pathname}>{breadcrumb}</StyledLink>
            ) : (
                <span style={{ textTransform: "capitalize" }}>{breadcrumb}</span>
            )}
                      {index < renderedBreadcrumbs.length - 1 && <BreadcrumbSeparator />}
                  </BreadcrumbItem>
        ))}
          </BreadcrumbList>
      </Breadcrumb>
  );
}
