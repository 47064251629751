import React, { useContext } from 'react';
import { Context } from 'context/GlobalState';
import SubOrgDropdown from 'components/SubOrgDropdown';
import { useParams } from 'react-router-dom';

export default function DashboardSubHeader() {
   // Constants if needed
   const { subOragID } = useParams()
    
    // Context
    const { user } = useContext(Context);;


    const userSubOrganizationCount = user.subOrganizations?.length || 0;
    const hasEnoughSubOrganizations = userSubOrganizationCount > 0;

    if (!subOragID) return
    
    return (
      <>
        <header className="bg-white dark:bg-gray-900 border-y border-gray-200 dark:border-gray-800 px-6 h-14 flex items-center justify-between">
          <div className="flex items-center gap-4">
            {/* <MenuIcon 
              onClick={handleToggleMenu}
              className="h-6 w-6 text-gray-500 dark:text-gray-400 cursor-pointer hidden lg:block" /> */}

              {/* SubOrganizations Selection */}
              {
                hasEnoughSubOrganizations && (
                  <SubOrgDropdown 
                    urlSubOrg={user.subOrganizations.find(subOrg => subOrg._id === subOragID)} />
                )
              }
          </div>
        </header>
      </>
    );
}