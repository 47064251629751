import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";

export const AuthRoute = () => {

    const { user, isAuth } = useContext(Context)
    const navigate = useNavigate()
        
    useEffect(() => {
        if (isAuth) {
            if (!user?.organization) {
                navigate(`/organizations`);
            } else {
                navigate('/organizations');
            }
        }

    }, [isAuth])

    return (
        <Outlet />
    )
    
  };