import React, { useContext } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "../../../../components/ui/card";
import { PartContext } from "../../context/PartContext";

export function PromptOutputLive(){
    const {promptOutputLive} = useContext(PartContext);

    return (
        <Card className="w-full flex flex-col h-full justify-between">
                {/* HEADER */}
                <CardHeader className="border-b-[1px] border-gray-200 px-6 py-4">
                    <CardTitle>Prompt Output</CardTitle>
                    <CardDescription>The generated output will be displayed here.</CardDescription>    
                </CardHeader>

                {/* CONTENT */}
                <CardContent className="p-0 flex flex-col px-6 py-4 flex-grow">
                        <p className='break-words text-sm min-h-[122px] whitespace-pre-line'>
                            {promptOutputLive}
                        </p>
                </CardContent>
            </Card>
    );
}
