import React, { useContext, useEffect } from "react";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../../../../components/ui/card"
import { Button } from "../../../../components/ui/button"
import usePackages from "../../../../hooks/usePackages"
import { AppLoader } from "../../../../components/LoaderSpinner";
import { loadStripe } from '@stripe/stripe-js';
import { server } from "../../../../utils/httpsRequest";
import { useParams, useSearchParams } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { Context } from "../../../../context/GlobalState";
import SubscriptionDetails from "./SubscriptionDetails";
import { SubOrgContext } from "context/SubOrganizationContext";

const stripePromise = loadStripe('pk_test_51MJbjwIqfn3WG6ZB9P2Q1empzz1TPD2JiNrpK6chqSpgxU0c6hb72lYdrNtdRxiVXrf0bXvTQ06VSY3zF0LP5reY0004gUuwjD');

export default function SubOrganizationPlan() {
    
    const { packages, loading, error } = usePackages()
    const [searchParams] = useSearchParams();
    const { addNewNotifcation } = useContext(Context)
    const { subscription } = useContext(SubOrgContext)

    useEffect(() => {
        if (searchParams.get("canceled")) {
            addNewNotifcation('Failed to subscribe to the plan', 'warning')
        }
    }, []);


    if (subscription) {
        return <SubscriptionDetails subscription={subscription.stripeSubscription} />
    }

    if (loading) {
        return <div className='flex flex-grow'> <AppLoader size={50} /> </div>;
    }

    if (error) {
        return <div className='flex flex-grow'> <div>Error: {error}</div>; </div>;
    }



    return (
        <Elements stripe={stripePromise}>
            <section className="flex flex-col flex-grow w-full">
                <div className="flex flex-wrap gap-6 px-4 md:px-6 lg:gap-8">
                    {packages.map((item) => (
                        <PackageCard {...item} key={item.id} />
                    ))}
                </div>
            </section>
        </Elements>

    )
}

const PackageCard = ({ id, price, name, description, priceId }) => {

    const { selectedSubOrganization } = useContext(Context)
    const { oragID, subOragID } = useParams()

    if (!selectedSubOrganization.isMain && name.includes('Free')) return 

    return (
        <Card className={`h-[400px] w-[300px] flex flex-col ${price === 0 && "border-2 border-primary"}`}>
            <CardHeader className='h-[120px]'>
                <CardTitle>{name}</CardTitle>
                <CardDescription>{description}.</CardDescription>
            </CardHeader>
            <CardContent className="grid gap-4">
                <div className="flex items-end gap-2">
                    <span className="text-4xl font-bold">${price}</span>
                    <span className="text-muted-foreground">/mo</span>
                </div>
                <ul className="grid gap-2 text-sm text-muted-foreground">
                    {description?.split(',')?.map((it) => (
                        <li key={it}>
                        <CheckIcon className="mr-2 inline-block h-4 w-4 fill-primary" />
                            {it}
                        </li>
                    ))}
                </ul>
            </CardContent>
            <CardFooter className='mt-auto'>
                {/* <Button onClick={() => setShow(true)} className="w-full">Subscribe</Button> */}
                <form action={`${server}/db/subscriptions/create-checkout-session?organizationId=${oragID}&subOrganizationId=${subOragID}&priceId=${priceId}&isTrial=${name.includes('Free')}`} method="POST">
                    <Button type="submit">
                        Subscribe
                    </Button>
                </form>
            </CardFooter>
        </Card>
    )
}

function CheckIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
        <path d="M20 6 9 17l-5-5" />
        </svg>
    )
}