import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Context } from 'context/GlobalState';
import { useOrganization } from 'hooks/useOrganization';
import { Button } from './ui/button';
import { BuildingIcon, ChevronDownIcon, LucideSettings } from 'lucide-react'
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from './ui/dropdown-menu';


export default function DashboardSubHeader() {
    // Context
    const { user } = useContext(Context);
    const location = useLocation()
    const navigate = useNavigate();
    const { organizations } = useOrganization();

    // State
    const [currentOrganizationID, setCurrentOrganization] = useState(null);

    // Effects
    useEffect(() => {
      handleLocationChange(location);
    }, [location]);

    // Getters
    const userOrganizationCount = organizations.length;
    const hasEnoughOrganizations = userOrganizationCount > 0;
    const organizationTitle = user.organizationObj?.name ?? 'No Organization Selected';

    // Actions   
    const handleOrganizaitonChange = (org) => {}

    const handleLocationChange = (location) => {
      if (location.pathname.includes('/organizations/')) {
        setCurrentOrganization(location.pathname.split('/')[2]);
      } else {
        setCurrentOrganization(null);
      }
    }

    const handleSettingsClick = () => {
        navigate(`/organizations/${currentOrganizationID}/settings`)
    }

    return (
      <>
          <DropdownMenu>
            {/* Active Organization */}
            <div className='flex items-center'>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="flex items-center gap-2">

                  <BuildingIcon 
                    className="h-5 w-5 text-gray-500 dark:text-gray-400" />

                    <span className="text-gray-700 dark:text-gray-300">
                      {organizationTitle}
                    </span>

                    {
                      hasEnoughOrganizations && (
                        <div className="flex items-center space-x-3">
                          <ChevronDownIcon 
                            className="h-4 w-4 text-gray-500 dark:text-gray-400" />
                        </div>
                      )
                    }
                </Button>
              </DropdownMenuTrigger>

              {
                currentOrganizationID && (
                  <LucideSettings 
                    onClick={handleSettingsClick}
                    className="h-4 w-4 text-gray-500 dark:text-gray-400 cursor-pointer" />
                )
              }
            </div>
            

            {/* Organizations Options List */}
            {/* TODO: Check why the organization are not rendered directly after loading */}
            {
              hasEnoughOrganizations && ( 
                <DropdownMenuContent className="w-56">
                  {organizations.map((org, index) => (
                    <DropdownMenuItem 
                        key={index}
                        action={() => handleOrganizaitonChange(org)}>
                        <BuildingIcon 
                          className="h-5 w-5 mr-2 text-gray-500 dark:text-gray-400" />
                        {org?.name} 
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              )
            }
          </DropdownMenu>
      </>
    );
}