import React, { useContext, useState, useEffect } from 'react';
import { Button } from "../../../../components/ui/button";
import { Save } from 'lucide-react';
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../../components/ui/tooltip";
import { PartContext } from "../../context/PartContext";
import {LoaderSpinner} from "../../../../components/LoaderSpinner";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../../../components/ui/dialog";
import { Textarea } from "../../../../components/ui/textarea";
import { httpRequestO } from "../../../../utils/httpsRequest";
import modelList from "../../files/modelList.json";



const LoadModelDialog = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");


    const {
        user,
        addNewNotifcation,
        selectedSubOrganization,
        setPromptLive,
        setSelectedModelLive,
        setParametersLive
    } = useContext(PartContext);

    const handleOnOpen = () => {
        setOpen(true);
    }

    function removeInputOutputMarkers(text) {
        // Remove "Input:\n" at the beginning
        if (text.startsWith("Input:\n")) {
            text = text.substring(7);
        }
    
        // Remove "Output:\n" at the end
        if (text.endsWith("Output:\n")) {
            text = text.substring(0, text.length - 8);
        }
    
        return text;
    }

    function splitAtFirstLine(input) {
        // console.log(input)
        // Find the index of the first newline character
        const splitIndex = input.indexOf("\n\n");
        // console.log(splitIndex)
    
        // If there's no newline, return the whole string as the first part and an empty string as the second part
        if (splitIndex === -1) {
            return { firstPart: input, secondPart: "" };
        }
    
        // Split the string into two parts
        const instruction = input.substring(0, splitIndex).trim();
        const context = input.substring(splitIndex + 1).trim();
        return { instruction, context };
    }


    const OnLoadModel = async () => {
        // Validate

        if (name.trim() === ""){
            addNewNotifcation("Please enter the unique 'Name' of your model.", "warning");
            return;
        }

        setLoading(true);
        try {
            const result = await httpRequestO.get(`/projecto/api/v1/model/foundationModel/get_suborg_model?unique_name=${name}&org_id=${user.organization}&sub_org_id=${selectedSubOrganization._id}`);
            addNewNotifcation("Successfully loaded the model.", "success");

            console.log(result);
            let loadedPrompt = removeInputOutputMarkers(result.data.prompt);
            // const { instruction, context } = splitAtFirstLine(loadedPrompt);
            setPromptLive(prevState => ({
                ...prevState,
                instruction: loadedPrompt.trim(),
                context :"",
                input_data : "",
                sample_input : "",
                sample_output : "",
                target_format : "",
            }));
            let model = modelList.find(model => model.value === result.data.modelId);
            setSelectedModelLive(model);
            setParametersLive(prevState => ({
                ...prevState,
                mode: result.data.parameters.mode,
                temperature: result.data.parameters.temperature ? result.data.parameters.temperature : 0.7,
                max_tokens: 0,
                max_new_tokens: result.data.parameters.max_new_tokens,
                top_p: result.data.parameters.top_k ? result.data.parameters.top_k : 1,
                top_k: result.data.parameters.top_p ? result.data.parameters.top_p : 50,
                stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
                repetition_penalty: 1.2
            }));
            // setSelectedModelLive()
            // console.log(loadedPrompt);
            // console.log(instruction);
            // console.log(context);

            // return result
            setName("");
            setOpen(false);
        }
        catch (error) {
            console.log(error);
            if (error.response.status === 404 ){
                addNewNotifcation("Model not found. Please check your entry.", "danger");
            }
            else{
                addNewNotifcation("Something went wrong. Could not load the model.", "danger");
            }
            
        }
        finally{
            setLoading(false);
        }
    }


    return (
      <Dialog open={open} onOpenChange={setOpen}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                            <Button variant="outline" onClick={handleOnOpen}>
                                {/* <Save className="h-5 w-5" /> */}
                                Load Model
                            </Button>
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Load Your Model's prompt and configurations.</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
            <DialogTitle>Load Model</DialogTitle>
            <DialogDescription>
                Enter the unique name of your model
            </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                Name
                </Label>
                <Input
                    id="name"
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    className="col-span-3"
                    placeholder="Enter your model's name"
                />
            </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={OnLoadModel} disabled={loading}>{loading ? <LoaderSpinner/> : "Load"}</Button>
        </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }


  export default LoadModelDialog;