import { organizationService } from "api"
import { httpRequest } from "./httpsRequest"


export const getUserRole = async (user) => {
    try {
        if (!user.organization) return ''
        const organization = await organizationService.getOrganization(user.organization)
        const member = organization.data.members.find((member) => member.id === user.id)
        
        const role = user.id === organization.data.superAdmin ? 'superAdmin' : member?.role
        
        return role
    } catch (error) {
        console.log('getUserRole error: ', error);
        return ''
    }
}

export const handleLeaveOrganization = async (oragID, userId) => {

    const leaveOrgObj = {
        organizationId: oragID,
        userId: userId
    }
    try {
        const res = await httpRequest.post('/db/organizations/update_organization/leave-organization', leaveOrgObj)
        console.log('res: ', res);
        window.location = '/organizations'
    } catch(error) {
        console.log('error: ', error);
        throw new Error(error)
    }
}