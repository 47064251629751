import React, { useContext, useEffect, useState } from 'react'
import './email-extraction-styles.css'
import SetupCard from '../../../components/AppSetup/SetupCard'
import SetupModal from '../../../components/AppSetup/SetupModal'
import { EmailProcessingModal } from '../../EmailHandling/EmailDemo'
import { httpRequest, httpRequestO, httpRequestOSetup, serverOSetup } from '../../../utils/httpsRequest'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/GlobalState'
import { AppLoader, LoaderSpinner } from "../../../components/LoaderSpinner";
import {RedButton, WhiteButton} from '../../../components/Buttons'
import { EmailSetupModal } from './EmailSetupModal';
import { LoaderModal } from '../../../components/LoaderModal'
import { Card, CardContent, CardHeader, CardTitle } from '../../../components/ui/card'
import { Button } from '../../../components/ui/button'
import AlertModal from '../../../components/Alertmodal'
import { FilePenIcon } from 'lucide-react'
import { UpdateHotelModal } from '../../../pages/EmailHandling/Settings/Hotel'
import { SubOrgContext } from 'context/SubOrganizationContext'
import Breadcrumb from 'components/Breadcrumb'

export default function EmailExtractionDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your email channel where you will be receiving your emails',
        'A set of hotel policies and hotel information where the BeX AI Assistant can read and understand'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for handling hotel emails is designed for hotel reservations teams to handle incoming hotel guest enquiries, booking requests and modifications.',
        'This BeX AI Assistant is capable of connecting to your hotel reservations systems, email systems, and generating a personalized reply to your hotel guests.'
    ];

    const navigate = useNavigate()
    const { user, addNewNotifcation, selectedSubOrganization } = useContext(Context)
    const { oragID, subOragID, assistantId } = useParams()

    const { workflow } = useContext(SubOrgContext)


    const [loading, setLoading] = useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [emailSample, setEmailSample] = useState("");
    const [show, setShow] = useState(false);
    const [resData, setResData] = useState({});
    const [emailInbox, setEmailInbox] = useState(false);
    
    const checkEmailCallback = () => {
        const emailObj = {
            organization: user.organization,
            subOrganization: subOragID,
            userId: user._id,
        }
        httpRequestOSetup.post('callback/get-email-inbox', emailObj)
        .then((res) => {
            console.log('res: ', res);
            if (res.data?.inboxe?.processed) {
                const emailSampleRes = {
                    details: res.data?.inboxe.details,
                    response: res.data?.inboxe.response,
                }
                setResData(emailSampleRes);
                setEmailSample(res.data?.inboxe.emailContent || '')
                setShow(true);
                setEmailInbox(false)
            } else {
                setEmailInbox(true)
            }
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleOpCodeClick = async () => {

        if (!emailSample) {
            return addNewNotifcation('Please choose email sample', 'warning')
        }

        const encodedEmailTitle = encodeURIComponent(emailSample);

        try {
            setBtnLoading(true);
            const emailSampleObj = {
                "user_input": encodedEmailTitle,
                "request_id": `${user.organization}-${subOragID}-${user._id}`,
                "callback_api": `${serverOSetup}/callback/email-callback`
            }
            // console.log(emailSampleObj);
            const res = await httpRequestO.post(`/projecto/api/v1/cpie/mini`, emailSampleObj)
            console.log("res: ", res.data);
            
            const callbackObj = {
                organization: user.organization,
                subOrganization: subOragID,
                userId: user._id,
                emailContent: emailSample
            }
            const inboxResponse = await httpRequestOSetup.post('callback/add-email-inbox', callbackObj)
            console.log('inboxResponse: ', inboxResponse);

            setEmailInbox(true)
            setShow(false)
            addNewNotifcation('Email sent successfully. Please refresh to check for response', 'success')
        } catch(err) {
            console.log("error: ", err);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        } finally {
            setBtnLoading(false);
        }
    };


    const handleDemoClick = () => {
        if (user.organization) {
            navigate(`/organizations/${oragID}/sub/${subOragID}/bex-assistants/email-handling-demo`)
        } else {
            navigate(`/organizations`)
        }
    }

    const handleModalClose = () => {
        setOpenModal(false)
        setResData({})
        setShow(false)
    }

    const handleSetupClick = () => {
        if (user.organization) {
            setOpenEmailModal(!openEmailModal)
        } else {
            navigate(`/organizations`)
        }
    }

    const checkEmailResponse = () => {
        setLoading(true)
        checkEmailCallback()
    }

    const isActive  = workflow?.workflowAssistants?.find(item => item.assistant._id === assistantId)?.status === 'active' || false


    return (
        <div className='email-extraction-dashboard-container'>
            <div className='email-extraction-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4} />

                    <div className='dashboard-app-setup-card-container flex-col'>
                        {!isActive ? (
                            <SetupCard 
                            overviewTitle={'BeX AI Assistant for Hotel Email Handling'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant' 
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />
                        ) : (
                            <ActiveAssistant />
                        )}

                        <EmailSetupModal isOpen={openEmailModal} onClose={() => setOpenEmailModal(false)} setOpenEmailModal={setOpenEmailModal} />

                        <SetupModal onClose={handleModalClose} title={'Demo Mode for BeX-O001 - BeX AI Assistant for hotel email handing'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='email-extraction-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO: Choose a sample email
                                    </div>

                                    <div className='demo-insturctions__text'>
                                        <span>
                                            This BeX AI Assistant is capable of reading emails from the guests, classifying the emails according to different intents, and extracting the key information from the email. The BeX AI Assistant is also able to generate the reply accordingly.
                                        </span>
                                    </div>
                                </div>
                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                                <div className='email-extraction-demo-setup'>
                                    {loading ? <LoaderModal /> : (
                                        <EmailProcessingModal setEmailInbox={setEmailInbox} setShow={setShow} resData={resData} show={show} emailSample={emailSample} setEmailSample={setEmailSample} />
                                    )}
                                    <div className='email-extraction-demo-setup__button-container'>
                                        <WhiteButton onClick={handleModalClose}> Cancel </WhiteButton>
                                        <RedButton disabled={btnLoading} onClick={emailInbox ? checkEmailResponse : handleOpCodeClick}> {btnLoading ? <LoaderSpinner /> : emailInbox ? 'Refresh' : "Process"} </RedButton>
                                    </div>
                                </div>
                            </SetupModal.StyledSetup>
                        </SetupModal>
                    </div>
                </div>
            </div>
        </div>
    )
}


const ActiveAssistant = () => {

    const [btnLoading, setBtnLoading] = useState(false)
    const [showDelModal, setShowDelModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [hotel, setHotel] = useState({})
    const [error, setError] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)

    const { oragID, subOragID } = useParams()
    const { addNewNotifcation } = useContext(Context)

    const navigate = useNavigate()

    useEffect(() => {

        setError(null)
        const getHotelObj = {
            subOrganizationId: subOragID,
            organizationId: oragID
        }

        httpRequestOSetup.post(`hotels/sub-organization`, getHotelObj)
        .then((res) => {
            console.log('res: ', res);
            setHotel(res.data)
        }).catch((error) => {
            console.log('hotel error: ', error);
            setError(error.message)
        }).finally(() => {
            setLoading(false)
        })

    }, [])


    const handleDisableAssistant = async () => {

        setBtnLoading(true)
        try {

            const cancelObj = {
                organizationId: oragID,
                subOrganizationId: subOragID,
                subscriptionId: hotel.email_auth.subscription_id
            }
            console.log('cancelObj:', cancelObj);
            const hotelRes = await httpRequestOSetup.delete(`/outlook/cancel-subscription`, {data: cancelObj})
            console.log(hotelRes);

            const updateOrg = {
                assistant: '667538b01444181354ac98eb',
                status: 'inactive',
                organizationId: oragID,
                subOrganizationId: subOragID,
            }
            const updateRes = await httpRequest.put(`/db/workflows/update-assistant`, updateOrg)
            console.log('updateRes: ', updateRes.data);
            addNewNotifcation('Hotel was cancelled successfully.','success')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } catch (error) {
            console.log('error: ', error);
            addNewNotifcation('Something went wrong','danger')
        } finally {
            setBtnLoading(false)
        }

    }

    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }
    if (error) {
        return error
    }

    return (
        <div>
            <div className='flex w-full gap-3'>

                <Card className='flex-grow'>
                    <CardHeader className='flex flex-row items-center justify-between'>
                        <CardTitle>Hotel Information</CardTitle>
                        <Button onClick={() => setShowEditModal(true)} variant="ghost" size="icon" className="!space-y-0 !mt-0">
                            <FilePenIcon className="w-5 h-5 text-muted-foreground" />
                            <span className="sr-only">Edit hotel info</span>
                        </Button>
                    </CardHeader>
                    <CardContent className="grid gap-4">
                        <div className="flex items-center gap-2">
                            <BuildingIcon className="w-6 h-6 text-muted-foreground" />
                            <div>
                                <h4 className="font-medium">{hotel.hotel_name}</h4>
                                <p className="text-muted-foreground">{hotel.hotel_address}</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <MailOpenIcon className="w-6 h-6 text-muted-foreground" />
                            <div>
                                <p>{hotel.hotel_email}</p>
                            </div>
                        </div>
                        <div className="flex items-center gap-2">
                            <PhoneIcon className="w-6 h-6 text-muted-foreground" />
                            <div>
                                <p>{hotel.hotel_phone}</p>
                            </div>
                        </div>
                    </CardContent>

                    {/* update hotel modal */}
                    <UpdateHotelModal isOpen={showEditModal} setIsOpen={setShowEditModal} setHotel={setHotel} hotel={hotel} />
                </Card>


                <Card className='w-[350px]'>
                    <CardHeader>
                        <CardTitle>AI Assistant Status</CardTitle>
                    </CardHeader>
                    <CardContent className="flex flex-col items-center justify-center gap-4">
                        <div className="space-y-2 flex flex-col items-center">
                            <BotIcon className="w-[100px] h-[100px] text-primary" />
                            <p className="text-lg font-semibold">AI Assistant is Active</p>
                            <p className="text-muted-foreground">The AI assistant is ready to assist you.</p>
                        </div>
                    </CardContent>
                </Card>
            </div>

     
            <Card className='mt-3'>
                <CardHeader className='flex flex-row items-center justify-between'>
                    <CardTitle>Email Subscription</CardTitle>
                </CardHeader>
                <CardContent className="flex justify-between">
                    <div className="flex items-center gap-2">
                        <MailOpenIcon className="w-6 h-6 text-muted-foreground" />
                        <div>
                            <p>{hotel.email_auth?.email_auth}</p>
                            <p className="text-muted-foreground">{hotel.email_auth?.created.split('T')[0]}</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-3'>
                        <Button onClick={() => navigate('../email-handling-demo')} variant='outline'>
                            Try Assistant
                        </Button>
                        <Button onClick={() => setShowDelModal(true)} variant='destructive'>
                            Unsubscribe
                        </Button>
                    </div>
                </CardContent>
            </Card>

            {/* alert modal */}
            <AlertModal openModal={showDelModal} setopenModal={setShowDelModal} onDelete={handleDisableAssistant} loading={btnLoading} title='Are you sure you want to cancel subscription' btnTitle='Confirm' />
        </div>

    )
}

function BotIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
            <path d="M12 8V4H8" />
            <rect width="16" height="12" x="4" y="8" rx="2" />
            <path d="M2 14h2" />
            <path d="M20 14h2" />
            <path d="M15 13v2" />
            <path d="M9 13v2" />
        </svg>
    )
}

function BuildingIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
            <rect width="16" height="20" x="4" y="2" rx="2" ry="2" />
            <path d="M9 22v-4h6v4" />
            <path d="M8 6h.01" />
            <path d="M16 6h.01" />
            <path d="M12 6h.01" />
            <path d="M12 10h.01" />
            <path d="M12 14h.01" />
            <path d="M16 10h.01" />
            <path d="M16 14h.01" />
            <path d="M8 10h.01" />
            <path d="M8 14h.01" />
        </svg>
    )
}
  
function MailOpenIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
            <path d="M21.2 8.4c.5.38.8.97.8 1.6v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10a2 2 0 0 1 .8-1.6l8-6a2 2 0 0 1 2.4 0l8 6Z" />
            <path d="m22 10-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 10" />
        </svg>
    )
}
  
function PhoneIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
        </svg>
    )
}