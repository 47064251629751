import { createContext, useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import { httpRequest } from '../utils/httpsRequest'
import { getUserRole } from '../utils/organizationUtils'
import { authService } from 'api'
export const Context = createContext({})


const IDLE_USER_TIMEOUT = 14400000 // 4 Hours
const IDLE_USER_THROTTLE = 500

export default function GlobalState() {
    const [isAuth, setIsAuth] = useState(false)
    const [isSubOrganization, setIsSubOrganization] = useState(true)
    const [isOrgDeployed, setIsOrgDeployed] = useState(false)
    const [isAdminAuth, setIsAdminAuth] = useState(false)
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(true)
    const [notificationStatus, setNotificationStatus] = useState([])
    const [selectedSubOrganization, setSelectedSubOrganization] = useState({})
    const [userAdmin, setUserAdmin] = useState({})

    // Hooks
    useIdleTimer({
        onActive: () => fetchUserDetails(),
        timeout: IDLE_USER_TIMEOUT,
        throttle: IDLE_USER_THROTTLE
    })

    useEffect(() => {
        const timeOut = setInterval(() => {
            if (notificationStatus.length > 0) {
                setNotificationStatus((prev) => prev.filter(i => i.timeremaining > 0).map(item => {
                    return {
                        ...item, timeremaining: item.timeremaining - 0.2
                    }
                }))
            }
        }, 200)

        if (notificationStatus.length <= 0) {
            clearInterval(timeOut)
        }

        return () => clearInterval(timeOut)
    }, [notificationStatus])

    useEffect(() => {
        if (window.location.pathname.includes('secure')) {
            httpRequest.get('/admin/is-admin-auth')
                .then(async (res) => {
                    if (res.data.auth) {
                        setUserAdmin(res.data.admin)
                    }
                    setIsAdminAuth(res.data.auth)
                    console.log(res)
                }).catch((err) => {
                    setUser({})
                    setIsAdminAuth(false)
                    console.log(err)
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            fetchUserDetails()
        }

    }, [])

    // Actions
    const addNewNotifcation = (message, type, timeremaining = 4) => {
        setNotificationStatus(prev => [...prev, { message, type, timeremaining }])
    }

    const actions = (action) => {
        const { type, payload } = action

        switch (type) {
            case "SET_LOADING":
                return setLoading(payload)
            case "SET_USER":
                return setUser(payload)
            case "SET_IS_AUTH":
                return setIsAuth(payload)
            case "SET_NOTIFCATION_STATUS":
                return setNotificationStatus(payload)
            case "SET_IS_ADMIN_AUTH":
                return setIsAdminAuth(payload)
            case "SET_USER_ADMIN":
                return setUserAdmin(payload)
            case "SET_IS_SUB_ORGANIZATION":
                return setIsSubOrganization(payload)
            default:
                return loading
        }
    }

    const fetchUserDetails = async () => {
        try {
            const res = await authService.getUser()
            if (res.data.auth) {
                res.data.user['role'] = await getUserRole(res.data.user)
                setUser(res.data.user)
                setIsOrgDeployed(res.data?.user.organizationObj?.isClusterDeployed)

                if (res.data?.user?.organization && res.data?.user?.subOrganizations?.length > 0) {
                    const storedSubOrg = localStorage.getItem('selectedSubOrg')
                    console.log('storedSubOrg: ', storedSubOrg)
                    const isMember = res.data?.user?.subOrganizations.find(i => i._id === storedSubOrg)
                    if (storedSubOrg && isMember) {
                        setSelectedSubOrganization(isMember)
                        localStorage.setItem('selectedSubOrg', isMember._id)
                    } else {
                        // localStorage.removeItem('selectedSubOrg')
                        setSelectedSubOrganization(res.data?.user?.subOrganizations[0])
                        localStorage.setItem('selectedSubOrg', res.data?.user?.subOrganizations[0]._id)
                    }
                } else {
                    setIsSubOrganization(false)
                }
            } else {
                setUser({})
                setIsAuth(false)
            }
            setIsAuth(res.data.auth)
            console.log(res)
        } catch (err) {
            setUser({})
            setIsAuth(false)
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

   return {
      actions,
      loading,
      user,
      isAuth,
      notificationStatus,
      isAdminAuth,
      userAdmin,
      isSubOrganization,
      addNewNotifcation,
      isOrgDeployed,
      selectedSubOrganization,
      setSelectedSubOrganization
   }
}