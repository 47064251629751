import { httpRequestO, httpRequestX } from "../../../../utils/httpsRequest";


// var prompt = 
// "You are a helpful assistant. You answer by generating code in the specified programming language. Generate codes with correct indentations.\n" +
// `Respond in this format:
// \`\`\`language
// code
// \`\`\``;
// const sys = "You are a helpful assistant. You answer by generating code with the specified programming language. Generate codes with correct indentations. Generate code blocks strictly in this format:\n```language\n// Your code here\n```";
const sys = "You are a helpful assistant. You generate code in \"```Code```\" format.";
const parameters = {
    mode: "sampling",
    method: "sampling",
    temperature: 0.5,
    top_p: 0.7,
    top_K: 50,
    max_tokens: 0,
    max_new_tokens: 1200,
    stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
    repetition_penalty: 1.2,
    stream: true,
}

export const Prompt = async (query) => {
    // console.log(prompt);

    var prompt =
`<s>
<<SYS>>
${sys}
<</SYS>>
[INST]
${query}
[/INST]`;
console.log(prompt)
    try{
        const result = await httpRequestO.post('/projecto/api/v1/model/foundationModel/experiment?mode=freeMode', {
            modelId: "codellama/codellama-34b-instruct-hf",
            parameters: parameters,
            prompt: prompt,
            query: ""
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log(result)
        // if (result.ok)
        return result.data.prediction
    }
    catch (error)
    {
        console.log(error);
    }
}