import React, { useContext, useState, useEffect } from 'react';
import './prompting-and-rag-training-styles.css';
import SetupCard from 'components/AppSetup/SetupCard';
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from 'context/GlobalState';
import Breadcrumb from 'components/Breadcrumb'


export default function PromptingAndRagTrainingDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Nothing!'
    ];

    const SETUP_DEMO_DESCRIPTION = [
        'The BeX AI Lab, your playground for mastering Generative AI (GenAI). BeX AI Lab offers a dedicated, immersive environment where you can dive deep into the world of GenAI. Here, you can test prompts, experiment with a variety of models, and fine-tune parameters to unlock the full potential of AI.',
        'Whether you\'re exploring prompt anatomy, comparing different AI models, or discovering practical applications, the BeX AI Lab is your gateway to becoming a GenAI expert.'
    ];

    const navigate = useNavigate();
    const { user, addNewNotifcation } = useContext(Context);

    const param = useParams()


    const handleDemoClick = () => {
        // setOpenModal(!openModal);
        navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/ai-lab`)
    };
    const handleSetupClick = () => {
        addNewNotifcation('No setup is needed for this assistant. Click Try Assistant.', 'success');
        // navigate('/ai-lab');
    };



    return (
        <div className='training-dashboard-container'>
            <div className='training-dashboard-content'>
                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4} />
                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard
                            overviewTitle={'BeX AI Lab'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to Start experimenting with GenAI'
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />
                        
                        
                    </div>
                </div>
            </div>
        </div>
    );
}
