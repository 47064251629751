import React, { useState, useEffect } from 'react';
import  useClickOutside  from '../hooks/useClickOutside';
import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';

const DropdownContainer = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-end;
    position: relative;
    background-color: #f1f1f1;
    border: none;
    color: black;
    padding: 0px 10px;
    height: 35px;
    width: 180px;
    cursor: pointer;
    transition: 0.3s;
    border: 0.4px solid #c6c6c6;
    border-radius: 5px;

    &:hover {
        background-color: #ddd;
    }

    &.open {
        background-color: var(--primary-color);
        color: white;
    }
`;

const DropdownIcon = styled.div`
    display: flex;
    width: 18px;
    align-items: center;
    margin-right: 5px;
`

const DropdownSpan = styled.span`
    font-size: 14px;
    font-weight: 500;
    margin-right: 8px;
    display: inline-block;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 2;
    background-color: #f1f1f1;
    min-width: 180px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    cursor: pointer;
`;

const DropdownMenuItemsContainer = styled.div`
    max-height: 300px;
    overflow: scroll;
    overflow-x: auto;
    overflow-y: auto;
`;

const DropdownMenuItem = styled.div`
    color: black;
    padding: 12px 20px;
    text-decoration: none;
    display: block;

    &:hover {
        background-color: #ddd;
    }

    &.selected {
        color: var(--primary-color);
    }

    &.selected::before {
        background-color: var(--primary-color);
        height: 22px;
    }

    &.selected::before {
        content: "";
        position: absolute;
        width: 4px;
        left: 0px;
        border-radius: 0px 4px 4px 0px;
        background-color: var(--primary-color);
        transition: background-color 150ms ease-in-out 0s;
    }

`;

const NoMatchFound = styled.div`;
    cursor: default;
    color: #000;
    &:hover {
        background-color: #f1f1f1 !important;
    }
`;


const SearchInput = styled.input`
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: none;
    
    &:focus {
        outline: none;
    }
`;

const DropdownLoadingSpinner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    &::after {
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #ccc;
        border-top-color: #888;
        animation: spin 1s infinite linear;
    }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
`;


const Dropdown = ({ selected, onSelecte, list=[], search = true, button=false}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(false);

    const [searchValue, setSearchValue] = useState('');
    const [filteredItems, setFilteredItems] = useState(list && list.length > 0 ? list.map(item => item.name) : null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFilteredItems(list.map(item => item.name).filter(item => item.toLowerCase().includes(searchValue.toLowerCase())));
    }, [list, searchValue]);

    const handleSelect = (item) => {
        const selectedObj = list.find(obj => obj.name === item);
        onSelecte(selectedObj);
        setIsComponentVisible(false);
    }

    const toggleDropdown = () => {
        setIsComponentVisible(!isComponentVisible);
    }

    const handleSearchInputChange = (e) => {
        setSearchValue(e.target.value);
        setIsComponentVisible(true);
    }

    return (
        <DropdownContainer className={`dropdown ${isComponentVisible ? 'open' : ''}`} onClick={toggleDropdown}>
            <DropdownIcon><IoIosArrowDown /></DropdownIcon>
            <DropdownSpan>{selected?.name}</DropdownSpan>
            {isComponentVisible && ( 
                <DropdownMenu ref={ref} style={{ position: 'absolute' }}>
                {search && ( 
                    list.length === 0 ? (
                        <DropdownLoadingSpinner></DropdownLoadingSpinner>
                    ) : (
                        <>
                            <SearchInput type="text" value={searchValue} onChange={handleSearchInputChange} onClick={(e) => e.stopPropagation()} placeholder="Search..." />
                            <DropdownMenuItemsContainer>
                            {filteredItems.length > 0 ? (
                                filteredItems.map((item) => (
                                    <DropdownMenuItem
                                    key={item.id}
                                    onClick={() => handleSelect(item)}
                                    className={item === selected ? 'selected' : ''}
                                    >
                                        {item}
                                    </DropdownMenuItem>
                                ))
                            ) :  (
                                <NoMatchFound className="no-match-found">No match found</NoMatchFound>
                            )}
                            </DropdownMenuItemsContainer>
                        </>
                    )
                )}
                {button && (
                    <button className={button.styling} onClick={button.onClick}>
                        {button.text}
                    </button>
                )}
                </DropdownMenu>
            )}
        </DropdownContainer>
    )
}

export default Dropdown;