import React, { useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-grow: 1;
    padding: 0px !important;
    height: 100%;
`
export const ProtectedRoute = ({ admin=false }) => {

    const { isAuth, user } = useContext(Context)
    const locations = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem('redirect-url', locations.pathname)
            navigate('/auth/login')
        } else if (admin && user.role === 'editor') {
            navigate('/bex-assistant')
        }
    }, [])

    return (
        <StyledContainer>
            <Outlet />
        </StyledContainer>
    )
  };