import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { Context } from "../context/GlobalState";

export const ProtectedOrgRoute = () => {

    const { user, isAuth, addNewNotifcation, isOrgDeployed } = useContext(Context)
    const locations = useLocation()
    const navigate = useNavigate()
    const { oragID } = useParams()
    const [isChecking, setIsChecking] = useState(true);
    
    useEffect(() => {
        if (!isAuth) {
            sessionStorage.setItem('redirect-url', locations.pathname)
            window.location = '/auth/login'
        } else {
            if (user.organization) {
                if (!isOrgDeployed) {
                    navigate('/organizations')
                    addNewNotifcation('Organization not deployed yet', 'warning')
                } else if (user.organization !== oragID) {
                    navigate('/organizations')
                    addNewNotifcation('You are not a member of this organization', 'warning')
                }
            } else {
                navigate('/organizations')
                addNewNotifcation('You don not have any organization', 'warning')
            }
        }
        setIsChecking(false)
    }, [isAuth])

    if (isChecking) {
        return null;
    }

    return (
        <Outlet />
    )
};

