import React, { useContext, useEffect } from 'react'
import { Avatar, AvatarFallback } from "../components/ui/avatar"
import { Context } from '../context/GlobalState'
import { Link } from 'react-router-dom'
import AvatarDropdownMenu from '../components/AvatarDropdownMenu'
import { BiLogOut } from 'react-icons/bi'
import { httpRequest } from '../utils/httpsRequest'
import useComponentVisible from '../hooks/useClickOutside'
import { IoClose } from "react-icons/io5";
import { FaFileContract, FaHotel } from 'react-icons/fa';
import { FaEnvelopeOpenText } from "react-icons/fa6";
import { Building, LayoutDashboard, Settings, UsersRound } from 'lucide-react'

// Refactor: organize methods and variables orders
export default function Profile() {
    // Constants
    const PROFILE_MENU_ITEMS = [
        { name: "My Account", link: "/profile" , action: () => setIsComponentVisible(false) },
        { name: "Logout", link: undefined, action: () => handleLogOut() }
    ];

    const { user } = useContext(Context)
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    
    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    const handleLogOut = () => { // Refactor: this function to use the GlobalState context
        httpRequest.post('/auth/logout')
          .then((res) => {
              console.log(res);
            //   localStorage.removeItem('selectedSubOrg');
            console.log('selectedSubOrg: ', localStorage.getItem('selectedSubOrg'));
            // navigate('/auth/login');
            window.location = '/auth/login';
          }).catch((error) => {
              console.log(error);
          })
    }
    
    
    
    useEffect(() => {
        const originalOverflow = document.body.style.overflow;
        if (isComponentVisible) {
            document.body.style.overflow = "hidden"; 
            // document.body.style.paddingRight = `17px`; 
        } else {
            document.body.style.overflow = originalOverflow; 
            // document.body.style.paddingRight = ''; 
        }
        return () => {
            document.body.style.overflow = originalOverflow;
            // document.body.style.paddingRight = '';
        }
    }, [isComponentVisible]);
    
    
    const contractProcess = user?.organizationObj?.assistants?.contractProcess?.isActive;
    const emailProcess = user?.organizationObj?.assistants?.emailProcess?.isActive;
    const legalDocument = user?.organizationObj?.assistants?.legalDocuments?.isActive;

    const assistants = [ // Refactor: this array to use the GlobalState context
        { name: 'Email Process', active: emailProcess, icon: <FaHotel />, linkPath: `/organizations/organization/${user?.organization}/bex-platform` },
        { name: 'Contract Process', active: contractProcess, icon: <FaFileContract /> , linkPath: `/organizations/organization/${user?.organization}/contract-management/projects`},
        { name: 'Legal Documents', active: legalDocument, icon: <FaEnvelopeOpenText />, linkPath: `/organizations/organization/${user?.organization}/legal-document/projects` }
    ];

    const anyAssistantActive = assistants.some(assistant => assistant.active);
    const userFirsCharacter = user.name ? user.name[0].toUpperCase() : 'A';

    return (
        <div className={`profile-container ${isComponentVisible ? 'modal-open' : ''}`}>
            <AvatarDropdownMenu 
                user={user} 
                menuItems={PROFILE_MENU_ITEMS} />

            {/* {isComponentVisible && ( */}
                <div ref={ref} className={`profile-modal-container  ${isComponentVisible ? 'visible-profile-modal' : ''}`}>
                    <div className='user-card-info-container'>
                        <Avatar className="h-9 w-9">
                            <AvatarFallback>
                                {userFirsCharacter}
                            </AvatarFallback>
                        </Avatar>
                        
                        <div className='user-info'>
                            <div className='user-name-container'>
                                {capitalizeFirstLetter(user.name)}
                            </div>
                            <span className='user-email-container'>
                                {user.email}
                            </span>
                        </div>
                        <div onClick={() => setIsComponentVisible(false)} className='close-profile-icon'>
                            <IoClose size={18} className='close-icon'/>
                        </div>
                    </div>
                    {/* 
                        Refactor: Props and components are very long, also share the same logic, dynamic rendering?
                    */}
                    <div className='options-container'>
                        <ProfileOptions setIsComponentVisible={setIsComponentVisible} option='Dashboard' 
                        icon={<LayoutDashboard size={18} />} linkPath='' />
                       {user.organization && <ProfileOptions setIsComponentVisible={setIsComponentVisible} option='Manage Organization' icon={<Building size={18} />} linkPath={`/settings/organizations/organization/${user.organization}`} />}
                        {user.organization && <ProfileOptions setIsComponentVisible={setIsComponentVisible} option='Manage users' icon={<UsersRound size={18} />} linkPath={`/settings/organizations/organization/${user.organization}/users`} />}
                        <ProfileOptions setIsComponentVisible={setIsComponentVisible} option='Manage Profile' 
                        icon={<Settings size={18} />} linkPath={`/settings/profile`} />
                    </div>
                    {anyAssistantActive && (
                        <div className='bex-assistants-nav-container'>
                            <span>Assistants</span>
                            {assistants.map(assistant => (
                                assistant.active &&
                                <ProfileOptions key={assistant.name} setIsComponentVisible={setIsComponentVisible} option={assistant.name} 
                                icon={assistant.icon} linkPath={assistant.linkPath} />
                            ))}
                        </div>
                    )}
                    <div className='logout-container'>
                        <button onClick={handleLogOut}>
                            <BiLogOut size={18} />
                            Logout
                        </button>
                    </div>
                    <div className='terms-container'>
                        <Link to=''>Privacy Policy</Link>
                        <Link> . </Link>
                        <Link to=''>Terms & Conditions</Link>
                    </div>
                </div>
            {/* )} */}
        </div>
    )
}

// Refactor: remove this component to a separate file 
const ProfileOptions = ({ option, icon, linkPath, setIsComponentVisible }) => {

    return (
        <div className='profile-name-container'>
            {icon}
            <Link onClick={(() => setIsComponentVisible(false))} to={linkPath}>{option}</Link>
        </div>
    )
}
