import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Card, CardHeader, CardTitle, CardContent, CardFooter, CardDescription } from "../../../../components/ui/card";
import { Button } from "../../../../components/ui/button";
import {Combobox} from '../../../../components/ui/combobox';
import { Play, SendHorizontal, Code, RotateCcw, BetweenHorizonalStart, Info } from 'lucide-react';
import Editor from '@monaco-editor/react';
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "../../../../components/ui/resizable"
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { v4 as uuidv4 } from 'uuid';
import {Prompt} from "../../context/functions/sandboxOps";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../../components/ui/tooltip";
import { SampleList } from './SampleList';
import { Textarea } from "components/ui/textarea";
import { SandboxContext } from 'pages/PromptingAndRagTraining/context/SandboxContext';

const CodeSandbox = () => {
    
    return (
        <div className="grid grid-cols-3 gap-4 h-full">
            <div className="col-span-1">
                <CodePrompt/>
            </div>
            <div className="col-span-2">
                <CodeEditor/>
            </div>
            
            
        </div>
    );
};

export default CodeSandbox;


const CodeEditor = () => {
    const {
        editorCode, setEditorCode,
        language, setLanguage,
        output, setOutput,
        isExecuting, setIsExecuting,
    } = useContext(SandboxContext);
    // const [code, setCode] = useState('# Write your code here');
    
    

    const languages = [
        {_id: 1, name: 'python'},
        {_id: 2, name: 'javascript'},
    ]

    function isObject(value) {
        return value !== null && typeof value === 'object' && !Array.isArray(value);
    }

    const handleRunCode = async () => {
        try {
            setIsExecuting(true)
            setOutput('')
            console.log({code: editorCode, language: language});
            const res = await axios.post('https://stgapp.coolriots.ai/code-exe/execute', {code: editorCode, language: language}, {
                headers: {
                'Content-Type': 'application/json',
                }
            });
            console.log('data: ', res);
            if (!isObject(res.data.result)) {
                if (res.data.error)
                    setOutput(res.data.error);
                else
                    setOutput(res.data.result);
            }
        } catch (error) {
            console.log('error: ', error);
            setOutput('Error executing code. ', error);
        } finally {
            setIsExecuting(false)
        }
    }

    const onSelect = (value) => {
        setLanguage(languages.find(item => value._id === item._id).name)
    }

    useEffect(() => {
        let newCode = "";
        if (language === "python") newCode = editorCode.replace("//", "#");
        else if (language === "javascript") newCode = editorCode.replace("#", "//");
        // console.log("language changed to: ", language);

        setEditorCode(newCode);
    }, [language])

    

    return (
        <Card className="w-full flex flex-col justify-between h-[620px]">
            <CardHeader className="h-16 border-b-[1px] border-gray-200 px-6 py-4 flex flex-row items-center justify-between">
                <Combobox
                    items={languages}
                    selectedValue={languages.find(item => item.name === language)}
                    setter={onSelect}
                />
                <div className="flex flex-row flex-nowrap gap-2">
                    {/* <Button variant="ghost" size="icon" className="flex items-center">
                        <FileUp className="w-5 h-5" />
                    </Button>
                    <Button variant="ghost" size="icon" className="flex items-center">
                        <Download className="w-5 h-5" />
                    </Button> */}
                    <Button variant="ghost" size="icon" className="" disabled={isExecuting} onClick={handleRunCode} >
                        <Play className="w-5 h-5 text-green-400" />
                    </Button>
                </div>
            </CardHeader>

            <CardContent className="flex-1 grid grid-cols-1 p-0">
                <ResizablePanelGroup direction="horizontal">
                    <ResizablePanel className='max-h-[530px]'>
                        <Editor
                            height="100%"
                            language={language}
                            value={editorCode}
                            theme="vs-dark"
                            onChange={(newValue) => setEditorCode(newValue)}
                            options={{
                              }}
                        />
                    </ResizablePanel>
                    <ResizableHandle withHandle />
                    <ResizablePanel className='max-h-[530px] overflow-auto bg-gray-100'>
                        <pre className="h-full w-full px-4">
                            <code className='text-sm h-full w-full'>{output}</code>
                        </pre>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </CardContent>

            <CardFooter className="">
                
            </CardFooter>
        </Card>
    );
};


const CodePrompt = () => {

    const {
        setEditorCode,
        setLanguage,
        inputMessage, setInputMessage,
        sandBoxConvo, setSandboxConvo,
        loading, setLoading
    } = useContext(SandboxContext);

    
    
    const endOfMessagesRef = useRef(null);
    const INFO =
        "This Code Generation Bot uses the model 'codellama/codellama-34b-instruct-hf' which was fine-tuned for chat using specific structure for prompts:\n\n" + 
        "<s> - the beginning of the entire sequence.\n<<SYS>> - the beginning of the system message.\n<</SYS>> - the end of the system message.\n[INST] - the beginning of some instructions\n" +
        "[/INST] - the end of the instructions\n\nModel Parameters\nTokens: 1200\nDecoding: sampling\nTemperature: 0.5\nTop-P: 0.7\nTop-K: 50\nStop Sequence: [\"<|endofcode|>\"]";
    // const containerRef = useRef(null);
    const TIPS =
        "TIPS: The best way to prompt with a code is by encapsulating your code using this format:\n\n```language\n// Code here\n```\n\n" +
        "Triple backticks are important to ensure that the model can understand your code.";
    


    const OnSendPrompt = async () => {
        if (inputMessage.trim() === "") return;

        setLoading(true);
        const newMessage = { role: 'user', message: inputMessage, _id: uuidv4()};
        setSandboxConvo(prevMessages => [...prevMessages, newMessage]);
        setInputMessage('');

        const response = await Prompt(inputMessage);
        console.log(response)
        const botMessage = response ? { role: 'assistant', message: response, _id: uuidv4() } : { role: 'system', message: "Error generating code.", _id: uuidv4() };
        setSandboxConvo(prevMessages => [...prevMessages, botMessage]);
        
        setLoading(false);
    }

    // const OnKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         OnSendPrompt();
    //     }
    // };

    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [sandBoxConvo]);




    const parseMessage = (message) => {
        // Regular expression to match code blocks with optional language identifier and `[LANGUAGE] ... [/LANGUAGE]` format
        const regex = /```(\w*)\n([\s\S]*?)```|(\[\w+\][\s\S]*?\[\/\w+\])/g;
        const parts = [];
        let lastIndex = 0;
        let match;
    
        // Find all matches and split the message accordingly
        while ((match = regex.exec(message)) !== null) {
            const [fullMatch, language, code, altBlock] = match;
            const startIndex = match.index;
            const endIndex = regex.lastIndex;
    
            // Add text before the current code block
            if (startIndex > lastIndex) {
                parts.push(message.slice(lastIndex, startIndex));
            }
    
            if (language) {
                console.log("asdasd")
                // Handle ```language ... ``` format
                parts.push(
                    <CodeSnippet setEditorCode={setEditorCode} key={parts.length} code={code} language={language} index={parts.length} />
                );
            } else if (altBlock) {
                // Handle [LANGUAGE] ... [/LANGUAGE] format
                const languageMatch = altBlock.match(/\[(\w+)\]/);
                const language = languageMatch ? languageMatch[1] : '';
                const code = altBlock.replace(/\[\w+\]/, '').replace(/\[\/\w+\]/, '').trim(); // Extract code content
                parts.push(
                    <CodeSnippet setEditorCode={setEditorCode} key={parts.length} code={code} language={language.toLowerCase()} index={parts.length} />
                );
            }
            else{
                parts.push(
                    <CodeSnippet setEditorCode={setEditorCode} key={parts.length} code={code} language={""} index={parts.length} />
                );
            }
    
            lastIndex = endIndex;
        }
    
        // Add any remaining text after the last code block
        if (lastIndex < message.length) {
            parts.push(message.slice(lastIndex));
        }
    
        return parts.map((part, index) => (
            <React.Fragment key={index}>{part}</React.Fragment>
        ));
    };

    const handleResetPrompt = () => {
        setSandboxConvo([]);
    }


    return (
        <Card className="w-full flex flex-col justify-between min-h-[600px] h-fit">
            <CardHeader className="h-16 border-b-[1px] border-gray-200 px-6 py-4 flex flex-row items-center justify-between">
                <CardTitle>CodeGen AI</CardTitle>
                <div className="flex gap-2 items-center">
                    {/* <Combobox
                        items={SAMPLES}
                        defaultDisplayed="Select Sample"
                        selectedValue={selectedSample}
                        setter={setSelectedSample}
                    /> */}
                    <SampleList inputMessage={inputMessage} setInputMessage={setInputMessage} setLanguage={setLanguage}/>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Info className="h-5 w-5" />
                            </TooltipTrigger>
                            <TooltipContent className="max-w-[300px] whitespace-pre-line" align="center" side="bottom">
                                <p>{INFO}</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>
                    <Button variant="ghost" size="icon" className="flex items-center" onClick={handleResetPrompt}>
                        <RotateCcw className="w-5 h-5" />
                    </Button>
                </div>
                
            </CardHeader>

            <CardContent className="border-t flex flex-row gap-2 items-center py-2 px-0 h-full w-full">
                <ScrollArea className="max-h-[460px] min-h-[460px] h-full w-full">
                    <div className="py-4 px-3">
                        {sandBoxConvo.map((convo) => (
                            <div
                                key={convo._id}
                                className={`text-sm mb-4 flex ${convo.role === "user" ? "justify-end" : "flex-row gap-2"}`}>


                                <div className={`rounded-[16px] px-3 py-2 max-w-[90%] ${convo.role === "user" ? "bg-gray-200" : "w-full"}`}>
                                    <pre className='break-words whitespace-pre-line'>
                                        {parseMessage(convo.message)}
                                    </pre>
                                </div>
                            </div>

                        ))}
                        {loading &&(
                            <div className="rag-typing mb-4">
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                                <div className="dot bg-black"></div>
                            </div>
                        )}
                    </div>
                    <div ref={endOfMessagesRef} />
                </ScrollArea>
            </CardContent>

            <CardFooter className="border-t flex flex-row gap-2 items-center py-2 px-3 h-fit">
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Info className="h-5 w-5" />
                        </TooltipTrigger>
                        <TooltipContent className="max-w-[300px] whitespace-pre-line" align="center" side="bottom">
                            <p>{TIPS}</p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
                <Textarea
                        className="shadow-none outline-none focus:outline-none focus-visible:outline-none focus-visible:ring-0"
                        placeholder="Type something..."
                        value={inputMessage}
                        onChange={(e)=>{setInputMessage(e.target.value)}}
                        // onKeyDown={OnKeyDown}
                    />
                <Button variant="ghost" size="icon" className="" onClick={OnSendPrompt}>
                    <SendHorizontal className="w-5 h-5" />
                </Button>
            </CardFooter>
        </Card>
    )
}

const CodeSnippet = ({index, code, language, setEditorCode}) => {
    const editorRef = useRef(null);
    const [contentHeight, setContentHeight] = useState("200px");

    // Function to handle editor mounting
    const handleEditorDidMount = (editor, monaco) => {
        // Store the editor instance in the ref
        editorRef.current = editor;
        const contentHeight = editorRef.current.getModel().getLineCount() * 19;
        // console.log(contentHeight + "px");
        setContentHeight(contentHeight + "px")
        editorRef.current.layout();
    };

    useEffect(()=>{
        if (editorRef.current){
            // console.log(editorRef.current)

            const contentHeight = editorRef.current.getModel().getLineCount() * 19;
            // console.log(contentHeight + "px");
            setContentHeight(contentHeight + "px")
            editorRef.current.layout();

        }
    }, [code, contentHeight])

    const OnTransferCode = () => {
        setEditorCode(code);
    }
    const OnAppendCode = () => {
        setEditorCode((prevCode) => prevCode + `\n${code}`);
    }

    return (
        <div className="p-0 m-0 w-full my-2">
            <CardHeader className="py-0 px-3 flex flex-row justify-between items-center rounded-t-[8px] border bg-white">
                <CardDescription>
                    {language}
                </CardDescription>
                <div className="flex gap-2 items-center">
                    <Button variant="ghost" size="icon" className="flex items-center" onClick={OnAppendCode}>
                        <BetweenHorizonalStart className="w-5 h-5" />
                    </Button>
                    <Button variant="ghost" size="icon" className="flex items-center" onClick={OnTransferCode}>
                        <Code className="w-5 h-5" />
                    </Button>
                </div>
                
            </CardHeader>
            {/* <CardContent className="p-0"> */}
            <pre className="whitespace-pre-wrap bg-gray-200 my-0 view-lines w-full">
                <Editor
                    width="100%"
                    height={contentHeight}
                    language={language}
                    value={code}
                    theme="vs-dark"
                    options={{
                        readOnly: true,
                        lineNumbers: "off",
                        minimap: {enabled: false},
                        scrollBeyondLastLine: false,
                        overviewRulerLanes: 0
                    }}
                    onMount={handleEditorDidMount}
                />
            </pre>
            {/* </CardContent> */}
        </div>
    )
}