/* eslint-disable no-undef */
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../../context/GlobalState';
import { httpRequest } from '../../../../utils/httpsRequest';
import { AppLoader, LoaderSpinner } from '../../../../components/LoaderSpinner';
import { PopUpModal } from '../../../Manager/EntityIntent/components/PopUpModal';
import NewStyledTable from '../../../../components/ui/StyledTable';
import StyledTable from '../../../../components/StyledTable';
import { MdDelete, MdEdit } from 'react-icons/md'
import AlertModal from '../../../../components/Alertmodal'
import Dropdown from '../../../../components/Dropdown';
import './sub-organization-styles.css'
import { Link, useParams } from 'react-router-dom';
import CreateSubOrganizationDialog from '../../../../components/AddSubOrgForm';
import UpdateSubOrganizationDialog from '../../../../components/UpdateSubOrganizationDialog';

function SubOrganizationSetting() {
    const [tableData, setTableData] = useState([]);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedSubOrgMembers, setSelectedSubOrgMembers] = useState([{}]);
    const [notInThisSubOrg, setNotInThisSubOrg] = useState([]);
    const [addedMember, setAddedMember] = useState([]);
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [subOrgs, setSubOrgs] = useState([]);  
    
    const { addNewNotifcation, user} = useContext(Context)
    const { oragID } = useParams()

    useEffect(() => {
        const subOrganizationsTable = user?.subOrganizations.map((item) => ({ id: item._id, ...item }));
        console.log('subOrganizationsTable: ', subOrganizationsTable);
        setTableData(subOrganizationsTable.map(item => ({...item, id: item._id})));
        setLoading(false);
    }, [user?.subOrganizations]);

    useEffect(() => {
        if (openUpdateModal) {
            httpRequest.get(`/db/users/getUsers/${user.organization}`)
            .then((res) => {
               if (openUpdateModal) {
                    setAddedMember({name: 'Add Member'})
                    // subOrgNameRef.current.value = selectedItem.name;
                    setSelectedSubOrgMembers(selectedItem.members.map((member) => ({ id: member, name: res.data.find(m => m._id === member)?.name })));
                    setNotInThisSubOrg(res.data.filter(member => !selectedItem.members.includes(member._id)));
                }
            }).catch((error) => {
                console.log(error);
            })
        } else {
            setSelectedSubOrgMembers([{}]);
            setNotInThisSubOrg([]);
        }
    }
    , [openUpdateModal]);

    const handleDelete = (itemId) => {
        if(user.role === 'superAdmin' || user.role === 'admin') {
            setAlertModal(true)
            const data = tableData.find((item) => item.id === itemId)
            setSelectedItem(data)
        }
        else {
            addNewNotifcation('Sorry, you are not authorized to delete sub-organization', 'warning')
        }
    }

    const handleUpdate = (itemId) => {
        if(user.role === 'superAdmin' || user.role === 'admin') {
            setOpenUpdateModal(true)
            const data = tableData.find((item) => item.id === itemId)
            setSelectedItem(data)
        } else {
            addNewNotifcation('Sorry, you are not authorized to update sub-organization', 'warning')
        }
    }

    const handleOnDelete = () => {
        setBtnLoading(true)
        const deleteObj = {
            subOrganizationId: selectedItem._id,
            organizationId: oragID
        }
        httpRequest.delete(`/db/sub-organizations/delete-sub-organization`, {data: deleteObj})
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Sub-organization deleted successfully', 'success')
            setTableData(prev => prev.filter(item => item.id !== selectedItem.id))
        })
        .catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Sub-organization could not be deleted', 'danger')
        })
        .finally(() => {
            setBtnLoading(false)
            setAlertModal(false)
        })
    }

    const handleOnUpdate = () => {
        setBtnLoading(true)
        const selectedMembers = selectedSubOrgMembers.map(member => member.id);
        const updateOrgObj = {
            //name: subOrgNameRef.current.value,
            members: selectedMembers
        }
        httpRequest.put(`/db/sub-organizations/update-sub-organization/${selectedItem.id}`, updateOrgObj)
        .then((res) => {
            console.log('res: ', res);
            addNewNotifcation('Sub-organization updated successfully', 'success')
            setTableData(prev => {
                return prev.map(item => {
                    if (item.id === selectedItem.id) {
                        return {...item, members: selectedMembers}
                    }
                    return item
                })
            })
            setOpenUpdateModal(false)
        })
        .catch((error) => {
            console.log('error: ', error);
            addNewNotifcation('Sub-organization could not be updated', 'danger')
        })
        .finally(() => {
            setBtnLoading(false)
        })
    }

    const handleAddMember = (item) => {
        setAddedMember(item);
        setSelectedSubOrgMembers([...selectedSubOrgMembers, {id: item._id, name: item.name}]);
        setNotInThisSubOrg(notInThisSubOrg.filter(member => member.name !== item.name));
        setAddedMember({name: 'Add Member'});
    }

    const handleRemoveMember = (id) => {
        const member = selectedSubOrgMembers.find((member) => member.id === id);
        setSelectedSubOrgMembers(selectedSubOrgMembers.filter((member) => member.id !== id));
        setNotInThisSubOrg([...notInThisSubOrg, {id: member.id, name: member.name}]);
    }

    const handleDeleteSelected = (selectedItems) => {
        if(user.role === 'superAdmin') {
            httpRequest.put(`/db/sub-organizations/delete-multiple-sub-organizations`, {ids: selectedItems})
            .then((res) => {
                console.log('res: ', res);
                addNewNotifcation('Sub-organizations deleted successfully', 'success')
                setTableData(prev => prev.filter(item => !selectedItems.includes(item.id)))
            })
            .catch((error) => {
                console.log('error: ', error);
                addNewNotifcation('Sub-organizations could not be deleted', 'danger')
            })
        }
        else {
            addNewNotifcation('Sorry, you are not authorized to delete sub-organization', 'warning')
        }
    }

    const handleAddNewSubOrg = () => {
        if (user.role !== 'superAdmin') {
            addNewNotifcation('Sorry, you are not authorized to add sub-organization', 'warning')
        } else {
            setShow(true)
        }
    }

    const subOrgRowActions = [
        {'name': 'Edit', 'icon': <MdEdit size={18} />, 'onclick': handleUpdate},
        {'name': 'Remove', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDelete},
    ]

    const subOrgTableActions = [
        {'name': 'Delete Selected', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleDeleteSelected},
        {'name': 'Add New', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleAddNewSubOrg}
    ]

    const memberTableOptions = [
        {'name': 'Remove', 'icon': <MdDelete size={18} color='var(--primary-color)' />, 'onclick': handleRemoveMember}
    ]

    return (
        
        <div className='organization-users-container'>
            <div className='header-container1'>
                <div className='header-container2'>
                    <h3>Sub Organizations</h3>
                    <p>{tableData.length} Total</p>
                </div>
            </div>

            <div className='sub-org-btn-container'>
                {loading ? (
                    <AppLoader />
                ) : (
                    <NewStyledTable
                    columns={[
                        { 
                        field: 'name', 
                        headerName: 'Name', 
                        renderCell: (rowData) => (
                            <div onClick={() => localStorage.setItem('selectedSubOrg', rowData.id)}>
                                <Link className='flex items-center gap-2 text-[blue] hover:underline' to={`${rowData.id}/bex-assistants`}>
                                    {rowData.name}
                                </Link>
                            </div>
                        ) 
                        }, 
                        { 
                        field: 'members', 
                        headerName: 'Members', 
                        renderCell: (rowData) => rowData.members.length 
                        }
                    ]}
                    data={tableData}
                    rowActions={subOrgRowActions}
                    tableActions={subOrgTableActions}
                    multiSelect={true}
                    />
                )}  
                <CreateSubOrganizationDialog show={show} setShow={setShow} subOrgs={subOrgs} setSubOrgs={setSubOrgs} user={user} addNewNotifcation={addNewNotifcation} />

                <UpdateSubOrganizationDialog
                show={openUpdateModal}
                setShow={setOpenUpdateModal}
                subOrg={selectedItem}
                setSubOrg={setSelectedItem}
                user={user}
                addNewNotification={addNewNotifcation}
                />

                <AlertModal
                    loading={btnLoading}
                    setopenModal={setAlertModal}
                    openModal={alertModal}
                    onDelete={handleOnDelete}
                />
            </div>
        </div>
    );
}

export default SubOrganizationSetting;