import { feoHttpClient } from '../../clients';


/**
 * Service class for Text-to-Speech functionality.
 */
class TextToSpeechService {
    constructor(user) {
        this.user = user;
        this.userSubOragID = localStorage.getItem('selectedSubOrg') // Refactor: This should be in a global store not local storage, use constants for key.
    }

    /**
     * Converts text to speech using the specified voice.
     * @param {string} text - The text to convert to speech.
     * @param {string} voice - The voice to use for the speech conversion.
     * @returns {Promise<string>} - A promise that resolves to the URL of the generated audio file.
     */
    async textToSpeech(text, voice) {
        try {
            // /feo-v3/api/stt-tts
            const response = await feoHttpClient.post('/stt-tts/tts/text-to-speech', { text, voice }, {
                responseType: 'arraybuffer' // Ensure the response is treated as binary data
            });

            // Create a Blob from the buffer
            const blob = new Blob([response.data], { type: 'audio/mp3' });

            // Create a URL for the Blob
            const audioURL = URL.createObjectURL(blob);

            return audioURL;
        } catch (error) {
            console.error('Error converting text to speech:', error);
            
            throw new Error('Failed to convert text to speech. Please try again later.');
        }
    }

    /**
     * Converts an instruction file to speech using the Text-to-Speech (TTS) service.
     * @param {File} file - The instruction file to convert.
     * @param {string} voice - The voice to use for the speech conversion.
     * @returns {Promise<string>} - A promise that resolves to the URL of the generated audio file.
     */
    async instructionToSpeech(file, voice) {
        try {
            const formData = new FormData();
            const subOrg = this.user.subOrganizations.find(subOrg => subOrg._id === this.userSubOragID);

            if (!subOrg) {
                // throw new Error(`Sub-organization with ID ${userSubOragID} not found.`);
            }

            formData.append('file', file);
            formData.append('voice', voice);
            formData.append('subOrg_Id', subOrg._id);
            formData.append('org_Id', this.user.organization);
            formData.append('user_id', this.user.id);

            const response = await feoHttpClient.post('/stt-tts/tts/instruction', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                responseType: 'arraybuffer' // Ensure the response is treated as binary data
            });

            // Create a Blob from the buffer
            const blob = new Blob([response.data], { type: 'audio/mp3' });

            // Create a URL for the Blob
            const audioURL = URL.createObjectURL(blob);

            return audioURL;
        } catch (error) {
            console.error('Error converting instruction to speech:', error);

            throw new Error('Failed to convert instruction to speech. Please try again later.');
        }
    }

    /**
     * Retrieves the user's TTS operation history.
     * @returns {Promise<any[]>} - A promise that resolves to an array of user's TTS operation history.
     */
    async retrieveTTSOperationsHistory() {
        try {
            const response = await feoHttpClient.get('/stt-tts/tts/user-operations', {
                params: {
                    user_id: this.user.id
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching user TTS operation history:', error);
            
            throw new Error('Failed to retrieve TTS operation history. Please try again later.');
        }
    }

    /**
     * Retrieves the list of available voices.
     * @returns {Promise<any[]>} - A promise that resolves to an array of available voices.
     */
    async getListOfVoices() {
        try {
            const response = await feoHttpClient.get('/stt-tts/tts/list-of-voices');
            return response.data;
        } catch (error) {
            console.error('Error fetching list of voices:', error);
            
            throw new Error('Failed to retrieve list of voices. Please try again later.');
        }
    }
}

export { TextToSpeechService };