import { React, useEffect, useState } from "react";
import emailSamples from "./emailSamples";
export default function Emailform({ emailSample, setEmailSample, setShow, setEmailInbox }) {
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (emailSample) {
      setEmail(emailSample)
    }
  }, [])
  
  const handleEmailSample = (e) => {
    setEmailSample(e.target.value);
    setEmail(e.target.value);

    // setOpCodeSelectedType(opCodeTypes.find(type => type.opcode_name === e.target.value))
  };

  return (
    <div className="opcode-form-container">
      <div className="opcode-type-container" style={{ fontWeight: "600" }}>
        <div className="opcode-label">
          <label> Select Email Sample </label>
        </div>
        <select
          className="response-select"
          name="emailSample"
          onChange={(e) => {
            console.log('chaned');
            const emailSample = emailSamples.find(
              (o) => o.opcode === e.target.value
            );
            setEmailSample(emailSample.email);
            setEmail(emailSample.email);
            setShow(false)
            setEmailInbox(false)
          }}
        >
          <option name="Select Sample" disabled selected>
            Select Sample
          </option>
          {emailSamples.map((email) => {
            return <option key={email.opcode} name={email.opcode}>{email.opcode}</option>;
          })}
        </select>
      </div>
      <div className="opcode-type-container" style={{ fontWeight: "600" }}>
        <div className="opcode-label">
          <label> Email Content </label>
        </div>
        <textarea
          // disabled
          placeholder="please select an email sample from the above"
          name="email sample"
          className="response-select"
          rows={10}
          onChange={handleEmailSample}
          value={email}
        />
      </div>
    </div>
  );
}
