import React, { useContext } from 'react';
import { Context } from '../../../../context/GlobalState';
import { Card, CardContent, CardTitle } from '../../../../components/ui/card';
import { Label } from '../../../../components/ui/label';

const SubOrganizationSettings = () => {
    const { selectedSubOrganization} = useContext(Context);

    return (
        <div className='w-full flex flex-col gap-4'>
            <Card className="w-full space-y-8 p-4">
                <CardTitle> Sub Organization </CardTitle>
                    <CardContent>
                        <div className='flex flex-col gap-3'>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Name </Label>
                                {/* display the suborg name from user?.subOrganizations based on selectedSubOrganization */}
                                <span className='font-[500]'>{selectedSubOrganization.name}</span>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <Label className='text-gray-500'> Created </Label>
                                <span className='font-[500]'>{new Date(selectedSubOrganization.created).toDateString()}</span>
                            </div>
                        </div>
                    </CardContent>
            </Card>
        </div>
    )
}

export default SubOrganizationSettings;