import React, { useContext, useState, useEffect } from 'react';
import { Popover, PopoverTrigger, PopoverContent } from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import {Combobox} from '../../../../components/ui/combobox';
import { Settings2, RotateCcw, SquareTerminal, Info, Copy, Upload, Save } from 'lucide-react';
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { Slider } from "../../../../components/ui/slider";
import { RadioGroup, RadioGroupItem } from "../../../../components/ui/radio-group";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../../components/ui/tooltip";
import { PartContext } from "../../context/PartContext";
import modelList from "../../files/modelList.json";
import {LoaderSpinner} from "../../../../components/LoaderSpinner";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../../../components/ui/dialog";
import { Textarea } from "../../../../components/ui/textarea";

import { PromptAnatomyLive } from './PromptAnatomyLive';
import { PromptOutputLive } from './PromptOutputLive';
import { RAGLive } from './RAGLive';

import SaveModelDialog from './SaveModelDialog';
import LoadModelDialog from './LoadModelDialog';

const PromptPanelLive = () => {

    const {
        setPromptLive,
        setPromptOutputLive,
        selectedModelLive, setSelectedModelLive,
        setParametersLive,
        generateLive,
        generateLoadingLive,
    } = useContext(PartContext);

    const [modelUseCase, setModelUseCase] = useState("");

    

    const clearPrompt = () => {
        setPromptLive({instruction: "", context: "", target_format: "", input_data: "", sample_input: "", sample_output: ""});
        setPromptOutputLive("");
        setSelectedModelLive("");
        setParametersLive({mode: "greedy", temperature: 0.7, max_tokens: 0, max_new_tokens: 200, top_p: 1, top_k: 50, stop_sequences: ["<|endoftext|>", "<|endofcode|>"], repetition_penalty: 1.2});
    };

    useEffect(()=>{
        if (!selectedModelLive || selectedModelLive === null || (typeof selectedModelLive === 'object' && Object.keys(selectedModelLive).length === 0)){
            setModelUseCase("");
            return;
        }
        
        const findModel = modelList.find(model => model.value === selectedModelLive.value);
        
        if (!findModel) return;
        setModelUseCase(findModel.use_cases);

    }, [selectedModelLive]);

    return (
        <div className="w-full grid md:grid-cols-[3fr_2fr] gap-4">

            <PromptAnatomyLive/>

            <div className="grid gap-4 h-fit">
                <RAGLive/>
            </div>
            <div className="col-span-2">
                <PromptOutputLive/>
            </div>

            <footer className="flex col-span-2 items-center justify-end h-[84px] w-[calc(100%+4rem)] bg-white border-gray-300 py-4 z-50 sticky bottom-0 bg-white transform translate-y-8 -translate-x-8 shadow-[rgba(0,0,15,0.05)_0px_-2px_10px_2px] border-t">
                <div className="flex items-center justify-between gap-2 px-4 w-full">
                    <div className="flex gap-2">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button size="icon" variant="ghost">
                                        <Info className="h-5 w-5" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent className="w-[300px]" align="start">
                                    <p>{modelUseCase ? modelUseCase : 'Select a foundation model to see use casesa.'}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>

                        <Combobox
                            defaultDisplayed='Select Model'
                                items={modelList}
                                selectedValue={selectedModelLive}
                                setter={setSelectedModelLive}
                        />

                        <PopoverConfigureModel
                            trigger={
                                <Button aria-haspopup="true" size="icon" variant="ghost">
                                    <Settings2 className="h-5 w-5" />
                                </Button>
                            }
                        />

                        <ConsolidatedDialogLive/>
                        <SaveModelDialog/>
                        <LoadModelDialog/>
                        
                    </div>
                    <div className="flex items-center gap-2">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button size="icon" variant="ghost" onClick={clearPrompt}>
                                        <RotateCcw className="h-5 w-5" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Clear Prompt</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <Button variant="default" onClick={generateLive} disabled={generateLoadingLive}>{generateLoadingLive ? <LoaderSpinner/> : "Generate"}</Button>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default PromptPanelLive;

export function PopoverConfigureModel({trigger}) {

    const {
        parametersLive, setParametersLive,
        selectedModelLive
    } = useContext(PartContext);
    const [tokensLimit, setTokensLimit] = useState({min: 1, max: 2048});
    const [tempLimit, setTempLimit] = useState({min: 0, max: 2});
    const [topKLimit, setTopKLimit] = useState({min: 1, max: 100});
    const [topPLimit, setTopPLimit] = useState({min: 0, max: 1});

    useEffect(() => {
        if (!selectedModelLive || selectedModelLive === null || (typeof selectedModelLive === 'object' && Object.keys(selectedModelLive).length === 0)) return;
        
        const findModel = modelList.find(model => model.value === selectedModelLive.value);
        
        if (!findModel) return;

        if (parametersLive.max_new_tokens > findModel.max_tokens){
            setParametersLive({...parametersLive, max_new_tokens: findModel.max_tokens});
        }
        setTokensLimit({min: 1, max: findModel.max_tokens});
        

    }, [selectedModelLive]);

    const handleModeChange = (value) => {
        setParametersLive({...parametersLive, mode: value});
    }

    return (
        <Popover>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <PopoverTrigger asChild>
                            {trigger ? trigger :
                                <Button variant="outline">Open popover</Button>
                            }
                        </PopoverTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Configure Model</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>


                
            
            <PopoverContent className="w-80" align="center">
                <div className="grid gap-4">
                <div className="space-y-2">
                    <h4 className="font-medium leading-none">Configure Model</h4>
                    <p className="text-sm text-muted-foreground">
                    Set the parameters for the model.
                    </p>
                </div>
                <div className="grid gap-4">

                    <RadioGroup defaultValue="greedy" onValueChange={handleModeChange} value={parametersLive.mode} className="grid grid-cols-2 gap-4">
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="greedy" id="r1"/>
                            <Label htmlFor="r1">Greedy</Label>
                        </div>
                        <div className="flex items-center space-x-2">
                            <RadioGroupItem value="sampling" id="r2"/>
                            <Label htmlFor="r2">Sampling</Label>
                        </div>
                    </RadioGroup>


                    <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                        <Label htmlFor="maxTokens" className="col-span-2">Max Tokens</Label>
                        <Input
                            id="maxTokens"
                            type="number"
                            value={parametersLive.max_new_tokens}
                            step={1}
                            max={tokensLimit.max}
                            min={tokensLimit.min}
                            onChange={(e)=>{setParametersLive({...parametersLive, max_new_tokens: e.target.value})}}
                            className="col-span-1 h-8"
                        />
                        <Slider
                            id="maxTokens"
                            value={[parametersLive.max_new_tokens]}
                            onValueChange={(value)=>{setParametersLive({...parametersLive, max_new_tokens: value})}}
                            step={1}
                            max={tokensLimit.max}
                            min={tokensLimit.min}
                            className="col-span-3 h-8"
                        />
                    </div>

                    <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                        <Label htmlFor="temp" className={`col-span-2 ${parametersLive.mode === "greedy" ? "text-gray-400" : ""}`}>Temperature</Label>
                        <Input
                            id="temp"
                            type="number"
                            value={parametersLive.temperature}
                            step={0.1}
                            max={tempLimit.max}
                            min={tempLimit.min}
                            onChange={(e)=>{setParametersLive({...parametersLive, temperature: e.target.value})}}
                            className="col-span-1 h-8"
                            disabled={parametersLive.mode === "greedy"}
                        />
                        <Slider
                            id="temp"
                            value={[parametersLive.temperature]}
                            onValueChange={(value)=>{setParametersLive({...parametersLive, temperature: value})}}
                            step={0.1}
                            max={tempLimit.max}
                            min={tempLimit.min}
                            className="col-span-3 h-8"
                            disabled={parametersLive.mode === "greedy"}
                        />
                    </div>

                    <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                        <Label htmlFor="topK" className={`col-span-2 ${parametersLive.mode === "greedy" ? "text-gray-400" : ""}`}>Top K</Label>
                        <Input
                            id="topK"
                            type="number"
                            value={parametersLive.top_k}
                            step={1}
                            max={topKLimit.max}
                            min={topKLimit.min}
                            onChange={(e)=>{setParametersLive({...parametersLive, top_k: e.target.value})}}
                            className="col-span-1 h-8"
                            disabled={parametersLive.mode === "greedy"}
                        />
                        <Slider
                            id="topK"
                            value={[parametersLive.top_k]}
                            onValueChange={(value)=>{setParametersLive({...parametersLive, top_k: value})}}
                            step={1}
                            max={topKLimit.max}
                            min={topKLimit.min}
                            className="col-span-3 h-8"
                            disabled={parametersLive.mode === "greedy"}
                        />
                    </div>

                    <div className="grid grid-cols-3 items-center gap-y-0 gap-x-2">
                        <Label htmlFor="topP" className={`col-span-2 ${parametersLive.mode === "greedy" ? "text-gray-400" : ""}`}>Top P</Label>
                        <Input
                            id="topP"
                            type="number"
                            value={parametersLive.top_p}
                            step={0.1}
                            max={topPLimit.max}
                            min={topPLimit.min}
                            onChange={(e)=>{setParametersLive({...parametersLive, top_p: e.target.value})}}
                            className="col-span-1 h-8"
                            disabled={parametersLive.mode === "greedy"}
                        />
                        <Slider
                            id="topP"
                            value={[parametersLive.top_p]}
                            onValueChange={(value)=>{setParametersLive({...parametersLive, top_p: value})}}
                            step={0.1}
                            max={topPLimit.max}
                            min={topPLimit.min}
                            className="col-span-3 h-8"
                            disabled={parametersLive.mode === "greedy"}
                        />
                    </div>
                </div>
                </div>
            </PopoverContent>
        </Popover>
      )
}

export function ConsolidatedDialogLive() {
    const [open, setOpen] = useState(false);
    const [consolidatedPrompt, setConsolidatedPrompt] = useState('');
    
    const {getConsolidatedPrompt, addNewNotifcation} = useContext(PartContext);

    const handleOnOpen = () => {
        setOpen(true);
        setConsolidatedPrompt(getConsolidatedPrompt(true));
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(consolidatedPrompt);
        addNewNotifcation("Copied to clipboard.", "success");
    }


    return (
      <Dialog open={open} onOpenChange={setOpen}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                            <Button size="icon" variant="ghost" onClick={handleOnOpen}>
                                <SquareTerminal className="h-5 w-5" />
                            </Button>
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>View Consolidated Prompt</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Consolidated Prompt</DialogTitle>
            <DialogDescription>
              Use this prompt on other GenAI tools. Modify the prompt as needed.
            </DialogDescription>
          </DialogHeader>
          <div className="">
            <Textarea rows={10} className="w-full max-h-[425px]" value={consolidatedPrompt} onChange={(e)=>{setConsolidatedPrompt(e.target.value)}}/>
          </div>
          <DialogFooter>
            <Button type="submit" variant="secondary" onClick={() => setOpen(false)}>Close</Button>
            <Button type="submit" onClick={handleCopy}>Copy</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }
