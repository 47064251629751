import React, { useEffect, useState } from 'react';
import { Container, PrimaryInput, SecondaryInput } from '../../../../components/FormInput';
import { httpRequest } from '../../../../utils/httpsRequest';


const AddAdminForm = ({ userInput, setUserInput, isModalOpen }) => {

    const [roles, setRoles] = useState([]);

    const handleInputChange = (key, value) => {
        setUserInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    useEffect(() => {
        httpRequest.get('/role/get-roles')
        .then((res) =>{
            console.log('roles: ', res.data);
            setRoles(res.data);
        }).catch((error) => {
            console.log(error);
        })
    }, [isModalOpen]);

    return (
        <div>
            <Container labelName={'Admin Email'} style={{ fontWeight: '600' }}>
                <PrimaryInput onChange={(e) => handleInputChange('email', e.target.value)}
                    type="email"
                    name="email"
                    placeholder="Type admin email here"
                    required
                    value={userInput.email}
                />
            </Container>
            <Container labelName={'Admin Password'} style={{ fontWeight: '600' }}>
                <PrimaryInput 
                    onChange={(e) => handleInputChange('password', e.target.value)}
                    type="password"
                    name="password"
                    placeholder="Type admin password here"
                    required
                    value={userInput.password}
                />
            </Container>
            <Container labelName={'Admin Name'} style={{ fontWeight: '600' }}>
                <PrimaryInput 
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    type="text"
                    name="name"
                    placeholder="Type admin name here"
                    required
                    value={userInput.name}
                />
            </Container>

            <Container labelName={'Select type'} style={{ fontWeight: '600', marginUp: '5px' }}>
                <select onChange={(e) => handleInputChange('role', e.target.value)} name="type" value={userInput.type} style={{ padding: '10px' }}>
                    {/* <option value="editor">Editor</option>
                    <option value="admin">Admin</option> */}
                    {roles.map(role => (
                        <option key={role.name} value={role.name}>{role.name}</option>
                    ))}
                </select>
            </Container>
        </div>
    );
};

export default AddAdminForm;