import React from 'react'
import styled from 'styled-components'
import { RedButton } from './Buttons'

const ListTag=styled.div`
    display: flex;
    color: var(--primary-color);
    max-width: 1300px;
    flex-direction: column;
    padding: 20px;
    margin: 50px auto;
    width: 100%;
`

const ListConatiner=styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f1f5fb;
    padding: 2rem;
`

const ListTitle=styled.span`
    font-size: 27px;
    margin-bottom: 1rem;
`

const ButtonContainer = styled.div`
    display: flex;
`

const ListBtn=styled.button`
    padding: 10px 15px;
    background-color: var(--primary-color);
    border-radius: 5px;
    font-size: 15px;
    color: #fff;
    border: none;
    min-width: 170px;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:hover {
        background-color: var(--primary-color-hover);
    }
`
const ListConatiner2=styled.div`
    display: flex;
    gap: 1rem;
    width: 100%;
    border-radius: 10px;
    color: black;
    flex-wrap: wrap;
    margin-top: 1rem;
`

const NotFoundList=styled.div`
    display:flex;
    justify-content:center;
    width:100%;
    font-size:30px;
    font-weight:bold;

`

function PageStyle({children,list,title,btn,setOpenModal, renderItem}) {

    return (
        <ListTag>
        <ListConatiner>
            <ListTitle><b>{title}</b></ListTitle>
            {children[0]}
            <ButtonContainer>
                {btn && (
                    <RedButton onClick={()=>setOpenModal(true)}>
                        {btn}
                    </RedButton>
                )}
            </ButtonContainer>
            <ListConatiner2>
                {list?.length > 0 ? (
                    list?.map((item) => (
                        renderItem(item)
                    ))
                ) : (
                    <NotFoundList> No {title} found</NotFoundList>
                ) }
            </ListConatiner2>
        </ListConatiner>
    </ListTag>
    )
}
    

export function PageStyleLayout({title, btn, setOpenModal, children}) {

    return (
        <ListTag>
        <ListConatiner>
            <ListTitle><b>{title}</b></ListTitle>
            <ButtonContainer>
                {btn && (
                    <RedButton onClick={()=>setOpenModal(true)}>
                        {btn}
                    </RedButton>
                )}
            </ButtonContainer>
            <ListConatiner2>
                {children}
            </ListConatiner2>
        </ListConatiner>
    </ListTag>
    )
}
    

export default PageStyle