import React, { useContext, useState, useEffect } from 'react';
import { Button } from "../../../../components/ui/button";
import { Save } from 'lucide-react';
import { Input } from "../../../../components/ui/input";
import { Label } from "../../../../components/ui/label";
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "../../../../components/ui/tooltip";
import { PartContext } from "../../context/PartContext";
import {LoaderSpinner} from "../../../../components/LoaderSpinner";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "../../../../components/ui/dialog";
import { Textarea } from "../../../../components/ui/textarea";
import { httpRequestO } from "../../../../utils/httpsRequest";



const SaveModelDialog = () => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");


    const {
        selectedModelLive,
        parametersLive,
        user,
        addNewNotifcation,
        getConsolidatedPrompt,
        selectedSubOrganization
    } = useContext(PartContext);

    const handleOnOpen = () => {
        setOpen(true);
    }


    const OnSaveModel = async () => {
        console.log(selectedModelLive);
        // Validate
        if (!selectedModelLive){
            addNewNotifcation("Please select a 'Model' first.", "warning");
            return;
        }
        if (getConsolidatedPrompt(true).trim() === "Input:\nOutput:"){
            addNewNotifcation("Please provide a prompt.", "warning");
            return;
        }

        if (name.trim() === "" || description.trim() === ""){
            addNewNotifcation("Please enter 'Name' and 'Description'.", "warning");
            return;
        }


        

        setLoading(true);

        // Set params, remove top k, top p and temp, if greedy
        let params = {};
        const prompt = await getConsolidatedPrompt(true);
        if (parametersLive.mode === "greedy") {
            params = { 
                mode: parametersLive.mode,
                max_tokens: 0,
                max_new_tokens: Number(parametersLive.max_new_tokens),
                stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
                repetition_penalty: 1.2
            };
        } else {
            params = {
                mode: parametersLive.mode,
                max_tokens: 0,
                max_new_tokens: Number(parametersLive.max_new_tokens),
                stop_sequences: ["<|endoftext|>", "<|endofcode|>"],
                temperature: Number(parametersLive.temperature),
                top_k: Number(parametersLive.top_k),
                top_p: Number(parametersLive.top_p),
                repetition_penalty: 1.2
            };
        }

        const subOrgId = user.subOrganizations.length > 0 ? user.subOrganizations[0]._id : "";

        const body = {
            unique_name: name,
            modelId: selectedModelLive.value,
            parameters: params,
            prompt: prompt,
            modelDescription: description,
            org_id: user.organization,
            sub_org_id: subOrgId,
            metadata: {project:"use_case_models"}
            // placeholders: {
            //     additionalProp1: {
            //         description: "string",
            //         format: "string",
            //         example: "string"
            //     },
            //         additionalProp2: {
            //         description: "string",
            //         format: "string",
            //         example: "string"
            //     },
            //         additionalProp3: {
            //         description: "string",
            //         format: "string",
            //         example: "string"
            //     }
            // }
        }
        console.log(body)


        

        try {
            const result = await httpRequestO.post('/projecto/api/v1/model/foundationModel/create_suborg_model', body);
            addNewNotifcation("Successfully saved the model.", "success");
            // return result
            setName("");
            setDescription("");
            setOpen(false);
        }
        catch (error) {
            console.log(error);
            addNewNotifcation("Something went wrong. Could not save the model.", "danger");
        }
        finally{
            setLoading(false);
        }
    }


    return (
      <Dialog open={open} onOpenChange={setOpen}>
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <DialogTrigger asChild>
                            <Button variant="outline" onClick={handleOnOpen}>
                                {/* <Save className="h-5 w-5" /> */}
                                Save Model
                            </Button>
                        </DialogTrigger>
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>Save Your Model's prompt and configurations.</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
            <DialogTitle>Save Model</DialogTitle>
            <DialogDescription>
                The model will be saved including the prompt, model ID, and parameters you have created. This can be used to try out generating your own chatbot!
            </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                Name
                </Label>
                <Input
                    id="name"
                    value={name}
                    onChange={(e)=>{setName(e.target.value)}}
                    className="col-span-3"
                    placeholder="Enter a unique name"
                />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="description" className="text-right">
                    Description
                </Label>
                <Textarea
                    id="description"
                    value={description}
                    onChange={(e)=>{setDescription(e.target.value)}}
                    className="col-span-3"
                    placeholder="Enter description"
                />
            </div>
        </div>
        <DialogFooter>
          <Button type="submit" onClick={OnSaveModel} disabled={loading}>{loading ? <LoaderSpinner/> : "Save"}</Button>
        </DialogFooter>
        </DialogContent>
      </Dialog>
    )
  }


  export default SaveModelDialog;