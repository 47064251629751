import React, { useContext, useState } from 'react'
import './web-engagement-styles.css'
import SetupCard from '../../../components/AppSetup/SetupCard.jsx'
import SetupModal from '../../../components/AppSetup/SetupModal.jsx'
import { useNavigate, useParams } from 'react-router-dom'
import { Context } from '../../../context/GlobalState.js'
import Breadcrumb from 'components/Breadcrumb'
import WebChat from '../../WebEngagement/Components/WebChat.jsx'



export default function WebEngagementDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your website URL where you are going to setup the BeX AI Assistant',
        'A set of FAQ or knowledge base documents that you want to setup'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for website conversation is designed to assist you with handling your website conversations.',
        'This BeX AI Assistant is capable of communicating with your website visitors, to answer all the queries based on the knowledge base that you train her with, record down appointments, connects to your backend systems.'
    ];

    const navigate = useNavigate()
    const { user } = useContext(Context)
    const param = useParams()
    const {addNewNotifcation } = useContext(Context);


    const [openModal, setOpenModal] = useState(false)

    const handleDemoClick = () => {
        // setOpenModal(!openModal)
        if (user.organization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/web-demo`)
        } else {
            navigate(`/organizations`)
        }
        console.log(param.oragID, param.subOragID)
    }

    const handleSetupClick = () => {
        addNewNotifcation('No setup is needed for this assistant. Click Try Assistant.','success');
    };

    return (
        <div className='web-engagement-dashboard-container'>
            <div className='web-engagement-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4} />

                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for Website Conversation'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant'
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                        <SetupModal title={'Demo Mode for BeX AI Assistant for Website Conversation '} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='web-engagement-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO: Choose a sample FAQ
                                    </div>
                                    <div className='demo-insturctions__text'>
                                        <span>
                                            Website conversation is designed to assist you with handling your website conversations.Capable of communicating with your website visitors, to answer all the queries based on the knowledge base that you train her with, record down appointments, connects to your backend systems.
                                        </span>
                                    </div>
                                </div>

                            </SetupModal.StyledInstructions>
                            <SetupModal.StyledSetup>
                             
                                <WebChat />
                            </SetupModal.StyledSetup>
                        </SetupModal>

                    </div>
                </div>
            </div>
        </div>
    )
}