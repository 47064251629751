import { useEffect, useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/ui/dialog"
import { Button } from "../../../components/ui/button"
import { Label } from "../../../components/ui/label"
import { Input } from "../../../components/ui/input"
import { containsKeyValue } from "."

export default function UploadDocs({  showUploadModal, setShowUploadModal, onSubmit, setDocumentQuery, metadata, setMetadata, btnLoading }) {
    
    const RESERVED_METADATA = [
        'userId',
        'bucket_name'
    ]

    const [isEmpty, setIsEmpty] = useState(false)


    const addMetadata = () => {
        setMetadata([...metadata, { key: "", value: "" }])
    }

    const removeMetadata = (index) => {
        const updatedMetadata = [...metadata]
        updatedMetadata.splice(index, 1)
        setMetadata(updatedMetadata)
    }

    useEffect(() => {
        setIsEmpty(containsKeyValue(metadata, 'value') || containsKeyValue(metadata, 'key'))
    }, [metadata])

    const handleMetadataChange = (index, field, value) => {
        const updatedMetadata = [...metadata]
        updatedMetadata[index][field] = value
        setMetadata(updatedMetadata)
    }

    const hanldeUpload = (e) => {
        setDocumentQuery(e.target.files[0])
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        onSubmit()
    }

    return (
        <Dialog open={showUploadModal} onOpenChange={setShowUploadModal}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Upload Document</DialogTitle>
                    <DialogDescription>Add your document and metadata before uploading.</DialogDescription>
                </DialogHeader>
                <form onSubmit={handleSubmit} className="grid gap-4">
                    <div>
                        {/* <Label htmlFor="document-query" className="mb-2">
                        Document Query
                        </Label> */}
                        <div className="grid gap-2">
                            <Label htmlFor="file">File</Label>
                            <Input onChange={hanldeUpload} id="file" type="file" />
                        </div>
                    </div>
                    <div>
                        <Label className="mb-2">Metadata</Label>
                        <div className="grid gap-2 py-2">
                            {metadata.map((item, index) => (
                                <div key={index} className="grid grid-cols-[1fr_1fr_auto] gap-2">
                                    <Input
                                        placeholder="Key"
                                        value={item.key}
                                        onChange={(e) => handleMetadataChange(index, "key", e.target.value)}
                                    />
                                    <Input
                                        placeholder="Value"
                                        value={item.value}
                                        onChange={(e) => handleMetadataChange(index, "value", e.target.value)}
                                    />
                                    <Button type="button" variant="ghost" size="icon" onClick={() => removeMetadata(index)}>
                                        <XIcon className="h-4 w-4" />
                                        <span className="sr-only">Remove metadata</span>
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <Button disabled={isEmpty} type="button" className='mt-2' variant="ghost" onClick={addMetadata}>
                            <PlusIcon className="h-4 w-4 mr-2" />
                            Add Metadata
                        </Button>
                    </div>
                    <DialogFooter>
                        <Button disabled={btnLoading} type="submit">Submit</Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

function PlusIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            >
            <path d="M5 12h14" />
            <path d="M12 5v14" />
        </svg>
    )
}


function XIcon(props) {
    return (
        <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
        </svg>
    )
}