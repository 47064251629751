import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../../../context/GlobalState'
import { httpRequest } from '../../../../utils/httpsRequest'
import NewStyledTable from '../../../../components/ui/StyledTable'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from '../../../../components/ui/dialog'
import { Button } from '../../../../components/ui/button'
import { ScrollArea } from '../../../../components/ui/scroll-area'
import { Label } from '../../../../components/ui/label'
import { Input } from '../../../../components/ui/input'
import { Badge } from '../../../../components/ui/badge'
import { MagnifyingGlassIcon, Cross1Icon, CheckIcon } from "@radix-ui/react-icons";
import { LoaderSpinner } from '../../../../components/LoaderSpinner'
import { AppLoader } from '../../../../components/LoaderSpinner'
import { cn } from '../../../../lib/utils'


export default function SubOrganizationMembers() {
  const [inThisSubOrg, setInThisSubOrg] = useState([]);
  const [notInThisSubOrg, setNotInThisSubOrg] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, selectedSubOrganization,addNewNotifcation, setSelectedSubOrganization } = useContext(Context);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingMembers, setLoadingMembers] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);


  useEffect(() => {
       httpRequest.get(`/db/users/getUsers/${user.organization}`)
       .then((res) => {
        console.log("fresponse", res.data);
        const formatedData = res.data.map((item) => {
            return {
                ...item,
                id: item._id,
            };
        });
        setInThisSubOrg(formatedData.filter((item) => selectedSubOrganization.members.includes(item._id)))
        setNotInThisSubOrg(formatedData.filter((item) => !selectedSubOrganization.members.includes(item._id)))
            console.log("inthissub response", inThisSubOrg);
        }
        ).catch((error) => {
            console.log(error);
        }).finally(() => {
            setLoading(false)
            setLoadingMembers(false)
        })
  }, []);

  useEffect(() => {
    if (!openDeleteModal)
        setSelectedMembers([])
    }, [openDeleteModal])


  const handleSelectMember = (member) => {
    if (!selectedMembers.some(m => m._id === member._id)) {
        setSelectedMembers([...selectedMembers, member]);
    }
  };

  const handleRemoveMember = (member) => {
      setSelectedMembers(selectedMembers.filter((m) => m._id !== member._id));
  };


    const handleAddMembers = () => {
        setBtnLoading(true);
        const memberIds = selectedMembers.map(m => m._id)
        httpRequest.put(`/db/sub-organizations/update-sub-organization/${selectedSubOrganization._id}`, { members: [...selectedSubOrganization.members, ...memberIds] })
            .then((res) => {
                setInThisSubOrg([...inThisSubOrg, ...selectedMembers])
                setNotInThisSubOrg(notInThisSubOrg.filter(m => !memberIds.includes(m._id)))
                setSelectedSubOrganization({...selectedSubOrganization, members: [...selectedSubOrganization.members, ...memberIds]})
                addNewNotifcation('Members added successfully', 'success')
                setOpenModal(false)
                setSelectedMembers([])
                console.log('Response data', res.data);
            })
            .catch((error) => {
                console.log(error)
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            })
            .finally(() => setBtnLoading(false))
    }

    const handleDelete = (itemId) => {
        console.log('memberId', itemId)
        if (user.role === 'superAdmin' || user.role === 'admin') {
            const data = inThisSubOrg.find((item) => item.id === itemId)
            setSelectedMembers([data])
            setOpenDeleteModal(true)
        } else {
          addNewNotifcation('Sorry, you are not authorized to remove member', 'danger')
        }
    }

    const handleOnDelete = () => {
        setBtnLoading(true);
        const memberIds = selectedMembers.map(m => m._id)
        httpRequest.put(`/db/sub-organizations/update-sub-organization/${selectedSubOrganization._id}`, { members: selectedSubOrganization.members.filter(m => !memberIds.includes(m)) })
            .then((res) => {
                setInThisSubOrg(inThisSubOrg.filter(m => !memberIds.includes(m._id)))
                setNotInThisSubOrg([...notInThisSubOrg, ...selectedMembers])  
                setSelectedSubOrganization({...selectedSubOrganization, members: selectedSubOrganization.members.filter(m => !memberIds.includes(m))})
                addNewNotifcation('Members removed successfully', 'success')
                setOpenDeleteModal(false)
            })
            .catch((error) => {

                console.log(error)
                addNewNotifcation('Something went wrong. Please try again', 'danger')
            })
            .finally(() => setBtnLoading(false))
    }


    const userRowActions = [
        {'name': 'Remove', 'onclick': handleDelete}
    ]

    const userTableActions = [
        // {'name': 'Delete Selected', 'onclick': handleDeleteSelected},
        {'name': 'Add New', 'onclick': () => setOpenModal(true)}
    ]

    const actionCondition = (row) => {
        return row?.role !== "super admin";
    };

    const filteredMembers = notInThisSubOrg.filter((member) =>
      member.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      member.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

  if (loading) {
      return <div className='flex flex-grow'> <AppLoader size={50} /> </div>;
  }

    return (
        <div className='organization-users-container'>
            <div className='header-container1'>
                <div className='header-container2'>
                    <h3>Manage Members</h3>
                    <p>{inThisSubOrg.length} Total</p>
                </div>
            </div>
            <div>
            {user.role === 'editor' ? (
                <NewStyledTable columns={[{ field: 'name', headerName: 'Name'}, { field: 'email', headerName: 'Email'}]} data={inThisSubOrg} multiSelect={false}/>
            ) : (
                <NewStyledTable columns={[{ field: 'name', headerName: 'Name'}, { field: 'email', headerName: 'Email'}]} data={inThisSubOrg} rowActions={userRowActions} tableActions={userTableActions} multiSelect={false} actionCondition={actionCondition}/>
            )}

                  {/* add new member modal */}
                  <Dialog open={openModal} onOpenChange={setOpenModal}>
                      <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                          <DialogHeader>
                              <DialogTitle className="text-2xl font-semibold">Add Members</DialogTitle>
                              <DialogDescription className="text-md text-muted-foreground">Add members to this sub organization.</DialogDescription>
                          </DialogHeader>
                          <div className="space-y-6">
                              <div className="flex">
                                  <ScrollArea className="rounded-md min-h-0 max-h-[80px]">
                                      <div className="flex flex-wrap gap-2">
                                          {selectedMembers.map((member) => (
                                              <Badge key={member._id} variant="secondary" className="p-2 text-sm">
                                                  {member.name}
                                                  <Cross1Icon className="ml-2 cursor-pointer" onClick={() => handleRemoveMember(member)} />
                                              </Badge>
                                          ))}
                                      </div>
                                  </ScrollArea>
                              </div>
                              <div className="relative space-y-3">
                                  <Label htmlFor="member-search" className="text-md">Member Selection</Label>
                                  <MagnifyingGlassIcon className="absolute top-7 left-3 h-6 w-6 text-muted-foreground" />
                                  <Input
                                      id="member-search"
                                      placeholder="Search members..."
                                      type="search"
                                      value={searchQuery}
                                      onChange={(e) => setSearchQuery(e.target.value)}
                                      className="w-full pl-12 pr-5 py-3 text-md rounded-md bg-white shadow-none appearance-none border-solid dark:bg-gray-950"
                                  />
                                  <ScrollArea className="border-solid rounded-md h-56">
                                      <div className="flex flex-col p-3 space-y-3">
                                          {loadingMembers ? <AppLoader size={60} centerAlign /> : filteredMembers.length === 0 ? <div className="text-center text-muted-foreground">No members found</div> : filteredMembers.map((member) => (
                                              <div key={member._id} className="flex items-center space-x-3 cursor-pointer" onClick={() => handleSelectMember(member)}>
                                                  <div>
                                                      <div className="font-medium text-md">{member.name}</div>
                                                      <div className="text-sm text-muted-foreground">{member.email}</div>
                                                  </div>
                                                  <CheckIcon className={cn("ml-auto h-6 w-6", selectedMembers.some(m => m._id === member._id) ? "opacity-100" : "opacity-0")} />
                                              </div>
                                          ))}
                                      </div>
                                  </ScrollArea>
                              </div>
                              <DialogFooter className="flex justify-end space-x-5 mt-6">
                                  <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                                  <Button onClick={handleAddMembers} className="px-5 py-3 text-md">
                                      {btnLoading ? <LoaderSpinner /> : 'Add Members'}
                                  </Button>
                              </DialogFooter>
                              </div> 
                          </DialogContent>
                      </Dialog>

                      {/* Delete member dialog */}
                      <Dialog open={openDeleteModal} onOpenChange={setOpenDeleteModal}>
                          <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                              <DialogHeader>
                                  <DialogTitle className="text-2xl font-semibold">Remove Member</DialogTitle>
                                  <DialogDescription className="text-md text-muted-foreground">Are you sure you want to remove <b>{selectedMembers[0]?.name}</b> from <b>{selectedSubOrganization.name}</b> sub organization?</DialogDescription>
                              </DialogHeader>
                              <DialogFooter className="flex justify-end space-x-5 mt-6">
                                  <Button variant="outline" onClick={() => setOpenDeleteModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                                  <Button onClick={handleOnDelete} className="px-5 py-3 text-md" variant="destructive">
                                      {btnLoading ? <LoaderSpinner /> : 'Delete'}
                                  </Button>
                              </DialogFooter>
                          </DialogContent>
                      </Dialog>

            </div>
        </div>
    )
}
