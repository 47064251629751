import React, { createContext, useState, useContext, useEffect } from 'react';
import {Context} from "context/GlobalState";
// import config from "pages/PromptingAndRagTraining/ailab.config";
import { ragService } from "api/services/PROJECT-O";
import { v4 as uuidv4 } from 'uuid';
import { QueryRAG, GetLLMResponse } from "pages/PromptingAndRagTraining/context/functions/documentChatOps";

export const DocChatContext = createContext({});

export const DocChatProvider = ({ children }) => {

  const {addNewNotifcation, user, selectedSubOrganization} = useContext(Context);
  
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedSample, setSelectedSample] = useState([]);
  const [uploadedDocs, setUploadedDocs] = useState([]);
  const [uploadedDocsOrg, setUploadedDocsOrg] = useState([]);
  const [processLoading, setProcessLoading] = useState(false);
  const [processLoadingOrg, setProcessLoadingOrg] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState('text_embedding');
  const [docChatConvo, setDocChatConvo] = useState([{role: 'system', message: "Hi! I am AI Teacher. I am here to help you go through your course materials. You can ask me questions about your slides, lecture materials, etc. I will do my best to answer your questions.", _id: uuidv4()}]);
  const [loading, setLoading] = useState(false);
  const [inputMessage, setInputMessage] = useState('');
  const [displayDocs, setDisplayDocs] = useState([]);
  const [displayDocsSample, setDisplayDocsSample] = useState([]);
  const [selectedDocIndex, setSelectedDocIndex] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  async function loadDocuments() {
    console.log("Retrieving uploaded documents...");
    const filter = {
      userId: user._id,
      organizationId: user.organization,
      subOrganizationId: selectedSubOrganization._id,
      assistant: 'ai-lab',
      feature: "ai-teacher",
      document_collection: "user"
    }
    await ragService.listDocuments(user.organization, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, filter)
    .then((res) => {
        if (res.data) setUploadedDocs(res.data)
          console.log("Uploaded documents:", res.data)
    }).catch((error) => {
        console.log(error);
        setUploadedDocs([]);
    })
  }
  async function loadDocumentsOrg() {
    console.log("Retrieving uploaded documents...");
    const filter = {
      // userId: user._id,
      organizationId: user.organization,
      // subOrganizationId: selectedSubOrganization._id,
      assistant: 'ai-lab',
      feature: "ai-teacher",
      document_collection: "organization"
    }
    await ragService.listDocuments(user.organization, process.env.REACT_APP_COMMON_SUB_ORGANIZATION_ID, filter)
    .then((res) => {
        if (res.data) setUploadedDocsOrg(res.data)
          console.log("Uploaded org documents:", res.data)
    }).catch((error) => {
        console.log(error);
        setUploadedDocsOrg([]);
    })
  }

  const OnSendPrompt = async (quiz) => {
    console.log("Quiz Mode: ", quiz)
    if (inputMessage.trim() === "") return;
    if (selectedDocuments.length === 0 && selectedSample.length === 0) return;

    setLoading(true);
    const newMessage = { role: 'user', message: inputMessage, _id: uuidv4()};
    setDocChatConvo(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');

    // console.log(selectedSample, selectedDocuments);
    const RAGResult = await QueryRAG(selectedSample.length > 0 ? selectedSample : selectedDocuments, inputMessage, selectedMethod, user, selectedSample.length > 0 ? true : false, quiz);
    const response = await GetLLMResponse(RAGResult, inputMessage, docChatConvo.slice(-6), quiz);
    const botMessage = response ? { role: 'assistant', message: response.trim(), _id: uuidv4() } 
    : { role: 'system', message: "Sorry, I cannot find that information in the document.", _id: uuidv4() };
    setDocChatConvo(prevMessages => [...prevMessages, botMessage]);
    
    setLoading(false);
  }


  const getFileExtension = (url) => {
    return url.split('.').pop().split(/\#|\?/)[0];
  };
  useEffect(() => {
    setDisplayDocs(selectedDocuments.map(doc => {
      switch (getFileExtension(doc.file_url)) {
          case 'txt': return {uri: `${doc.file_url}`, filename: doc.name, filetype: getFileExtension(doc.file_url)};
          case 'pdf': return {uri: `${doc.file_url}`, filename: doc.name, filetype: getFileExtension(doc.file_url)};
          default: return {uri: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.file_url)}`, filename: doc.name, filetype: getFileExtension(doc.file_url)};
      }
    }));
  }, [selectedDocuments]);
  useEffect(() => {
    setDisplayDocsSample(selectedSample.map(doc => {
      switch (getFileExtension(doc.file_url)) {
          case 'txt': return {uri: `${doc.file_url}`, filename: doc.name, filetype: getFileExtension(doc.file_url)};
          case 'pdf': return {uri: `${doc.file_url}`, filename: doc.name, filetype: getFileExtension(doc.file_url)};
          default: return {uri: `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(doc.file_url)}`, filename: doc.name, filetype: getFileExtension(doc.file_url)};
      }
    }));
  }, [selectedSample]);

  const value={
    addNewNotifcation, user, selectedSubOrganization,

    selectedMethod, setSelectedMethod,
    selectedDocuments, setSelectedDocuments,
    selectedSample, setSelectedSample,
    uploadedDocs, setUploadedDocs,
    uploadedDocsOrg, setUploadedDocsOrg,
    processLoading, setProcessLoading,
    processLoadingOrg, setProcessLoadingOrg,
    loadDocuments, loadDocumentsOrg,
    docChatConvo, setDocChatConvo,
    loading, setLoading,

    OnSendPrompt,
    inputMessage, setInputMessage,
    displayDocs, setDisplayDocs,
    displayDocsSample, setDisplayDocsSample,
    selectedDocIndex, setSelectedDocIndex,
    isSidebarOpen, setIsSidebarOpen,
    getFileExtension
  }

  return (
      <DocChatContext.Provider value={value}>
          {children}
      </DocChatContext.Provider>
  );
};