import React, { useContext, useEffect, useRef, useState } from 'react';
import { httpRequest } from '../../../utils/httpsRequest';
import { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent } from '../../../components/ui/card';
import { Button } from '../../../components/ui/button';
import {
    Dialog,
    DialogPortal,
    DialogOverlay,
    DialogTrigger,
    DialogClose,
    DialogContent,
    DialogHeader,
    DialogFooter,
    DialogTitle,
    DialogDescription,
} from '../../../components/ui/dialog';
import { Context } from '../../../context/GlobalState';

const Assistants = () => {
    const [assistants, setAssistants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [currentAssistant, setCurrentAssistant] = useState(null);
    const { isAdminAuth } = useContext(Context);

    useEffect(() => {
        httpRequest.get('/db/bex-assistants/get-assistants')
            .then((res) => {
                setAssistants(res.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log('error: ', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const handleAddAssistant = () => {
        httpRequest.post('/db/bex-assistants/add-assistant', {
            name: currentAssistant.name,
            title: currentAssistant.title,
            description: currentAssistant.description,
        }).then((res) => {
            console.log('resasd: ', res);
            setAssistants([...assistants, res.data]);
            console.log('assiss: ', assistants);
            setShowAdd(false);
        }).catch((error) => {
            console.log('error: ', error);
        }).finally(() => {
            setLoading(false);
        }
        );
    };

    const handleEditAssistant = () => {
        httpRequest.put(`/db/bex-assistants/update-assistant/${currentAssistant._id}`, {
            name: currentAssistant.name,
            title: currentAssistant.title,
            description: currentAssistant.description,
        }).then((res) => {
            console.log('res: ', res);
            setAssistants(assistants.map((a) => a._id === currentAssistant._id ? currentAssistant : a));
            setShowEdit(false);
        }).catch((error) => {
            console.log('error: ', error);
        });
    };

    const handleDeleteAssistant = () => {
        console.log('currentAssistant: ', currentAssistant);
        httpRequest.delete(`/db/bex-assistants/delete-assistant/${currentAssistant._id}`)
            .then((res) => {
                setAssistants(assistants.filter(a => a._id !== currentAssistant._id));
                setShowDelete(false);
            }).catch((error) => {
                console.log('error: ', error);
            });
    };

    const openEditDialog = (assistant) => {
        setCurrentAssistant(assistant);
        setShowEdit(true);
    };

    const openDeleteDialog = (assistant) => {
        setCurrentAssistant(assistant);
        setShowDelete(true);
    };

    function truncateString(str, num) {
        if (!str) {
            return '';
        }
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    }

    return (
        <div className="flex flex-col w-full m-4 p-4">
            <div className="flex flex-row justify-between">
                <h1 className="text-2xl font-bold">Assistants</h1>
                {isAdminAuth && <Button onClick={() => { setCurrentAssistant(null); setShowAdd(true); }}>Add Assistant</Button>}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 m-4 overflow-auto max-h-screen">
                {loading ? <h1>Loading...</h1> : assistants.map((assistant) => (
                    <Card key={assistant._id}>
                        <CardHeader>
                            <CardTitle>{assistant.name}</CardTitle>
                        </CardHeader>
                        <CardContent>
                            <CardDescription>{truncateString(assistant.description, 50)}</CardDescription>
                        </CardContent>
                        <CardFooter className="flex justify-end gap-2">
                            <Button onClick={() => openEditDialog(assistant)}>Edit</Button>
                            <Button  onClick={() => openDeleteDialog(assistant)} variant="destructive">Delete</Button>
                        </CardFooter>
                    </Card>
                ))}
            </div>
    
            <Dialog open={showAdd} onOpenChange={setShowAdd}>
                <DialogOverlay />
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Add Assistant</DialogTitle>
                        <DialogDescription>Fill in the details for the new assistant.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="assistant-name" className="block text-sm font-medium">Name</label>
                            <input
                                id="assistant-name"
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, name: e.target.value })}
                            />
                        </div>
                        <div>
                            <label htmlFor="assistant-title" className="block text-sm font-medium">Title</label>
                            <input
                                id="assistant-title"
                                type="text"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, title: e.target.value })}
                            />
                        </div>
                        <div>
                            <label htmlFor="assistant-description" className="block text-sm font-medium">Description</label>
                            <textarea
                                id="assistant-description"
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, description: e.target.value })}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={() => setShowAdd(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleAddAssistant}>Add Assistant</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
    
            <Dialog open={showEdit} onOpenChange={setShowEdit}>
                <DialogOverlay />
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Assistant</DialogTitle>
                        <DialogDescription>Update the details of the assistant.</DialogDescription>
                    </DialogHeader>
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="assistant-name" className="block text-sm font-medium">Name</label>
                            <input
                                id="assistant-name"
                                type="text"
                                value={currentAssistant?.name || ''}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, name: e.target.value })}
                            />
                        </div>
                        <div>
                            <label htmlFor="assistant-title" className="block text-sm font-medium">Title</label>
                            <input
                                id="assistant-title"
                                type="text"
                                value={currentAssistant?.title || ''}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, title: e.target.value })}
                            />
                        </div>
                        <div>
                            <label htmlFor="assistant-description" className="block text-sm font-medium">Description</label>
                            <textarea
                                id="assistant-description"
                                value={currentAssistant?.description || ''}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                onChange={(e) => setCurrentAssistant({ ...currentAssistant, description: e.target.value })}
                            />
                        </div>
                    </div>
                    <DialogFooter>
                        <Button onClick={() => setShowEdit(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleEditAssistant}>Update Assistant</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
    
            <Dialog open={showDelete} onOpenChange={setShowDelete}>
                <DialogOverlay />
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Assistant</DialogTitle>
                        <DialogDescription>Are you sure you want to delete this assistant?</DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button onClick={() => setShowDelete(false)} variant="outline">Cancel</Button>
                        <Button onClick={handleDeleteAssistant} variant="destructive">Delete Assistant</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
    
}

export default Assistants;