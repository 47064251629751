import React, { useContext} from 'react';
import './prompting-and-rag-training.css';
import Training from "./components/Training.jsx";
import { PartProvider } from './context/PartContext';


export default function PromptingAndRagTrainingDashboard() {
    return (
        <PartProvider>
            <Training/>
        </PartProvider>
    );
}

