import React, { useContext, useRef, useState } from 'react'
import './login-styles.css'
import { Context } from '../../context/GlobalState'
import LoginForm from './LoginForm'
import { authService } from 'api'

export default function Login() {

    const [loading, setLoading] = useState(false)
    const { addNewNotifcation } = useContext(Context);
    
    const loginFormRef = useRef(null);
    
    const handleOnSubmit = (e) => {
        e.preventDefault()

        const emailRef = loginFormRef.current.getEmailRef();
        const passwordRef = loginFormRef.current.getPasswordRef();

        setLoading(true)
        authService.login({
            email: emailRef.value,
            password: passwordRef.value,
        })
        .then(async (res) => {
            if (!res.data?.user?.organization) {
                window.location = '/organizations'
            } else {
                const selectedSubOrg = localStorage.getItem('selectedSubOrg');
                if (selectedSubOrg) {
                    const isMember = res.data?.user?.subOrganizations?.find((sub) => sub._id == selectedSubOrg);
                    if (isMember) {
                        window.location = `/organizations/${res.data.user.organization}/sub/${selectedSubOrg}/bex-assistants`;
                    } else {
                        window.location = '/organizations';
                    }
                } else {
                    window.location = '/organizations';
                }
            }
        }).catch((error) => {
            console.log('error: ', error);
            if (error.response?.status == 403) {
                addNewNotifcation('Email or password is incorrect. Please reenter your credentials.', 'danger')
            } else {
                addNewNotifcation('Login failed. Please check your credentials.', 'danger')
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <div className='flex flex-grow flex-col justify-center items-center'>
            <LoginForm ref={loginFormRef} loading={loading} onSubmit={handleOnSubmit} />
        </div>
    )
}
