import React from 'react';
import { Container, PrimaryInput } from '../../../../components/FormInput';

const AddRoleForm = ({ roleInput, setRoleInput }) => {

    const handleInputChange = (key, value) => {
        setRoleInput(prev => ({
            ...prev,
            [key]: value
        }))
    }

    return (
        <div>
            <Container labelName={'Role Name'} style={{ fontWeight: '600' }}>
                <PrimaryInput 
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    type="text"
                    name="name"
                    placeholder="Type name of role here"
                    required
                    value={roleInput.name}
                />
            </Container>
        </div>
    );
};

export default AddRoleForm;
