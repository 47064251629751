import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../context/GlobalState'
import './Organiztion-styles.css'
import { useOrganization } from '../../hooks/useOrganization'
import OrgForm from './OrgForm'
import { AppLoader, LoaderSpinner } from '../../components/LoaderSpinner'
import { httpRequest } from '../../utils/httpsRequest'
import { Card, CardContent, CardHeader } from '../../components/ui/card'
import { Button } from '../../components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../components/ui/table'
import { LogOut, Trash } from 'lucide-react'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../../components/ui/dialog'
import { Link } from 'react-router-dom'
import { handleLeaveOrganization } from '../../utils/organizationUtils'
import AlertModal from '../../components/Alertmodal'
import { Badge } from 'components/ui/badge'
import { organizationService } from 'api'


function Organization() {
    const [btnLoading, setBtnLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const { organizations, loading } = useOrganization()
    const [orgData, setOrgData] = useState({name:''})
    
    const { user, isSubOrganization, addNewNotifcation } = useContext(Context)

    useEffect(() => {
        if (!isSubOrganization && user.organization) {
            setOrgData(prev => ({...prev, name: user.organizationObj?.name}))
        }
    }, [])

    const handleCreate = async () => {
        const orgNameRegex = /^[a-zA-Z0-9_ -]+$/;

        const existed = organizations?.find((org) => org.name.toLowerCase() === orgData.name.toLowerCase())
        if (existed) {
            addNewNotifcation(`Organization "${orgData.name}" already exist. Please choose a difference name.`, 'warning')
            return false;
        } else if (orgData.name.trim().length < 4) {
            return addNewNotifcation(`Organization name must be more than 4 character`, 'warning')
        } else if (!orgNameRegex.test(orgData.name.trim())) {
            return addNewNotifcation(`Organization name should not contain special character`, 'warning')
        }

        console.log(orgData)
        const orgObj = {
            name: orgData.name.trim(),
            created: new Date(),
            members:[],
            createdBy: user.id,
            superAdmin: user.id,
        }

        try {

            setBtnLoading(true)
            const res = await httpRequest.post('/db/organizations/insert_organization',orgObj)
            console.log('res: ',res)
            // setOrganizations(prev=> [...prev, res.data])
            // actions({type:'SET_USER',payload:{...user, organization: res.data._id}})

            // const subOrgObj = {
            //     name: orgData.name.trim()+"-sub",
            //     organizationId: res.data._id,
            //     members: [],
            //     isMain: true,
            //     workflowType: '669eb15d7567bb190481f914' // main workflow type
            // }
            // const subOrgRes = await httpRequest.post('/db/sub-organizations/insert-sub-organization', subOrgObj)
            // console.log('subOrgRes: ',subOrgRes)
            
            addNewNotifcation(`Organization "${orgData.name}" has been created successfully.`, 'success')
            // addNewNotifcation(`Sub Organization "${subOrganizationName}" has been created successfully.`, 'success')
            setTimeout(() => {
                window.location = '/'
            }, 1500)

            // setSubOrgForm(true)
            
        } catch(err) {
            console.log('err: ',err)
            if (err.response?.status === 409) {
                addNewNotifcation(`Organization name "${orgData.name}" already exist.`, 'warning')
            } else {
                addNewNotifcation(`Organization "${orgData.name}" could not be created. Please check all your entries.`, 'warning')
            }
        } finally {
            setBtnLoading(false)
        }
    }

    if (loading) {
        return <div className='flex flex-grow'> <AppLoader size={50}/> </div>
    }

    return (
        <div className='organizations-page-container'>
            <Card className="shadow-md flex-grow">
                <CardHeader>
                    <div className="flex items-center justify-between mb-6">
                        <h1 className="text-2xl font-bold"> Organizations </h1>
                        <div className="flex items-center gap-4">
                            <Button disabled={user.organization} onClick={() => setOpenModal(true)} size="sm">
                                Create New Organization
                            </Button>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="border rounded-lg overflow-hidden">
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Creted</TableHead>
                                    <TableHead>Members</TableHead>
                                    <TableHead>Status</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {organizations.map((item, index) => (
                                    <OrganizationCard {...item} index={index} key={index} />
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </CardContent>
            </Card>

            {/* create organization modal */}
            <Dialog open={openModal} onOpenChange={setOpenModal}>
                <DialogContent className="w-full max-w-lg p-8 bg-white rounded-lg shadow-lg">
                    <DialogHeader>
                        <DialogTitle className="text-2xl font-semibold">Create New Organization</DialogTitle>
                        <DialogDescription className="text-md text-muted-foreground">Set up a new organization.</DialogDescription>
                    </DialogHeader>
                    <OrgForm orgData={orgData} setOrgData={setOrgData}/>

                    <DialogFooter className="flex justify-end space-x-5 mt-6">
                        <Button variant="outline" onClick={() => setOpenModal(false)} className="px-5 py-3 size-md border-solid shadow-none text-accent-foreground">Cancel</Button>
                        <Button onClick={handleCreate} className="px-5 py-3 text-md">
                            {btnLoading ? <LoaderSpinner /> : 'Create Organization'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

        </div>
    )
}

const OrganizationCard = ({name, created, _id, members, index, isClusterDeployed}) => {

    const [deleteLoading, setDeleteLoading] =  useState(false)

    // leave org state
    const [LeaveLoading, setLeaveLoading] =  useState(false)
    const [leaveOrgModal, setLeaveOrgModal] =  useState(false)
    const { user, addNewNotifcation } = useContext(Context)

    const handleLeave = async () => {
        setLeaveLoading(true)
        try {
            await handleLeaveOrganization(_id, user._id)
        } catch (error) {
            if (error.response?.status === 409) {
                addNewNotifcation('Super admin can not leave the organization', 'warning')
            } else {
                addNewNotifcation('Something went wrong. Please try again', 'warning')
            }
        } finally {
            setLeaveLoading(false)
        }
    }

    const handleOpenModel = () => {
        if (user.role === 'superAdmin') {
            console.log('delete');
        } else {
            setLeaveOrgModal(true)
        }
    }

    useEffect(() => {
        let intervalId;
    
        if (!isClusterDeployed) {
            intervalId = setInterval(() => {
                checkDeploymentStatus().then((res) => {
                    if (res) {
                        window.location.reload()
                    }
                });
            }, 5000);
        }
    
        return () => clearInterval(intervalId);
    }, [isClusterDeployed]);

    const checkDeploymentStatus = async () => {
        try {
            const response = await organizationService.checkOrganizationCluster(_id)
            if (response.status === 202) {
                return false
            } else {
                return true
            } 
        } catch (error) {
            console.error('Error checking deployment status:', error);
            return false;
        }
    };

    return (
        <TableRow key={index}>
            <TableCell className='p-4'>
                <div className="flex items-center gap-2">
                    <Link className={`flex items-center gap-2 text-[blue] hover:underline ${!isClusterDeployed ? 'pointer-events-none' :''}`} to={`/organizations/${_id}/sub`}>
                        <span>{name}</span>
                    </Link>
                </div>
            </TableCell>
            <TableCell className='p-4'>{created.split('T')[0] || "2024/2/2"}</TableCell>
            <TableCell className='p-4'>{members?.length || 'N/A'}</TableCell>
            <TableCell className='p-4'>
                {isClusterDeployed ? 
                    <Badge variant="outline" className="bg-green-500 text-green-50">
                        Deployed
                    </Badge>
                :
                    <>
                        <Badge variant="outline" className="bg-yellow-500 text-yellow-50">
                            Deploying...
                        </Badge>
                        <div className="text-xs text-muted-foreground">
                            Organization is still being deployed and is not yet available for use.
                        </div>
                    </>
                }
            </TableCell>
            <TableCell className='p-4'>
                <div className="flex items-center gap-2">
                    <Button disabled={!isClusterDeployed} onClick={handleOpenModel} variant="destructive" className='flex items-center gap-2 text-[13px]' size="sm">
                        {user.role !== 'superAdmin' ? <LogOut size={13} /> : <Trash size={13} />}
                        {user.role !== 'superAdmin' ? 'Leave' : 'Delete Organization'}
                    </Button>
                </div>
            </TableCell>

            {/* leave organization alert modal */}
            <AlertModal openModal={leaveOrgModal} 
            setopenModal={setLeaveOrgModal} onDelete={handleLeave} loading={LeaveLoading} 
            title='Are you sure you want to leave this organization' 
            subTitle='This action cannot be undone and you will lose access to all organizational resources and data.' 
            btnTitle='Leave' />

        </TableRow>
    )
}

export default Organization