import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Context } from '../../../context/GlobalState'
import SetupCard from '../../../components/AppSetup/SetupCard';
import SetupModal from '../../../components/AppSetup/SetupModal';
import './text-to-speech-styles.css';
import TextToSpeechForm from '../../../pages/TextToSpeech/Components/TextToSpeechForm';
import Breadcrumb from 'components/Breadcrumb'

export default function TextToSpeechDashboard() {
    // Constants
    const SETUP_DEMO_LIST = [
        'Your text source for the BeX AI Assistant to convert to speech',
        'The specific text or data you want the Assistant to extract and convert to speech'
    ];
    const SETUP_DEMO_DESCRIPTION = [
        'BeX AI Assistant for Text To Speech is tailored for seamless conversion of text into speech while providing the added feature of downloading the generated audio files.',
        'This tool enhances communication and accessibility within the BeX Platform, enabling users to transform written content into natural-sounding speech and conveniently access audio versions for sharing or offline use.'
    ];

    // States
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);

    // Context
    const { user, addNewNotifcation } = useContext(Context)
    const param = useParams()

    const navigateToTextToSpeech = () => {
        if (user.organization) {
            navigate(`/organizations/${param.oragID}/sub/${param.subOragID}/bex-assistants/text-to-speech`)
        } else {
            navigate(`/organizations`)
        }
    }
    
    // Actions
    const handleDemoClick = () => {
        navigateToTextToSpeech()
    };
    
    const handleSetupClick = () => {
        addNewNotifcation("Setup is locked.", "danger")
    }

    return (
        <div className='text-to-speech-dashboard-container'>
            <div className='text-to-speech-dashboard-content'>

                <div className='dashboard-app-setup-container'>
                    <Breadcrumb maxPaths={4} />

                    <div className='dashboard-app-setup-card-container'>
                        <SetupCard 
                            overviewTitle={'BeX AI Assistant for Text To Speech'}
                            overviewDescriptions={SETUP_DEMO_DESCRIPTION}
                            title='Click now to setup your BeX AI Assistant' 
                            subTitle='What do you need to get started?'
                            list={SETUP_DEMO_LIST}
                            onDemoClick={handleDemoClick}
                            onSetupClick={handleSetupClick} />

                        <SetupModal title={'Demo for BeX AI Assistant (Text To Speech)'} isOpen={openModal} setOpenModal={setOpenModal}>
                            <SetupModal.StyledInstructions>
                                <div className='text-to-speech-demo-insturctions'>
                                    <div className='demo-insturctions__title'>
                                        DEMO:  Select a sample text to read
                                    </div>

                                    <div className='demo-insturctions__text'>
                                        <span>
                                            The BeX AI Assistant provides Text-To-Speech (TTS) conversion and audio file downloading features, making communication and accessibility easier for users. It allows users to select their preferred voice from a list provided.                                         </span>
                                        <span>
                                            Demo: In this demo, users have the option to select samples, choose voice preferences, upload a text file, or input their own text to initiate text-to-speech conversion.
                                        </span>
                                    </div>
                                </div>
                            </SetupModal.StyledInstructions>
                            
                            <SetupModal.StyledSetup>
                                {/* 
                                    TODO: 
                                        - Remove this class after refactoring and improving the SetupModal 
                                        - Check why the dropdown is not visible in the modal but visible in the main page - Z-index issue
                                */}
                                <div className="speech-to-text-container">
                                    <TextToSpeechForm />                   
                                </div>
                            </SetupModal.StyledSetup>
                        </SetupModal>
                    </div>
                </div>
            </div>
        </div>
    );
}
