import React, { useContext, useEffect, useState } from 'react'
import { AppLoader } from '../../../../../../components/LoaderSpinner'
import ListDocuments from '../../../../../../pages/EmailHandling/Document/ListDocuments'
import { useParams } from 'react-router-dom'
import { arrayToObject, containsKeyValue, hasDuplicateKeyValues } from '../../../../../../pages/EmailHandling/Document'
import { Context } from '../../../../../../context/GlobalState'
import UploadDocs from 'pages/EmailHandling/Document/UploadDocs'
import QueryDocs from 'pages/EmailHandling/Document/QueryDoc'
import { ragService } from 'api/services/PROJECT-O'

export default function SubOrganizationDocuments() {


    const [docs, setDocs] = useState([])
    const [queries, setQueries] = useState([])
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [showQueryModal, setShowQueryModal] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const { user, addNewNotifcation } = useContext(Context)
    const { oragID, subOragID, collectionId } = useParams()

    const [documentQuery, setDocumentQuery] = useState([])

    const handleUpload = async () => {

        // check queries
        if (hasDuplicateKeyValues(queries, 'key')) {
            return addNewNotifcation("Duplicate key found. Keys should not be duplicated.", 'warning')
        } else if (containsKeyValue(queries, 'key') || containsKeyValue(queries, 'value')) {
            return addNewNotifcation("Empty key or value found. Keys and values should not be empty.", 'warning')
        }


        if (!documentQuery || documentQuery?.length <= 0) {
            return addNewNotifcation('Document could not be uploaded. Document is required. Please provide a document before proceeding.', 'danger')
        }

        setBtnLoading(true)
        const formData = new FormData();

        // add user id as metadata
        const metadataQueries = [
            {key: 'userId', value: user._id}, 
            {key: 'bucket_name', value: `coll-${subOragID}-${collectionId}`}
        ]
        metadataQueries.concat(queries)

        formData.append(`organization_id`, oragID);
        formData.append(`sub_organization_id`, subOragID);
        formData.append(`files`, documentQuery);
        formData.append(`metadata`, JSON.stringify(arrayToObject(metadataQueries)));
        formData.append(`bucket_name`, `coll-${subOragID}-${collectionId}`);

        ragService.processFile(formData)
        .then((res) => {
            console.log(res.data);
            // window.location.reload()
            addNewNotifcation('Document added successfully', 'success')
            setOpenModal(false)
            setDocumentQuery([])
            getDocs()
            .then((res) => {
                console.log('Done');
            })
        }).catch((err) => {
            console.log('err: ', err);
            addNewNotifcation('Something went wrong. Please try again', 'danger')
        }).finally(() => {
            setBtnLoading(false)
        })
    }

    const getDocs = async () => {
        try {
            setLoading(true)
            const res = await ragService.listDocuments(oragID, subOragID, {'bucket_name':`coll-${subOragID}-${collectionId}`})
            console.log('res: ', res);
            setDocs(res.data)
        } catch(err) {
            console.log('err: ',err);
        }finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        getDocs()
        .then((res) => {
            console.log('Done');
        })
    }, [])


    if (loading) {
        return <div className='flex flex-grow flex-col justify-center items-center'> <AppLoader size={50} /> </div>;
    }

    return (
        <div className='flex flex-col flex-grow'>
            <ListDocuments handleUploadFiles={() => setOpenModal(true)} handleQueryAll={() => setShowQueryModal(true)} docs={docs} setDocs={setDocs} />

            <QueryDocs showQueryModal={showQueryModal} setShowQueryModal={setShowQueryModal} />
            <UploadDocs btnLoading={btnLoading} metadata={queries} 
            setMetadata={setQueries} documentQuery={documentQuery} setDocumentQuery={setDocumentQuery} onSubmit={handleUpload} showUploadModal={openModal} setShowUploadModal={setOpenModal} />
        </div>
    )
}
