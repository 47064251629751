import React, { useState, useContext, useEffect } from "react";
import "./email-styles.css";
import { EntityIntentCloseBtn } from "../../Manager/EntityIntent/components/PopUpModal";
import PageStyle from "../../../components/PageStyle";
import { LoaderSpinner } from "../../../components/LoaderSpinner";
import {
  areObjectsEqual,
  convertObjectToArray,
} from "../../../utils/arrayUtils";
import { httpRequestO, httpRequestOSetup, serverOSetup } from "../../../utils/httpsRequest";
import { Context } from "../../../context/GlobalState";
import Modal from "../../../components/Modal";
import EmailCard from "./emailCard";
import EmailForm from "./emailForm";
import { RedButton } from "../../../components/Buttons";
import { useParams } from "react-router-dom";

export default function Email() {
  const [opCodesList, setOpCodesList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [emailSample, setEmailSample] = useState("");
  const [resData, setResData] = useState({});

  const { addNewNotifcation } = useContext(Context);

  const [show, setShow] = useState(true);

  const handleOpCodeClick = async () => {
    setBtnLoading(true);
    
    const encodedEmailTitle = encodeURIComponent(emailSample);

    httpRequestO.post(`/projecto/api/v1/cpie/cpie-v2?user_input=${encodedEmailTitle}&user_id=mazen`)
      .then((res) => {
        console.log("res: ", res.data);
        setResData(res.data);
        setShow(true);
      })
      .catch((err) => {
        console.log("error: ", err);
        addNewNotifcation('Something went wrong', 'danger')
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  return (
    <div className="document-manger-container">
      <Modal
        $isOpen={openModal}
        setOpenModal={setOpenModal}
        onClose={onModalClose}
      >
        <Modal.Header> Email Processing </Modal.Header>
        <Modal.Body>
          <div className="create-opcode-form">
            <EmailProcessingModal
              show={show}
              emailSample={emailSample}
              setEmailSample={setEmailSample}
              resData={resData}
              setShow={setShow}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <EntityIntentCloseBtn onClick={onModalClose}>
              {"Cancel"}
            </EntityIntentCloseBtn>
            <button
              className="organizations-create-btn"
              // disabled={btnLoading ? true : checkDisable}
              onClick={handleOpCodeClick}
            >
              {btnLoading ? <LoaderSpinner /> : "Process"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <PageStyle
        renderItem={(doc) => (
          <EmailCard setOpCodesList={setOpCodesList} key={doc._id} {...doc} />
        )}
        title={"Email Processing"}
        btn={"Process Email"}
        list={opCodesList}
        setOpenModal={setOpenModal}
      >
        <span></span>
      </PageStyle>
    </div>
  );
}

export const EmailProcessingModal = ({ setEmailInbox, resData, setShow, show, emailSample, setEmailSample }) => {
  
  const [opcodeResponse, setOpcodeResponse] = useState({})
  const [regenrateLoading, setRegenrateLoading] = useState(false)

  const { user, addNewNotifcation } = useContext(Context);
  const { subOragID } = useParams()

  useEffect(() => {
    setOpcodeResponse(resData)
  }, [resData])
  

  const handleRegenrateResponse = async () => {

    setRegenrateLoading(true)
    const regenerateObj = {
      user_input: emailSample,
      details: opcodeResponse.details,
      "request_id": `${user.organization}-${subOragID}-${user._id}`,
      "callback_api": `${serverOSetup}/callback/email-callback`
    }
    console.log('regenerateObj: ', regenerateObj);

    try {
      const res = await httpRequestO.post('/projecto/api/v1/cpie/regeneration', regenerateObj)
      console.log('res: ', res);
      
      const callbackObj = {
        organization: user.organization,
        subOrganization: subOragID,
        userId: user._id,
        emailContent: emailSample
      }
      const inboxResponse = await httpRequestOSetup.post('callback/add-email-inbox', callbackObj)
      console.log('inboxResponse: ', inboxResponse);
      // setOpcodeResponse(res.data)
      setEmailInbox(true)
      addNewNotifcation('Regeneration sent successfully. Please refresh to check for response', 'success')
    } catch(error) {
      addNewNotifcation('Something went wrong', 'danger')
      console.log('error: ', error);
    } finally {
      setRegenrateLoading(false)
    }

  }

  const handleRagChange = (value, type, i) => {

    setOpcodeResponse(prev => {
      const updatedArray = prev.details.map((obj, index) => {
          if (index === i) {
          return { ...obj, [type]: value }; 
          }
          return obj;
      });

      return { ...prev, details: updatedArray };
    });
  }


  return (
    <div className={`opcode-setup-container ${false ? "hide-setup" : ""}`}>
      <EmailForm emailSample={emailSample} setShow={setShow} setEmailSample={setEmailSample} setEmailInbox={setEmailInbox} />
      {show &&
        opcodeResponse?.details?.map((response, index) => {
          return (
            <div className="opcode-form-container" key={index}>
              <div
                className="opcode-type-container"
                style={{ fontWeight: "600" }}
              >
                <div className="opcode-label">
                  <label>  Intent Classification {index + 1}</label>
                </div>
                <input
                  className="response-select"
                  name="Operation Code"
                  value={response.opcode}
                  onChange={() => {}}
                  disabled
                />
                <div className="opcode-label">
                  <label>
                    Data extracted from email based on intent clasifictation 
                  </label>
                </div>
                <div className="sample__data">
                  <div className="collected-data-container">
                    {convertObjectToArray(response.entities).map((row) => (
                      <div key={row.field} className="collect-row">
                        <span style={{ textTransform: "capitalize" }}>
                          {" "}
                          {row.field?.replaceAll("_", " ")}:{" "}
                        </span>
                        <input
                          name={row.field}
                          // style={{ width: "100%" }}
                          placeholder=""
                          className="response-select"
                          value={row.fieldType || 'None'}
                          onChange={() => {}}
                          disabled
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="opcode-form-container">
                  <div className="opcode-label" style={{ fontWeight: "600", marginTop: '15px' }}>
                      <label>
                        data extracted from hotel policy
                      </label>
                  </div>            
                      <div className="rag-display-container">
                        <label>
                          Structured RAG
                        </label>
                        <textarea onChange={(e) => handleRagChange(e.target.value, 'structured_rag', index)} value={response?.structured_rag || ''} disabled={!response?.structured_rag} className="opcode-response-textarea" name="rag" />
                      </div>
                  </div>
                <div className="opcode-form-container">        
                    <div className="rag-display-container">
                      <label>
                        Unstructured RAG
                      </label>
                      <textarea onChange={(e) => handleRagChange(e.target.value, 'unstructured_rag', index)} value={response?.unstructured_rag || ''} disabled={!response?.unstructured_rag} className="opcode-response-textarea" name="rag"/>
                    </div>
                    <div className="regenerate-opcode-response-container">
                      <RedButton disabled={regenrateLoading || areObjectsEqual(resData, opcodeResponse)} onClick={handleRegenrateResponse}>
                        {regenrateLoading ? <LoaderSpinner /> : "Regenrate Response"}
                      </RedButton>
                    </div>
                </div>
              </div>
              <hr style={{ marginTop: 30 }} />
            </div>
          );
        })}
      {show && (
        <div className="opcode-form-container" style={{ fontWeight: "600" }}>
          <div className="opcode-label">
            <label> AI Generated Reply </label>
          </div>
          <textarea
            style={{ marginTop: 10 }}
            placeholder=""
            name="email"
            className="opcode-response-textarea"
            rows={15}
            value={opcodeResponse.response}
            disabled
          />
        </div>
      )}
    </div>
  );
};
