import React, { useContext, useState, useEffect, useRef } from 'react';
import { Button } from "components/ui/button";
import { FileText, Trash2, Upload } from 'lucide-react';
import * as ShadeDD from "components/ui/dropdown-menu";
import { LoaderSpinner } from 'components/LoaderSpinner';
import { Input } from 'components/ui/input';
import { v4 as uuidv4 } from 'uuid';


export const SampleList = ({inputMessage, setInputMessage, setLanguage}) => {
    const [isOpen, setIsOpen] = useState(false);
    const SAMPLES = [
//         {_id: uuidv4(), name: "Simple Python Code", value: "sample_1", language: "python", query: "Write a python code that computes and displays the factorial of a given number using a loop."},
//         {_id: uuidv4(), name: "Simple JavaScript Code", value: "sample_2", language: "javascript", query: "A simple JavaScript function that generates and displays a random matrix."},
//         {_id: uuidv4(), name: "Code Fix (Python)", value: "sample_3", language: "python", query: 
// `\`\`\`python
// def bubble_sort(arr):
//     n = len(arr)
//     for i in range(n):
//         swapped = False
//         for j in range(0, n - i - 1):
//             if arr[j] < arr[j + 1]:
//                 arr[j], arr[j + 1] = arr[j + 1], arr[j]
//                 swapped = True
//         if not swapped:
//             break

// arr = [64, 34, 25, 12, 22, 11, 90]
// bubble_sort(arr)
// print("Sorted array is:", arr)
// \`\`\`

// Can you fix the given code? It sorts from highest to lowest instead of lowest to highest.`},
        
        {_id: uuidv4(), name: "OOP (JavaScript)", value: "sample_4", language: "javascript", query: 
            "Write a JavaScript function called getAdults that takes an array of objects representing people, each with name and age properties. The function should return an array of names of people who are 18 years old or older. Define people array of objects and print the returned people's name"},
        {_id: uuidv4(), name: "Form Validation (JavaScript)", value: "sample_5", language: "javascript", query: 
            "Generate a complete JavaScript code that validates username and password. Ensure that the username is not empty and the password is at least 8 characters long. Display appropriate error messages if the validation fails. Test the function with sample data"},
        {_id: uuidv4(), name: "Read Data from API (JavaScript)", value: "sample_6", language: "javascript", query: 
            "Generate JavaScript code to fetch data from an API endpoint (https://135717ae-f5ef-492d-80c9-6dc9c4c68fd7.mock.pstmn.io/current?query=New Delhi). Response will be in JSON format, extract following data from the response: location[name], location[country], current[temperature], current[observation_time] and display in the console"},
        {_id: uuidv4(), name: "Historical Weather Data Analysis (Python)", value: "sample_7", language: "python", query: 
`Write a Python script that makes a GET request to an API endpoint (https://135717ae-f5ef-492d-80c9-6dc9c4c68fd7.mock.pstmn.io/historical?query=New York&historical_date=2015-01-21&hourly=1) and analyzes the returned JSON data. The API response includes current and historical weather data for New York, USA. Your script should: Request Data: Fetch the data from the provided API endpoint URL. Parse the JSON: Extract and print the following information: Current Weather: Temperature (current.temperature), Weather Description (current.weather_descriptions[0]), Humidity (current.humidity), Wind Speed (current.wind_speed), and Cloud Cover (current.cloudcover). Historical Data: For each hour in the historical data (historical[date]['hourly']), note that (historical[date]['hourly']) is a list, extract and display the time, temperature, wind_speed, and weather_descriptions[0]. Data Analysis: Perform a simple analysis on the historical data to: Find the hour with the highest temperature. Find the hour with the highest wind speed. Calculate the average temperature and wind speed for the entire day. Data Representation: Create a DataFrame using pandas to store the hourly historical weather data (time, temperature, wind speed, weather descriptions), and print the DataFrame. Error Handling: Ensure your script handles potential errors, such as network issues, invalid JSON responses, or missing keys. Finally, display the results of your analysis.`},
        {_id: uuidv4(), name: "BMI Calculator (Python)", value: "sample_8", language: "python", query: 
            "Write a Python program that calculates the Body Mass Index (BMI) from defined user dictionary of weight and height and prints whether they are underweight, normal weight, overweight, or obese based on the BMI value."},
        {_id: uuidv4(), name: "Bank Account OOP (Python)", value: "sample_8", language: "python", query: 
`Write a Python class called BankAccount that simulates a bank account. The class should support the following operations:

Deposit: Add a specified amount to the account balance. If the deposit amount is negative or zero, raise a ValueError.
Withdrawal: Subtract a specified amount from the account balance. If the withdrawal amount is greater than the current balance, raise a ValueError indicating insufficient funds. If the withdrawal amount is negative or zero, raise a ValueError.
Balance Check: Return the current balance of the account.
Transaction History: Maintain a record of all deposits and withdrawals. Each transaction should be stored with a timestamp, type of transaction (deposit/withdrawal), and amount.
Overdraft Protection: Allow an optional overdraft limit that permits withdrawals that exceed the balance up to a certain limit. If overdraft protection is enabled, ensure that withdrawals do not exceed the combined balance and overdraft limit.
Advanced Requirements:
Account Statement: Add a method get_statement that returns a list of all transactions in chronological order, formatted as Date | Type | Amount | Balance After Transaction.
Account Locking: Add functionality to lock the account after three consecutive failed withdrawal attempts (due to insufficient funds). Provide a method to unlock the account.
Interest Calculation: Add a method apply_interest that increases the account balance by a specified annual interest rate, applied monthly.
Initialize an Account then perform all the actions`},
    ];

    const OnSampleSelected = (sample) => {
        // alert(sample.name);
        setInputMessage(sample.query);
        setLanguage(sample.language);
        setIsOpen(false);
    };

    return(
        <ShadeDD.DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
            <ShadeDD.DropdownMenuTrigger asChild>
                <Button variant="outline" className="px-4">
                    {/* <FileText className="w-5 h-5" /> */}
                    Samples
                </Button>
            </ShadeDD.DropdownMenuTrigger>
            <ShadeDD.DropdownMenuContent align="end" side="bottom" className="w-[300px]">
                {/* <ShadeDD.DropdownMenuLabel>
                    {uploadedDocs.length === 0 ? "No documents" : "Documents"}
                </ShadeDD.DropdownMenuLabel> */}
                <ShadeDD.DropdownMenuGroup>
                    {SAMPLES.map((s) => (
                        <div
                            key={s._id}
                        >
                            <Button 
                                variant="ghost"
                                className="w-full whitespace-normal break-words"
                                onClick={() => OnSampleSelected(s)}>
                                {s.name}
                            </Button>    
                        </div>
                    
                    ))}
                </ShadeDD.DropdownMenuGroup>
                
            </ShadeDD.DropdownMenuContent>
        </ShadeDD.DropdownMenu>
    );
}


