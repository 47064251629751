import { bexHttpClient } from '../../clients';


/**
 * Service class for performing subscription operations.
 */
class WorkflowService {

    /**
     * Fetches the sub organization workflow.
     * @returns {Promise<Object>} The response from the server returns sub organization workflow.
     */
    async getSubOrgWorkflow(organizationId, subOrganizationId) {
        const response = await bexHttpClient.get(`/db/workflows/get-workflow/${organizationId}/${subOrganizationId}`)
        return response;
    }

    /**
     * Fetches the workflow types.
     * @returns {Promise<any[]>} The response from the server returns workflow types.
     */
    async getWorkflowTypes(type='regular') {
        const response = await bexHttpClient.get(`/db/workflows/get-workflow-types?type=${type}`)
        return response;
    }

    /**
     * add new workflow.
     * @param {Object} data - The new workflow data including name, organizationId, subOrganizationId, workflowAssistants and workflowType.
     * @returns {Promise<Object>} The response from the server returns new workflow document from mongoDB.
     */
    async addWorkflow(data) {
        const response = await bexHttpClient.post('db/workflows/add-workflow', data)
        return response;
    }

    /**
     * update workflow assistant.
     * @param {Object} data - The update workflow assistant data including assistant id, status, organizationId and subOrganizationId
     * @returns {Promise<Object>} The response from the server returns updated workflow document from mongoDB.
     */
    async updateWorkflowAssistant(data) {
        const response = await bexHttpClient.put(`/db/workflows/update-assistant`, data)
        return response;
    }

}


export { WorkflowService }
